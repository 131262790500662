import React, { Component } from 'react';
import {
  BaseButton, BaseTextField, BaseSelect,
  BaseFileSelect,
  MasterLayoutMiddle,
  FooterInner,
  BasePhoneInput
} from "@dclab/dc-react-ui-kit";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import {
//   BasePhoneInput
// } from 'master-react-lib';

import Alert from '../../components/common/AlertAction';
import { connect } from 'react-redux';
import * as actions from '../../store/Actions/index';
import Spinner from '../../components/common/Spinner';
import Grid from '@material-ui/core/Grid';
import './editOrganization.css';
import MediaUpload from '../../components/common/MediaUpload/MediaUpload';


class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {

      show: false,
      errors: {},
      loading: false,
      organization: {},

      id: '',
      orgName: "",
      orgType: '',
      orgShortName: '',
      logo: '',
      logoUrl: '',
      iconUrl: '',
      icon: '',
      iconSize: '',
      logoSize: '',
      streetAddress: '',
      city: '',
      country: '',
      state: '',
      zipCode: '',
      suiteNumber: '',
      primaryPhoneNo: '',
      secondaryPhoneNo: '',
      orgEmail: '',
      orgWebsite: 'https://',
      orgStatus: '',

      object: [
        {
          value: "Masjid/Islamic Center",
          label: "Masjid/Islamic Center",
        },
        {
          value: "Madarsa/Islamic Center",
          label: "Madarsa/Islamic Center",
        },
        {
          value: "others",
          label: "Others",
        },
      ],

      object2: [
        {
          value: "approved",
          label: "Approved",
        },
        {
          value: "onboarding",
          label: "Onboarding",
        },
        {
          value: "blocked",
          label: "Blocked",
        },
      ],

      chipData: [
        {
          key: 0,
          label: "",
          src:
            "",
          alt: "",
        }
      ],
      chipData2: [
        {
          key: 0,
          label: "",
          src:
            "",
          alt: "",
        }
      ],


    };
  }


  static getDerivedStateFromProps(props, state) {
    let errors = props.errors;
    let page = props.page;
    let organization = props.organization ? props.organization : {};

    let stateChanged = false;
    let changedState = {};

    if (organization && JSON.stringify(state.organization) !== JSON.stringify(organization)) {
      changedState.organization = organization;
      stateChanged = true;

      changedState.orgName = organization.orgName ? organization.orgName : '';
      changedState.orgType = organization.orgType ? organization.orgType : '';
      changedState.orgShortName = organization.orgShortName ? organization.orgShortName : '';
      changedState.logo = organization?.orgLogo?.logoName ? organization.orgLogo.logoName : '';
      changedState.logoUrl = organization?.orgLogo?.logoUrl ? organization.orgLogo.logoUrl : '';
      changedState.icon = organization?.orgIcon?.iconName ? organization.orgIcon?.iconName : '';
      changedState.iconUrl = organization?.orgIcon?.iconUrl ? organization.orgIcon?.iconUrl : '';
      changedState.streetAddress = organization.orgAddress && organization.orgAddress.line1 ? organization.orgAddress.line1 : '';
      changedState.city = organization.orgAddress && organization.orgAddress.city ? organization.orgAddress.city : '';
      changedState.country = organization.orgAddress && organization.orgAddress.country ? organization.orgAddress.country : '';
      changedState.state = organization.orgAddress && organization.orgAddress.state ? organization.orgAddress.state : '';
      changedState.zipCode = organization.orgAddress && organization.orgAddress.postalCode ? organization.orgAddress.postalCode : '';
      changedState.suiteNumber = organization.orgAddress && organization.orgAddress.line2 ? organization.orgAddress.line2 : '';
      changedState.primaryPhoneNo = organization?.phoneFax?.primaryPhone ? organization.phoneFax.primaryPhone : '';
      changedState.secondaryPhoneNo = organization?.phoneFax?.secondaryPhone ? organization.phoneFax.secondaryPhone : '';
      changedState.orgEmail = organization.orgEmail ? organization.orgEmail : '';
      changedState.orgWebsite = organization.orgWebsite ? organization.orgWebsite : 'https://';
      changedState.orgStatus = organization.orgStatus ? organization.orgStatus : '';
      const chipdata = [{
        key: 0,
        label: "",
        src: organization?.orgLogo?.logoName ? organization.orgLogo.logoName : '',
        alt: "",
      }]
      const chipdata2 = [{
        key: 0,
        label: "",
        src: organization?.orgIcon?.iconName ? organization.orgIcon.iconName : '',
        alt: "",
      }]
      changedState.chipData = chipdata;
      changedState.chipData2 = chipdata2;
    }

    if (errors && JSON.stringify(state.errors) !== JSON.stringify(errors)) {
      changedState.errors = errors;
      stateChanged = true;
    }

    if (page && JSON.stringify(state.loading) !== JSON.stringify(page.loading)) {
      changedState.loading = page.loading;
      stateChanged = true;
    }

    if (stateChanged) {
      return changedState;
    }
    return null;
  }
  showAlertHandler = () => {
    this.props.onClearError();
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.setState({ id: id });
    this.props.onGetSingleOrganiztion(id);
  }

  click = (data) => {
    console.log("button clicked : ", data);
  }

  handleChange = (prop) => (event) => {
    if(prop === 'orgWebsite' && event?.target?.value.length < 8) return;
    this.setState({
      [prop]: event.target.value
    });
  }

  callbackLogo = (selectedFiles) => {
    this.setState({ logo: selectedFiles.title, logoSize: 0, logoUrl: selectedFiles.src })
  }

  callbackIcon = (selectedFiles) => {
    this.setState({ icon: selectedFiles.title, iconSize: 0, iconUrl: selectedFiles.src })
  }

  submitHandler = (e) => {
    e.preventDefault();
    const { id, orgName, orgType, orgShortName, streetAddress,
      city, country, state, zipCode, suiteNumber, primaryPhoneNo, logo, logoUrl, iconUrl, icon, iconSize, logoSize,
      secondaryPhoneNo, orgWebsite,
      orgEmail, orgStatus
    } = this.state;

    let formData = {
      orgName,
      orgShortName,
      orgType,
      orgEmail,
      orgStatus,
      phoneFax: {
        primaryPhone: primaryPhoneNo,
        secondaryPhone: secondaryPhoneNo,
      },
      orgAddress: {
        line1: streetAddress,
        line2: suiteNumber,
        city: city,
        state: state,
        country: country,
        postalCode: zipCode
      },
      orgWebsite,
      deen_fund_side_info: {},
      orgIcon: {
        iconName: icon,
        iconSize: iconSize,
        iconUrl: iconUrl
      },
      orgLogo: {
        logoName: logo,
        logoSize: logoSize,
        logoUrl: logoUrl

      }
    }

    console.log("Checking form data: ", formData);
    this.props.onSubmit(id, formData, this.props.history);
  }

  styles = {
    txt: {
      color: '#4D4D4D ',
      letterSpacing: '1.26px',
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: '15px'
    },
    backBtn: {
      backgroundColor: 'white',
      borderRadius: '18px',
      color: '#4D4D4D',
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '15px',
      width: '86px',
      height: '36px'
    },
    routes: {
      marginLeft: '70px',
      width: '90%',
      padding: '30px 0',
      display: 'flex'
    },

    inviteBtn: {
      textAlign: 'center',
      color: 'white',
      letterSpacing: '1.26px',
      height: '45px',
      background: '#2490F9 0% 0% no-repeat padding-box',
      borderRadius: '23px',
      padding: '14px 45px'
    },
    cancelBtn: {
      textAlign: 'center',
      color: 'black',
      letterSpacing: '1.26px',
      height: '45px',
      borderRadius: '23px',
      backgroundColor: 'white',
      padding: '14px 45px'
    },
    multiSelectImage: {
      height: 'auto',
      width: '100%',
      marginTop: '40px',
      borderBottom: '1px solid #818E94'
    },

  };


  render() {
    let { errors, loading, show, object, object2,
      // chipData, chipData2, 
      orgName, orgType, orgShortName, streetAddress, logo, icon, logoUrl, iconUrl,
      city, country, state, zipCode, suiteNumber, primaryPhoneNo,
      secondaryPhoneNo, orgEmail, orgWebsite, orgStatus,
      id
    } = this.state;


    console.log("state:", this.state)

    console.log("checking orgType: ", orgType);

    let pageContent = '';
    let message = 'Invite sent successfully! ';
    let type = 'success';
    let styles = this.styles;


    if (loading) {
      pageContent = <Spinner />
    }
    if (errors && errors.message) {
      message = errors.message;
      type = 'error';
      show = true;
    }

    const style = {
      width: '100%',
    };

    const phoneRegex = /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{5})$/g;

    return (
      <React.Fragment>
        <MasterLayoutMiddle

          header={
            <div>
              <Alert message={message} type={type} click={this.showAlertHandler} show={show} />
              <div className="backBtnFrom">
                <BaseButton
                  onClick={() => this.props.history.push(`/organization-detail-${id}`)}
                  text="back"
                  variant="contained"
                  textStyle={styles.txt}
                  disableElevation={false}
                  disableFocusRipple={false}
                  disableRipple={false}
                  startIcon={<ChevronLeftIcon />}
                />
              </div>
            </div>
          }

          isForm={true}

          formData={

            <form onSubmit={this.submitHandler}>
              <Grid container spacing={3} className="orgDetailsContainer">

                <Grid item xs={12}>
                  <h1 className="title_top">Organization Details</h1>
                </Grid>

                <Grid item xs={12}>
                  <div className="field_box">
                    <BaseTextField
                      value={orgName}
                      onChange={this.handleChange("orgName")}
                      label='Organization Name'
                      placeholder="Organization Name"
                      InputProps={false}
                      phone={false}
                      search={false}
                      count={50}
                      color="green"
                    ////helperText="helper text"
                    //type="search"
                    />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className="field_box">
                    <BaseTextField
                      value={orgShortName}
                      onChange={this.handleChange("orgShortName")}
                      label='Organization Short Name'
                      placeholder="Organization Short Name"
                      InputProps={false}
                      phone={false}
                      search={false}
                      count={15}
                      color="green"
                    ////helperText="helper text"
                    //type="search"
                    />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className="field_box">
                    <BaseSelect
                      value={orgType}
                      onChange={this.handleChange("orgType")}
                      label="Organization Type"
                      placeholder="Organization Type"
                      object={object}
                      autoWidth={true}
                    ////helperText="helper text"
                    />
                  </div>
                </Grid>

                

                <Grid item xs={12}>
                  <MediaUpload
                    addMediaItem={this.callbackLogo}
                    label="Logo"
                    buttonName="ADD LOGO"
                    typeOfMedia='/images'
                    selectedMedia={{ logoUrl: logoUrl, logoName: logo }}
                  />
                  {/* <p style={{ color: '#818E94' }} >Logo</p>
                    <BaseFileSelect selectedFiles={this.callbackLogo} /> */}
                  {/* <BaseImgChip headerText="Logo" chipData={chipData} /> */}

                </Grid>

                <Grid item xs={12}>

                  <MediaUpload
                    addMediaItem={this.callbackIcon}
                    label="Logo Icon"
                    buttonName="ADD LOGO ICON"
                    typeOfMedia='/images'
                    selectedMedia={{ logoUrl: iconUrl, logoName: icon }}
                  />
                  {/* <p style={{ color: '#818E94' }} >Icon</p>
                    <BaseFileSelect selectedFiles={this.callbackIcon} />
                    {/* <BaseImgChip headerText="Icon" chipData={chipData2} /> */}
                </Grid>

                <Grid item xs={8}>
                  <div className="field_box">
                    <BaseTextField
                      value={streetAddress}
                      onChange={this.handleChange("streetAddress")}
                      label='Street Address'
                      placeholder="Street Address"
                      InputProps={false}
                      phone={false}
                      search={false}
                      color="green"
                    ////helperText="helper text"
                    //type="search"
                    />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div className="field_box">
                    <BaseTextField
                      value={suiteNumber}
                      onChange={this.handleChange("suiteNumber")}
                      label='FL/Suite Number'
                      placeholder="FL/Suite Number"
                      InputProps={false}
                      phone={false}
                      search={false}
                      color="green"
                    //helperText="helper text"
                    //type="search"
                    />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div className="field_box">
                    <BaseTextField
                      value={city}
                      onChange={this.handleChange("city")}
                      label='City'
                      placeholder="City"
                      InputProps={false}
                      phone={false}
                      search={false}
                      color="green"
                    //helperText="helper text"
                    //type="search"
                    />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div className="field_box">
                    <BaseTextField
                      value={state}
                      onChange={this.handleChange("state")}
                      label='State'
                      placeholder="State"
                      InputProps={false}
                      phone={false}
                      search={false}
                      //helperText="helper text"
                      color="green"
                    //type="search"
                    />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div className="field_box">
                    <BaseTextField
                      value={zipCode}
                      onChange={this.handleChange("zipCode")}
                      label='Zip Code'
                      placeholder="Zip Code"
                      InputProps={false}
                      phone={false}
                      search={false}
                      color="green"
                    //type="search"
                    //helperText="helper text"
                    />
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div className="field_box" style={{ marginTop: "15px" }}>
                    <BasePhoneInput
                      phoneInputVariant={"underlineWithFlag"}
                      countryCodeEditable={false}
                      specialLabel={'Primary Phone Number'}
                      placeholder='Phone Number'
                      country={'us'}
                      value={primaryPhoneNo}
                      onChange={(e, f, g, h) => {
                        console.log("onChange", e, h)
                        if (!phoneRegex.test(e)) {
                          this.setState({
                            primaryPhoneError: false,
                            primaryPhoneNo: h,
                          });
                        } else {
                          this.setState({
                            primaryPhoneError: false,
                            primaryPhoneNo: h,
                          });
                        }
                      }}
                    // errorText={!primaryPhoneNo ? 'Enter valid phone number' : ''}
                    // error={!primaryPhoneNo ? true : false}
                    />
                    {/* <BaseTextField
                    value={primaryPhoneNo}
                    onChange={this.handleChange("primaryPhoneNo")}
                    label='Primary Phone Number'
                    placeholder="Primary Phone Number"
                    InputProps={false}
                    phone={false}
                    search={false}
                    count={100}
                    color="green"
                    //helperText="helper text"
                  /> */}
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div className="field_box" style={{ marginTop: "15px" }}>
                    <BasePhoneInput
                      phoneInputVariant={"underlineWithFlag"}
                      countryCodeEditable={false}
                      specialLabel={'Secondary Phone Number'}
                      placeholder='Phone Number'
                      country={'us'}
                      value={secondaryPhoneNo}
                      onChange={(e, f, g, h) => {
                        console.log("onChange", e, h)
                        if (!phoneRegex.test(e)) {
                          this.setState({
                            secondaryPhoneError: false,
                            secondaryPhoneNo: h,
                          });
                        } else {
                          this.setState({
                            secondaryPhoneError: false,
                            secondaryPhoneNo: h,
                          });
                        }
                      }}
                    // errorText={!secondaryPhoneNo ? 'Enter valid phone number' : ''}
                    // error={!secondaryPhoneNo ? true : false}
                    />
                    {/* <BaseTextField
                      value={secondaryPhoneNo}
                      onChange={this.handleChange("secondaryPhoneNo")}
                      label='Secondary Phone Number'
                      placeholder="Secondary Phone Number"
                      InputProps={false}
                      phone={false}
                      search={false}
                      count={100}
                      color="green"
                      //helperText="helper text"
                    /> */}
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div className="field_box">
                    <BaseTextField
                      value={orgEmail}
                      onChange={this.handleChange("orgEmail")}
                      label='Organization Email'
                      placeholder="Organization Email"
                      InputProps={false}
                      phone={false}
                      search={false}
                      color="green"
                    //helperText="helper text"
                    //type="search"
                    />
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div className="field_box">
                    <BaseTextField
                      value={orgWebsite}
                      onChange={this.handleChange("orgWebsite")}
                      label='Organization Website'
                      placeholder="https://"
                      InputProps={false}
                      phone={false}
                      search={false}
                      color="green"
                    //helperText="helper text"
                    //type="search"
                    />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className="field_box">
                    <BaseSelect
                      value={orgStatus}
                      onChange={this.handleChange("orgStatus")}
                      label="Org Status"
                      placeholder="Org Status"
                      object={object2}
                      autoWidth={true}
                      style={styles.input}
                    //helperText="helper text"
                    />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className="form_button">
                    <BaseButton
                      onClick={() => this.props.history.push(`/organization-detail-${id}`)}
                      text="cancel"
                      textStyle={styles.txt}
                      disableElevation={false}
                      disableFocusRipple={false}
                      disableRipple={false}
                      variant='text'
                    />
                    <BaseButton
                      onClick={this.submitHandler}
                      type="submit"
                      text={'Save'}
                      disableElevation={false}
                      disableFocusRipple={false}
                      disableRipple={false}
                      style={styles.inviteBtn}
                    />
                  </div>


                  {pageContent}
                </Grid>

              </Grid>

            </form>

          }

          isFooter={true}
          footer={
            <FooterInner style={style} />
          }

          isWhiteBackground={true}


        />

      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    organization: state.organizations.organization,
    page: state.page,
    errors: state.errors
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onGetSingleOrganiztion: (id) => dispatch(actions.getOrganizationDetail(id)),
    onClearError: () => dispatch(actions.clearErrors()),
    onSubmit: (id, formData, history) => dispatch(actions.editOrganization(id, formData, history))

  }
};


export default connect(mapStateToProps, mapDispatchToProps)(Index);



