import React from 'react';
import { SimpleTable } from "@dclab/dc-react-ui-kit";
import CreateIcon from "../../assets/img/create-24px.svg";
import DeleteIcon from "../../assets/img/delete_forever-24px.svg";


export default function roleTable(props) {

  const { roles, deleteAction, editAction } = props; 

    let columns = [
 
        { type: "string", name: "name", label: "Application", minWidth: 170 },
        { type: "string", name: "category", label: "Role", minWidth: 200 },
        { type: "action", name: "actionIcons", label: "ACTION", minWidth: 90 },
      ];
      
      let classProps = () => {
        const tempArray = [];
        columns.forEach((column, index) => {
          tempArray.push(`class${index}`);
        });
        return tempArray;
      };
      
      const changeClassProps = () => {
        let tempArray = classProps();
        tempArray[0] = "class15";
        return tempArray;
      };
      
      const columns1 = (columns) =>
        columns.map((obj) => {
          // delete obj.id;
          // delete obj.name;
          return obj;
        });


        let rows = [];
        if(roles && roles.length){
          rows = roles.map((role, index) => {
            return (
                [
                  { type: "string", name: "name", value: role.application ? role.application : ''},
                  { type: "string", name: "category", value: role.roles ? role.roles : '' },
                ]
            )
          });
        }
      
    
      
      const click = (msg) => {
        console.log(msg);
      };
      
      const btnClick=() => {
        console.log('invite')
      }
      
      // const editAction = () => {
      //   console.log('edit')
      // }
      
      // const deleteAction = (data) => {
      //   console.log("checking data in deleteAction: ", data);
      // }

      const actionLink = (name) => {
        console.log(name)
      }

        

    return <SimpleTable
    rows={rows}
    columns={columns1(columns)}
    colSpan={6}
    actionSendButton={false}
    classProps={changeClassProps()}
    onClick={(message, index) => click(message)}
    showFilter={false}
    showAdd={false}
    donationsCount={10}
    noTitle="No recent donations"
    noPara="Add donation categories so that people can donate for a specific cause."
    noBtnText="Invite Donors"
    onInviteClick={() => btnClick()}
    editIcon={CreateIcon}
    deleteIcon={DeleteIcon} 
    showIconCol={true}
    editAction={(data) => editAction(data)}
    deleteAction = { (data ) => deleteAction(data)}
    showFooter={false}
    actionLink={(name) => actionLink(name)}
  />;
}