import axios from 'axios';

import {
    GET_ALL_DEVICES,
    GET_EDIT_DEVICE,
    DELETE_DEVICE,
    GET_NOT_ASSIGNED_DEVICES,
    GET_ORG_ASSIGNED_DEVICES,
    SET_ERRORS
} from './actionTypes';

import {
    setPageLoading,
    clearPageLoading,
    clearErrors,
    showMessage
} from './pageActions';


const backendServerURL = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;

export const getAllDevices = () => dispatch => {
    dispatch(setPageLoading());

    axios
        .get(backendServerURL + '/digital-device', {
            headers: {
                'token': localStorage.authToken
            }
        })
        .then(res => {
            console.log("Checking Result: ", res && res.data && res.data.body && res.data.body.data && res.data.body.data);
            const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
            dispatch({ type: GET_ALL_DEVICES, payload: data });
            dispatch(clearErrors());

        })
        .catch(err => {
            console.log("error: ", err)
            // dispatch({ type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! " } })
        })
        .finally(() => dispatch(clearPageLoading()))
};

export const getEditDevice = (id) => dispatch => {
    dispatch(setPageLoading());

    axios
        .get(backendServerURL + '/digital-device/getDeviceById/' + id, {
            headers: {
                'token': localStorage.authToken
            }
        })
        .then(res => {
            console.log("Edit Device: ", res && res.data && res.data.body && res.data.body.data && res.data.body.data);
            const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
            dispatch({ type: GET_EDIT_DEVICE, payload: data });
            dispatch(clearErrors());
        })
        .catch(err => {
            console.log("error: ", err)
            dispatch({ type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! " } })
        })
        .finally(() => dispatch(clearPageLoading()))
};

export const addDevice = (device, ownProps) => dispatch => {
    dispatch(setPageLoading());

        axios.post(`${backendServerURL}/hikmah/digital-device`, device, {
            headers: {
                'token': localStorage.authToken
            }
        })
        .then(res => {
            ownProps.history.push("/hardware")
            const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
            // dispatch({ type: GET_EDIT_DEVICE, payload: data });
            dispatch(showMessage("Device Added Successfully!"));
            dispatch(clearErrors());
        })
        .catch(err => {
            console.log("error: ", err)
            dispatch({ type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! " } })
        })
        .finally(() => dispatch(clearPageLoading()))
};

export const editDevice = (device, ownProps) => dispatch => {
    dispatch(setPageLoading());

        axios.put(`${backendServerURL}/digital-device/${ownProps.match.params.id}`, device, {
            headers: {
                'token': localStorage.authToken
            }
        })
        .then(res => {
            const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
            // dispatch({ type: GET_EDIT_DEVICE, payload: data });
            dispatch(showMessage("Device Updated Successfully!"));
            dispatch(clearErrors());
            dispatch({ type: GET_EDIT_DEVICE, payload: {} });
            ownProps.history.push(`/hardware-details/${ownProps.match.params.id}`)
        })
        .catch(err => {
            console.log("error: ", err)
            dispatch({ type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! " } })
        })
        .finally(() => dispatch(clearPageLoading()))
};

export const deleteDevice = (id) => dispatch => {
    dispatch(setPageLoading());

    axios
        .put(backendServerURL + '/digital-device/deleteDevice/' + id, {}, {
            headers: {
                'token': localStorage.authToken
            }
        })
        .then(res => {
            console.log("Deleted Device");
            dispatch(showMessage("Hardware deleted successfully!"));
            dispatch(clearErrors());
            dispatch({ type: DELETE_DEVICE, payload: id });
        })
        .catch(err => {
            console.log("error: ", err)
        })
        .finally(() => dispatch(clearPageLoading()))
};

export const getNotAssignedDevices = () => dispatch => {
    dispatch(setPageLoading());

    axios
        .get(backendServerURL + '/digital-device/getNotAssignedDevices', {
            headers: {
                'token': localStorage.authToken
            }
        })
        .then(res => {
            console.log("Checking Result: ", res && res.data && res.data.body && res.data.body.data && res.data.body.data);
            const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
            dispatch({ type: GET_NOT_ASSIGNED_DEVICES, payload: data });
            dispatch(clearErrors());
        })
        .catch(err => {
            console.log("error: ", err)
        })
        .finally(() => dispatch(clearPageLoading()))
};

export const getOrgAssignedDevices = (id) => dispatch => {
    dispatch(setPageLoading());

    axios
        .get(backendServerURL + `/digital-device/getOrgAssignedDevices/${id}`, {
            headers: {
                'token': localStorage.authToken
            }
        })
        .then(res => {
            console.log("Checking Result getOrgAssignedDevices: ", res && res.data && res.data.body && res.data.body.data && res.data.body.data);
            const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
            dispatch({ type: GET_ORG_ASSIGNED_DEVICES, payload: data });
            dispatch(clearErrors());
        })
        .catch(err => {
            console.log("error: ", err)
            dispatch({ type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! " } })
        })
        .finally(() => dispatch(clearPageLoading()))
};

export const showAssignedAlert = () => dispatch => {
    dispatch(showMessage("Device Assigned Successfully"));
};

export const showRemoveDeviceAlert = () => dispatch => {
    dispatch(showMessage("Hardware removed successfully!"));
};