import React, { useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid';
import { MasterLayoutMiddle, FooterInner, TableStats, Activities } from "@dclab/dc-react-ui-kit";


function dashboardStats(props) {


  // ============================== Table Start ======================================//
  const Organizations = [
    {
      name: "New org",
      amount: "50",
      statusColor: "statusgreen"
    },

    {
      name: "Onboarding",
      amount: "5",
      statusColor: "statusgreen"
    },

    {
      name: "Active organizations",
      amount: "25",
      statusColor: "statusgreen"
    },

    {
      name: "Invited",
      amount: "500",
      statusColor: "statusgreen"
    },

    

  ];

  const Orders = [
    {
      name: "New orders",
      amount: "50",
      statusColor: "statusgreen"
    },

    {
      name: "Completed orders",
      amount: "5",
      statusColor: "statusgreen"
    },

    {
      name: "Pending orders",
      amount: "25",
      statusColor: "statusgreen"
    },

    {
      name: "Total order amount",
      amount: "$10,000",
      statusColor: "statusgreen"
    },

  ];

  const Recent_Organizations = [
    {
      name: "Islamic Institute of Brooklyn",
      date: "Jun 12",
      align: "right",
      statusColor: "statusgreen"
    },

    {
      name: "Masjid Bilal of New York City",
      date: "Jun 12",
      align: "right",
      statusColor: "statusyelllow"
    },

    {
      name: "Darul Jannah Masjid",
      date: "Jun 12",
      align: "right",
      statusColor: "statusred"
    },

    {
      name: "Masjid Bilal of New York City",
      date: "Jun 12",
      align: "right",
      statusColor: "statuspurple"
    },

  ];

  const Recent_Orders = [
    {
      name: "1848-5138",
      date: "Jun 12",
      amount: "$600",
      align: "left",
      statusColor: "statusgreen"
    },

    {
      name: "1848-5137",
      date: "Jun 12",
      amount: "$600",
      align: "left",
      statusColor: "statusyelllow"
    },

    {
      name: "1848-5136",
      date: "Jun 12",
      amount: "$600",
      align: "left",
      statusColor: "statusred"
    },

    {
      name: "1848-5134",
      date: "Jun 12",
      amount: "$600",
      align: "left",
      statusColor: "statuspurple"
    },

  ];

  const selectdata = [
    { value: 1, select: "Today" },
    { value: 2, select: "Last 7 days" },
    { value: 3, select: "Default value" },
    { value: 4, select: "Last 4 weeks" },
    { value: 5, select: "Last 3 months" },
    { value: 6, select: "Last 12 months" },
    { value: 7, select: "Last year" },
  ];
  // ================ Table End =====================//

  // =============== Activities Start ==============//
   const ActivitiesData =[
        
    {   org:"Darul Salaam",
        text:"submitted tax exemption request.",
        Link:"http://daruljannah.org/",
        Date:"Feb 14, 2021, 10:00 AM"

    },

    {   org:"Darul Salaam",
        text:"submitted tax exemption request.",
        Link:"http://daruljannah.org/",
        Date:"Feb 14, 2021, 10:00 AM"

    },

    {   org:"Darul Salaam",
        text:"submitted tax exemption request.",
        Link:"http://daruljannah.org/",
        Date:"Feb 14, 2021, 10:00 AM"

    },
];

// ============== Activities End ================//

  const style = {
    width: '100%',
  };
  return (
    <MasterLayoutMiddle
      content={
        <div>
          <Grid container spacing={0} >
            <Grid item md={10} sm={10} xs={12} >

              <Grid container spacing={3} >
                <Grid item md={6} sm={6} xs={12} >
                  <TableStats
                    Title="Organizations"
                    seemore="See More"
                    tabledata={Organizations}
                    selectdata={selectdata}
                    defaultValue={1}
                  />
                </Grid>

                <Grid item md={6} sm={6} xs={12} >
                  <TableStats
                    Title="Orders"
                    seemore="See More"
                    tabledata={Orders}
                    selectdata={selectdata}
                    defaultValue={1}
                  />
                </Grid>

                <Grid item md={6} sm={6} xs={12} >
                  <TableStats
                    Title="Recent Organizations"
                    seemore="See More"
                    tabledata={Recent_Organizations}
                    selectdata={selectdata}
                    Status={true}
                    defaultValue={1}
                  />
                </Grid>

                <Grid item md={6} sm={6} xs={12} >
                  <TableStats
                    Title="Recent Orders"
                    seemore="See More"
                    tabledata={Recent_Orders}
                    selectdata={selectdata}
                    Status={true}
                    defaultValue={1}
                  />
                </Grid>

              </Grid>

            </Grid>
          </Grid>

          <Activities  
              Title="Activities"
              ActivitiesData={ActivitiesData}
              selectdata={selectdata}
              Boxheight="94%"
              BoxPosition="fixed"
              borderRadius="0px"
              defaultValue={1}
              seemore="See More"
              Seamore={false}
              SpaceTop="80px"
          />

        </div>
      }
      isFooter={true}
      footer={
        <FooterInner style={style} />
      }
      isWhiteBackground={false}
    />
  );
}

export default dashboardStats;