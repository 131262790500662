import axios from 'axios';

import {
    SET_ALL_CAMPAIGN,
    SET_ERRORS
} from './actionTypes';

import {
    setPageLoading,
    clearPageLoading,
    clearErrors,
    showMessage
} from './pageActions';



const backendServerURL = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;

export const addCampaign = (campaign, ownProps) => dispatch => {
    dispatch(setPageLoading());

    axios
        .post(backendServerURL + '/hikmah/createcampaign', campaign, {
            headers: {
                'token': localStorage.authToken
            }
        })
        .then(res => {
            if(res?.data?.error) {
                dispatch({ type: SET_ERRORS, payload: res && res.data  ? res.data : { message: "Invalid Promo Code!" } });
                setTimeout(() => {
                    dispatch(clearErrors());
                }, 5000);
            } else {
                const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
                dispatch(showMessage("Campaign added from Stripe"));
                dispatch(clearErrors());
                ownProps.history.push(`/campaign`)
            }

        })
        .catch(err => {
           
            dispatch({ type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! " } })
        })
        .finally(() => dispatch(clearPageLoading()))
};

export const getAllCampaign = () => dispatch => {
    dispatch(setPageLoading());

    axios
        .get(backendServerURL + '/hikmah/allcampaigns', {
            headers: {
                'token': localStorage.authToken
            }
        })
        .then(res => {
            const data = res?.data?.body?.data ?? [];
            dispatch({ type: SET_ALL_CAMPAIGN, payload: data });
            dispatch(clearErrors());
           

        })
        .catch(err => {
            console.log("error: ", err)
            // dispatch({ type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! " } })
        })
        .finally(() => dispatch(clearPageLoading()))
};