import React, { Component } from 'react';
import { BaseTabs, MasterLayoutMiddle, FooterInner, } from "@dclab/dc-react-ui-kit";

import TabContent1 from './tabContent1';
import TabContent2 from './tabContent2';
import TabContent3 from './tabContent3';
import TabContent4 from './tabContent4';

//for send props to chage the menu
import Navbar from '../../components/NavDawer/NavDrawer';
import classes from './organizationDetail.module.css';

import Alert from '../../components/common/AlertAction';
import { connect } from 'react-redux';
import * as actions from '../../store/Actions/index';
import Spinner from '../../components/common/Spinner';
import Grid from '@material-ui/core/Grid';


class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      errors: {},
      loading: false,
      organization: {},
      teamMembers: [],
      id: '',
      currentTab: 0

    };
  }


  static getDerivedStateFromProps(props, state) {

    let errors = props.errors;
    let page = props.page;
    let organization = props.organization ? props.organization : {};
    let teamMembers = props.teamMembers ? props.teamMembers : {};



    let stateChanged = false;
    let changedState = {};

    if (organization && JSON.stringify(state.organization) !== JSON.stringify(organization)) {
      changedState.organization = organization;
      stateChanged = true;
    }

    if (teamMembers && JSON.stringify(state.teamMembers) !== JSON.stringify(teamMembers)) {
      changedState.teamMembers = teamMembers;
      stateChanged = true;
    }

    if (errors && JSON.stringify(state.errors) !== JSON.stringify(errors)) {
      changedState.errors = errors;
      stateChanged = true;
    }

    if (page && JSON.stringify(state.loading) !== JSON.stringify(page.loading)) {
      changedState.loading = page.loading;
      stateChanged = true;
    }

    if (stateChanged) {
      return changedState;
    }


    return null;
  }




  onClick = (x, y) => {
    console.log('checking onClick function: ', x)
    console.log('checking onClick function Y:', y)
    if (y === 1) {
      const id = this.props.match.params.id;
      this.props.onGetTeamMembers(id);
    }
  }

  styles = {
    routes: {
      marginLeft: '70px',
      width: '90%',
      padding: '30px 0',
    }

  };

  showAlertHandler = () => {
    this.props.onClearError();
  }

  componentDidMount() {
    console.log("componentDidMount");
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    const id = this.props.match.params.id;
    this.setState({
      id: id
    });
    this.props.onGetSingleOrganiztion(id);
    this.props.onGetStripeAccountDetail(id);
  }

  onDeleteMember = (data) => {
    console.log("delete Id: ", data);
    let orgId = this.props.match.params.id;
    this.props.onDeleteMember(data[0].id, orgId);
  }

  render() {

    let { errors, loading, organization, show, teamMembers } = this.state;
    let pageContent = '';
    let message = 'Invite sent successfully! ';
    let type = 'success';
    let styles = this.styles;


    if (loading) {
      pageContent = <Spinner />

    }
    if (errors && errors.message) {
      message = errors.message;
      type = 'error';
      show = true;
    }

    const object = [
      {
        label: "ORG DETAIL",
        item: <TabContent1 organization={organization} history={this.props.history} />
      },
      {
        label: "TEAM MEMBERS",
        item: <TabContent2 teamMembers={teamMembers} deleteAction={this.onDeleteMember} id={this.state.id} history={this.props.history} />,
      },
      {
        label: "VERIFICATION",
        item: <TabContent3 organization={organization} stripeOrgDetails={this.props.stripeOrgDetails} history={this.props.history} />,
      },
      {
        label: "LOGS",
        item: <TabContent4 organization={organization} history={this.props.history} />
      },

    ];


    const handleChange = (event, newValue) => {
      this.setState({ currentTab: newValue });
    };

    const style = {
      width:'100%',
    };

    return (
      <MasterLayoutMiddle
          header={          
            <div>
              {/* <Alert message={message} type={type} click={this.showAlertHandler} show={show} /> */}
              <div style={{ display: 'none' }}>
              {console.log("into navBAR")}
              {/* <Navbar orgDetail={true} /> */}
              </div>  
            </div>
            }
            content={
            <div>

            <Grid container spacing={3}>	      

              <Grid item xs={12}> 
                <BaseTabs
                title=""
                value={this.state.currentTab}
                object={object}
                indicatorColor="#7266fb"
                style={{ color: "#2490F9", border: "1px solid #E8E9E9", width: '100%', background: 'white', padding: '0px 0' }}
                tabsStyle={{ width: '25%' }}
                onClick={(x, y) => this.onClick(x, y)}
                onChange={(event, newValue) => handleChange(event, newValue)}
              />
              {pageContent}
              </Grid>

            </Grid>

            
            </div>
          }
  
          isFooter={true}
          footer={
            <FooterInner style={style}/>
          }

          isWhiteBackground={false}
      />
      
    )
  }
}


const mapStateToProps = state => {
  return {
    organization: state.organizations.organization,
    teamMembers: state.teamMembers.teamMembers,
    stripeOrgDetails: state.organizations.stripeOrgDetails,
    page: state.page,
    errors: state.errors
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onGetSingleOrganiztion: (id) => dispatch(actions.getOrganizationDetail(id)),
    onGetStripeAccountDetail: (id) => dispatch(actions.getStripeAccountDetail(id)),
    onGetTeamMembers: (id) => dispatch(actions.getAllTeamMembers(id)),
    onDeleteMember: (id, orgId) => dispatch(actions.deleteTeamMember(id, orgId)),
    onClearError: () => dispatch(actions.clearErrors())
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(Index);













