import React, { useEffect, useState } from "react";
import { MasterLayoutMiddle, FooterInner, CheckOrderSummary, SimpleTable, PageHeaderWithButton  } from "@dclab/dc-react-ui-kit";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Grid from '@material-ui/core/Grid';
import './style.css'
import Moment from 'moment';


function ManageOrder(props) {

  // ============================== Oder Sumary start ======================================//
  const [discountCode, setDiscountCode] = React.useState("");
  const [discount, setDiscount] = React.useState(0);
  const [cartItems, setcartItems] = useState([]);
  const [orderData, setOrderData] = useState({})


  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(event, newValue);
  };
  
  
  // const onClick = (x, y) => {
  //   console.log(x);
  //   console.log(y);
  // };
  
  const saveChanges = () => {
    console.log("save");
  };
  
  const publish = () => {
    console.log("publish");
  };
  const back = () => {
    console.log("back");
  };
  
  const saveStyles = {
    backgroundColor: "#2A82F6",
  };
  
  const publishStyles = {
    backgroundColor: "#2A82F6",
    opacity: 0.6,
  };
  const livePauseStyles = {
    display: "flex",
    alignItems: "center",
    marginLeft: "8px",
    marginTop: "1px",
    height: "36px",
    minWidth: "114px",
    backgroundColor: "#FFFFFF",
    borderTopLeftRadius: "30px",
    borderBottomLeftRadius: "30px",
    borderTopRightRadius: "30px",
    borderBottomRightRadius: "30px",
    cursor : "pointer",
    border: "1px solid #D3D8DA",
    textTransform:"uppercase",
    justifyContent:"center",
    alignItems:"center",
    minWidth:"114px",
  };
  const livePauseTextStyle = { 
    margin: "0px", 
    fontSize: "14px", 
    color: "#4D4D4D", 
    fontWeight: 'bold', 
    fontFamily: '"Roboto", "Helvetica"' }
  
  
  
  const paperStyles = {
    backgroundColor: '#fff'
  };












  useEffect(() => {
    let cartData = props?.location?.state?.orderData ? props.location.state.orderData : {};
    setOrderData(cartData)
    setcartItems(cartData.orderItems ?? [])
  }, [])

  React.useEffect(() => {
    if (discount < 100 && discountCode !== "") {
      setDiscount(discount + 1);
    } //Set Your Discount Logic Here.
    if (discountCode === "") {
      setDiscount(0);
    }
  }, [discountCode]);

  const callback = (code, total) => {
    console.log(total);
    setDiscountCode(code);
  };

  const onClick = () => {
    console.log("clicker")
    props.history.push("/checkout")
  }

  // ============================== Oder Sumary End ======================================//

  // ============================== Table Start ======================================//
  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };
  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };
  const capitalizeFLetter = (data) => {
    return data[0].toUpperCase() + data.slice(1);
  };
  let col = [
    { type: "", name: "", label: "", width: "auto" },
    { type: "link", name: "invoice_number", label: "Items", width: "20%" },
    { type: "string", name: "name", label: "Price", width: "15%" },
    { type: "string", name: "name", label: "Cycle", width: "20%" },
    { type: 'string', name: 'itemQuantity', label: 'Quantity', width: '20%' },
    { type: "string", name: "name", label: "Total", width: "15%" },
    { type: 'deleteItem', label: '', width: "10%" },
  ];

  let sum = 0;
  const rows = cartItems?.length ? cartItems.map((item) => {
    let appName = item.name;
    let planName = item.planType;
    let paymentFrequency = item.frequency;
    let monthTotal = (item.amount / 100).toFixed(2) ?? 0;
    return [
      {
        name: "textImg", type: "string",
        primaryText: `${appName?.toUpperCase() ?? ""} ${planName ? "- " + planName.toUpperCase() : ""}`,
        initials: !item.productImage ? appName[0] : "",
        src: item.productImage ?? '',
        appName: appName
      },
      { type: "string", name: "frequency", value: `$${parseInt(item.amount / 100)}${item.frequency === "monthly" ? '/mo' : item.frequency === 'annually  ' ? '/yr' : ''}` },
      { type: "string", name: "frequency", value: capitalizeFLetter(item.frequency) },
      { type: '', name: 'itemQuantity', value: "deleteIcon" }, //itemQuantityInput
      { type: "string", name: "date/time", value: `$${parseInt(item.amount / 100) ?? 0}` },
      { type: 'deleteItem', name: 'deleteIcon', disabled: false },
    ]
  }) : [];


  const dataset = [
    {
      title: "Subtotal",
      value: `$${orderData.totalAmount ? (orderData.totalAmount / 100).toFixed(2) : 0.00}`,
    },
    {
      title: `Tax for:${(orderData?.shippingAddress?.postal_code || orderData?.shippingAddress?.postalCode)  ?? ''}`,
      value: "$0.00",
    },
    {
      title: "Discounts",
      value: "$0.00",
    },
    {
      title: "Promo applied",
      promo: "EID2021"
    },
  ];


  const deleteAction = (deleteData) => {
    console.log(deleteData);
  };
  const deleteItemAction = (deleteData) => {
    console.log("deleteItemAction", deleteData);

    let items = cartItems;
    items = items.length ? items.filter((item) => deleteData[0].appName !== Object.keys(item)[0]) : [];

    console.log("items", items);
    localStorage.setItem("appCartItem", JSON.stringify(items));
    setcartItems(items)

  };
  const deleteActionChild = (deleteData) => {
    console.log(deleteData, 'c');
  };

  const changeQuantityAction = (value, item) => {
    console.log("changeQuantityAction", value, item);
  };


  // ============================== Table End ======================================//

  const style = {
    width: '100%',
  };

  return (
    <div>
      <MasterLayoutMiddle
        header={
          <>
            <div
              onClick={() => props.history.goBack()}
              className="backBtn">
              <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
              <p style={{ alignSelf: "center" }}>Back</p>{" "}
            </div>


            <PageHeaderWithButton
              title="Manage Order"
              indicatorColor="primary"
              style={{ color: "#fff", border: "1px solid " }}
              handleChange={(x, y) => handleChange(x, y)}
              headerColor={'#000'}
              saveStyles={saveStyles}
              publishStyles={publishStyles}
              paperStyles={paperStyles}
              saveChanges={() => saveChanges()}
              publish={() => publish()}
              disabled={false}
              back={back}
              showBackBtn={true}
              livePauseBtnText={"CANCEL ORDER"}
              livePauseStyles={livePauseStyles}
              livePauseOnclick={(e) => console.log('!props.livePauseBtnText')}
              livePauseTextStyle={livePauseTextStyle}
              hiddenDraft={false}
              hiddenPublish={false}
              btnText="Back"
              saveBtn="UPDATE ORDER"
            />

          </>
        }

        content={
          <div className="AppPageContainer cart-table-data">

            <Grid container spacing={3} className="">

              <Grid item xs={8}>
                <div className="your_cart_title">Items</div>
                <SimpleTable
                  parentTableDrag={true}
                  showHeader={false}
                  title=""
                  width="100%"
                  rows={rows}
                  columns={col}
                  colSpan={16}
                  actionSendButton={true}
                  classProps={changeClassProps()}
                  deleteActionChild={(deleteItem) => deleteActionChild(deleteItem)}
                  deleteItemAction={(deleteItem) => deleteItemAction(deleteItem)}
                  changeQuantityAction={(value, item) => changeQuantityAction(value, item)}
                  donationsCount={rows && rows.length > 0 ? 1 : 0}
                  noTitle="No Items added"
                  noPara="Added Items will be displayed and managed here"
                  noBtnText="Add New Items"
                  //onInviteClick={() => props.history.push( "/more-apps" )}
                  actionLink={(name) => this.details(name)}
                  showIconCol={false}
                  actionSendButton={true}
                  deleteAction={(e) => this.deleteServiceAction(e)}
                  showFooter={false}
                  showAdd={false}
                  addText="ADD NEW"
                  //onAddClick={() => props.history.push( "/more-apps" )}
                  iconTextSize={16}
                />
              </Grid>

              <Grid item xs={4}>
                <CheckOrderSummary
                  dataset={dataset}
                  callback={callback}
                  discount={discount}
                  summary="Order Summary"
                  dropDownSummary="Promo code"
                  dropDownDetails="Enter Code Here"
                  bottomText="Total"
                  buttomSubText="$0"
                  text="Continue to payment"
                  //apply="APPLY"
                  //onClick={onClick}
                  total={`${orderData.totalAmount ? (orderData.totalAmount / 100).toFixed(2) : 0.00}`}
                  editCartSection={false}
                  showCheckoutButton={false}
                  CodeField={true}
                  BottomBorder={true}
                  CodeField={false}
                  BottomBorder={true}
                />
              </Grid>

            </Grid>

          </div>
        }


        isFooter={true}
        footer={
          <FooterInner style={style} />
        }

        isWhiteBackground={false}

      />

    </div>
  )
}


export default ManageOrder
