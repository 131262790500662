import React, { useState, Fragment, useEffect, useRef } from 'react'
import { DrawerMain, BaseButton, BaseTextField, BaseSelect, TextEditor, BaseImgChip, BaseFileSelect, AlertAction, MasterLayoutMiddle, FooterInner, TextInputFixedLabel  } from "@dclab/dc-react-ui-kit";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import classes from './addHardware.module.css';
import axios from 'axios'
import { connect } from 'react-redux';
import * as actions from '../../store/Actions/index';
import Spinner from '../../components/common/Spinner';
import "./products.css"
import {Grid, Container, Button} from '@material-ui/core';  
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { BaseStepperExtended } from "@dclab/dc-react-ui-kit";
import BasicInfo from './ApplicationSteps/basicInfo';
import Features from './ApplicationSteps/features';
import Plans from './ApplicationSteps/plans';
import Hardwares from './ApplicationSteps/hardware';
import AppStatus from './ApplicationSteps/status';
import { makeStyles } from '@material-ui/core/styles';

const initialPlans = [ 
    {
        monthlyBilling: { stripePlanId: "", price : 0, billingPeriod : "Monthly", discountPercentage : 0 },
        annuallyBilling: { stripePlanId: "", price : 0, billingPeriod : "Annually", discountPercentage : 0 },
        features: [],
        id : "free-plan", name : "Free", status : "Available"
    },
    {
      monthlyBilling: { stripePlanId: "", price : 0, billingPeriod : "Monthly", discountPercentage : 0 },
      annuallyBilling: { stripePlanId: "", price : 0, billingPeriod : "Annually", discountPercentage : 0 },
      features: [],
      id : "standard-plan", name : "Standard", status : "Available"
    },
    {
      monthlyBilling: { stripePlanId: "", price : 0, billingPeriod : "Monthly", discountPercentage : 0 },
      annuallyBilling: { stripePlanId: "", price : 0, billingPeriod : "Annually", discountPercentage : 0 },
      features: [],
      id : "premium-plan", name : "Premium", status : "Available"
    },
    {
      monthlyBilling: { stripePlanId: "", price : 0, billingPeriod : "Monthly", discountPercentage : 0 },
      annuallyBilling: { stripePlanId: "", price : 0, billingPeriod : "Annually", discountPercentage : 0 },
      features: [],
      id : "custom-plan", name : "Custom", status : "Available"
    },
  ];

function AddProduct(props) {

const [activeStep, setActiveStep] = React.useState(0);
const [stepDisabled, setstepDisabled] = useState(true)

const childRef = useRef();
const childRef2 = useRef();
const childRef3 = useRef();
const childRef4 = useRef();
const childRef5 = useRef();


const getSteps = () => {
  return [
    { number: 1, label: "Basic Info", value: <BasicInfo setChanges = {() => {}} ref={childRef} applicationInfo={props.applicationInfo} onAddAppInfo={props.onAddAppInfo} setstepDisabled={setstepDisabled} /> },
    { number: 2, label: "Features", value: <Features setChanges = {() => {}}  ref={childRef2} applicationInfo={props.applicationInfo} onAddAppInfo={props.onAddAppInfo} setstepDisabled={setstepDisabled} /> },
    { number: 3, label: "Plans", value: <Plans setChanges = {() => {}}  ref={childRef3} applicationInfo={props.applicationInfo} onAddAppInfo={props.onAddAppInfo} setstepDisabled={setstepDisabled} history={props.history}  /> },
    { number: 4, label: "Hardware", value: <Hardwares setChanges = {() => {}}  ref={childRef4} applicationInfo={props.applicationInfo} onAddAppInfo={props.onAddAppInfo} setstepDisabled={setstepDisabled} /> },
    { number: 5, label: "Status", value: <AppStatus setChanges = {() => {}}  ref={childRef5} applicationInfo={props.applicationInfo} onAddAppInfo={props.onAddAppInfo} setstepDisabled={setstepDisabled} /> },
  ];
};
const handleNext = () => {
    if(activeStep === 0) {
        childRef.current.onSubmit()
    } else if (activeStep === 1) {
        childRef2.current.onSubmit()
    } else if (activeStep === 2) {
        childRef3.current.onSubmit()
    } else if (activeStep === 3) {
        childRef4.current.onSubmit()
    } else if (activeStep === 4) {
        childRef5.current.onSubmit()
        props.onAddApplication()
    }

    if(activeStep < 4) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
};

const handleBack = () => {
    if(activeStep > 0) {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    } else {
        props.history.push("/admin-products");
    }
};
// const handleBackPreviousPage = () => {
//   console.log('BackButton');
// }

useEffect(() => {
    // console.log(props.location?.state?.step, "sdd")
    if(!props.applicationInfo?.plans?.length) {
        let formData = {
            plans: [...initialPlans]
        }
        props.onAddAppInfo(formData)
    }
    if(props.location?.state?.step) {
        setActiveStep(props.location.state.step)
    } else {
        setActiveStep(0)
    }
}, [])


const styles2 = {
  stepper: {
    marginTop: 150,
    padding: "8px 20px",
    borderRadius: "30px",
  },
};

    let styles = {
        txt: {
            color: '#4D4D4D ',
            letterSpacing: '1.26px',
            textAlign: 'left',
            fontFamily: 'Roboto',
            fontWeight: 500,
            fontSize: '15px'
        },
        chipInput: {
            width: "100%"
        },
        backBtn: {
            backgroundColor: 'white',
            borderRadius: '18px',
            color: '#4D4D4D',
            textAlign: 'left',
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '15px',
            width: '86px',
            height: '36px'
        },
        routes: {
            marginLeft: '70px',
            width: '90%',
            padding: '30px 0',
            display: 'flex'
        },
        input: {
            height: '48px',
            marginTop: '20px',
            width: '100%',
            border: "#707070",
        },
        input2: {
            height: '48px',
            marginTop: '20px',
            marginRight: '22px',
            width: '48%',
            float: 'left',
            border: "#707070",
        },
        input3: {
            height: '48px',
            marginTop: '20px',
            width: '48%',
            border: "#707070",
        },
        inviteBtn: {
            textAlign: 'center',
            color: 'white',
            letterSpacing: '1.26px',
            height: '45px',
            background: '#2490F9 0% 0% no-repeat padding-box',
            borderRadius: '23px',
            padding: '14px 45px'
        },
        disableBtn: {
            textAlign: 'center',
            color: '#222',
            letterSpacing: '1.26px',
            height: '45px',
            background: '#eee',
            borderRadius: '23px',
            padding: '14px 45px'
        },
        cancelBtn: {
            textAlign: 'center',
            color: 'black',
            letterSpacing: '1.26px',
            height: '45px',
            borderRadius: '23px',
            backgroundColor: 'white',
            padding: '14px 45px'
        },

        
    }

    const style = {
        width:'100%',
    };

    const useStyles = makeStyles((theme) => ({

        SteperContainer: {

            "& .stepper_container .MuiStepper-horizontal": {
                width:"80% !important",
                maxWidth:"990px",
            },

        },

     }));


     const classes = useStyles();

    return (
        <Fragment>

            <MasterLayoutMiddle
                header={
                    <div>
                    <div className="backBtnFrom">
                    <BaseButton
                        onClick={() => handleBack()}
                        text="back"
                        textStyle={styles.txt}
                        disableElevation={false}
                        disableFocusRipple={false}
                        disableRipple={false}
                        style={styles.backBtn}
                        startIcon={<ChevronLeftIcon />}
                    />
                    </div>
                    {/* </Link> */}
                    {props.page && props.page.loading && <Spinner />}
                   
                </div>
                }
                
                content={
                    <div className={classes.SteperContainer}>
                    <BaseStepperExtended
                    title="Add Applications"
                    getSteps={getSteps}
                    alternativeLabel={true}
                    handleBack={() => handleBack()}
                    handleNext={() => handleNext()}
                    nonLinear={true}
                    bottomLine={true}
                    backButton={false}
                    backgroundColor={'transparent'}
                    orientation={"horizontal"}
                    btnStyles={styles2.stepper}
                    activeStep={activeStep}
                />
                </div>
                }
                isFooter={true}
                footer={
                <FooterInner style={style}/>
                }
                isWhiteBackground={true}
                />

            <div className="sticky-proceed-footer-wrapper stickyfooter_border">
                <div className="sticky-proceed-footer">
                    
                            <div className="cancel-next-btn">
                                <Button 
                                //   disabled={activeStep === 0} 
                                    onClick={handleBack} 
                                >
                                    Back
                                </Button>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={stepDisabled}
                                    onClick={handleNext}
                                    className={`proceed-btn ${stepDisabled ? "disabledStepper" : ""}`}>
                                    {activeStep === 4 ? 'ADD APPLICATION' : 'CONTINUE'}
                                </Button>
                            </div>
                       
                </div>
                </div>
        </Fragment>
    )
}

const mapStateToProps = state => {
    return {
          page: state.page,
          errors: state.errors,
          applicationInfo: state.products.applicationInfo
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onAddAppInfo: (application) => dispatch(actions.addAppInfo(application)),
        onAddApplication: () => dispatch(actions.addApplication(ownProps)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProduct);
