import { 
	SET_TEAM_MEMBERS,
	SET_FINANCE_TEAM_MEMBERS,
	SET_VERIFY_TEAM_MEMBER_EMAIL,
	SET_FINANCE_TEAM_MEMBER
} from '../Actions/actionTypes';

const initialState = {
	teamMembers: [],
	financeTeamMembers: [],
	verifyTeamMemberEmail: "",
	financeTeamMember: {}
};

export default function(state = initialState, action) {
	switch (action.type) {
		case SET_TEAM_MEMBERS:
			return { ...state, teamMembers: action.payload }
		case SET_FINANCE_TEAM_MEMBERS:
			return {...state, financeTeamMembers: action.payload }	
		case SET_FINANCE_TEAM_MEMBER:
			return {...state, financeTeamMember: action.payload }	
		case SET_VERIFY_TEAM_MEMBER_EMAIL:
			return {...state, verifyTeamMemberEmail: action.payload }	

		default:
			return state;
	}
}