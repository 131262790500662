import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';


import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import authReducer from './store/Reducers/authReducer';
import errorReducer from './store/Reducers/errorReducer';
import pageReducer from './store/Reducers/pageReducer';
import organizationReducer from './store/Reducers/organizationReducer';
import teamMemberReducer from './store/Reducers/teamMemberReducer';
import hardwareReducer from './store/Reducers/hardwareReducer';
import contactReducer from './store/Reducers/contactReducer';
import productReducer from './store/Reducers/productReducer';
import orderReducer from './store/Reducers/orderReducer';
import campaignReducer from './store/Reducers/campaignReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    auth: authReducer,
    errors: errorReducer,
    page: pageReducer,
    organizations: organizationReducer,
    teamMembers: teamMemberReducer,
    devices: hardwareReducer,
    contacts: contactReducer,
    products: productReducer,
    orders: orderReducer,
    campaigns: campaignReducer
});

//const store = createStore(burgerBuilderReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));



const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);


ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
