import React from 'react';
import { AlertAction } from "@dclab/dc-react-ui-kit";

export default function Alert(props) {
  return (
    props.show &&
    <div className="header_alert">
        <AlertAction
          alert={props.message}
          alertType={props.type}
          onClick={props.click}
        />
    </div>

  )
}
