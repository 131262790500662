import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import tickIcon from '../../../assets/img/cancel-24px.svg';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    marginLeft: '7px',
  },
  itemLeft: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    letterSpacing: '0px',
    color: '#818E94',
    opacity: 1
  },
  itemRight: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    letterSpacing: '0px',
    color: '#4D4D4D',
    opacity: 1,

  }
}));

export default function ControlledExpansionPanels() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>
              <img src={tickIcon} alt='' width='24' height='24' style={{verticalAlign: 'bottom', paddingRight: '10px'}}  /> Rejected </Typography>
          <Typography className={classes.secondaryHeading}>June 03, 2020, 12:00 PM</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{display: 'block'}}>

         <Grid container md={12} sm={12} style={{height: '40px'}}>
             <Grid item md={4}>
                 <Grid container direction="row" justify="flex-start" alignItems="center" >
                   <Grid item style={{marginLeft: '40px'}}>
                       <Typography className={classes.itemLeft}> Reviewed by </Typography>
                   </Grid>
                 </Grid>
             </Grid>
            <Grid item md={8}>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                      <Typography className={classes.itemRight}>Nafseen Al Shoaib</Typography>
                </Grid>
            </Grid>
         </Grid>
        
         <Grid container md={12} sm={12} style={{height: '40px'}}>
             <Grid item md={4}>
                 <Grid container direction="row" justify="flex-start" alignItems="center" >
                   <Grid item style={{marginLeft: '40px'}}>
                       <Typography className={classes.itemLeft}> Message </Typography>
                   </Grid>
                 </Grid>
             </Grid>
            <Grid item md={8}>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                      <Typography className={classes.itemRight}>We just want to send you a quick thank you note for your awesome contribution
                      </Typography>
                </Grid>
            </Grid>
         </Grid>
        
        
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}
