import React, {useEffect} from 'react';
import moment from 'moment';
import { SimpleTable, MasterLayoutMiddle, FooterInner } from "@dclab/dc-react-ui-kit";
import { connect } from 'react-redux';
import * as actions from '../../store/Actions/index';
import Spinner from '../../components/common/Spinner';

function Comunication(props) {
  console.log("allcontacts:", props.allRequests)
  const {allRequests} = props;

  useEffect(() => {
    props.onGetAllRequests();
  }, [])

  const checkStatus = (status) => {
    status = status ? status.toLowerCase() : "";
    if (status === 'new') {
     return "invite"
   } else if (status === 'scheduled') {
      return "discontinued"
    } else if (status === 'done'){
      return "success"
    } else{
      return "discontinued"
    }
 }

 const appInitials = (status) => {
  status = status ? status.toLowerCase() : "";
  if (status === 'deenkiosk') {
   return "DK"
 } else if (status === 'deenscreen') {
    return "DS"
  } else if (status === 'deenfund'){
    return "DF"
  } else if (status === 'deenweb'){
    return "DW"
  } else if (status === 'deenconnect'){
    return "DC"
  } else{
    return "DC"
  }
}

  // ============================== Table Start ======================================//
  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };
  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };
  let col = [
    { type: "number", name: "serial_number", label: "NO.", width: "auto" },
    { type: "link", name: "invoice_number", label: "Name", width: "22%" },
    { type: "string", name: "name", label: "Email", width: "22%" },
    { type: "string", name: "name", label: "Demo Apps", width: "22%" },
    { type: "string", name: "name", label: "Date", width: "22%" },
    { type: "action", name: "action", label: "Status", width: "20%" },
    { type: "action", name: "action", label: "ACTION", width: "20%" },
  ];

  let rows = [];
  if(allRequests && allRequests.length){
    rows = allRequests.map((request, index) => {
      let userFullName = request?.name?.firstName || request?.name?.lastName ? request.name.firstName + " " + request.name.lastName : '';
      return (
        [
          { type: "id", id: request._id ? request._id : '',  value: index+1 },
          { type: "string", name: "frequency", value: userFullName },
          { type: "string", name: "frequency", value: request?.email ? request.email : '' },
          { type: "string", name: "frequency", value: request?.applications?.length ? request.applications.map((app, i) => appInitials(app)).join(", " ) : null},
          { type: "string", name: "date/time", value: moment(request.createdAt).format("MMM DD, yyyy, hh:mm A") },
          { type: 'status', status: checkStatus(request.status), value: request.status },
          { type: "action", status: "success", value: "View Details" },
        ]
      )
    });
  }
  // ============================== Table End ======================================//


  const click = (msg) => {
    console.log(msg);
  };

  const btnClick = () => {
    console.log('invite');
  }

  const actionLinkHandler = (data) => {
    let id = data[0].id;
    props.history.push(`/request-details-${id}`)
  }

  const style = {
    width:'100%',
  };


  return (
    <MasterLayoutMiddle

    content={			
    <div> 
      {props.page && props.page.loading && <Spinner />}
    <SimpleTable
        showHeader={true}
        title="Demo Requests"
        width="100%"
        rows={rows}
        columns={col}
        colSpan={16}
        actionSendButton={true}
        classProps={changeClassProps()}
        actionLink={(data) => actionLinkHandler(data)}
        donationsCount={1}
        noTitle="No Service found"
        noPara="Add new AnnouncementsAnnouncements."
        noBtnText="Add Announcements"
        // onInviteClick={() => this.props.history.push( "/request-details" )}
        // actionLink={(name) => this.details(name)}
        showIconCol={false}
        actionSendButton={true}
        // deleteAction={(e) => this.deleteServiceAction(e)}
        showFooter={true}
        showAdd={false}
        addText="ADD NEW"
        // onAddClick={() =>
        //     this.props.history.push(
        //     "/Website/Add-Announcements"
        //     )
        // }
        iconTextSize={16}
        />                   
    </div>
    }
  
    
    isFooter={true}
    footer={
    <FooterInner style={style}/>
    }
    
    isWhiteBackground={false}
    />
  
  )
}


const mapStateToProps = state => {
  return {
    allRequests: state.contacts.allRequests,
    page: state.page,
    errors: state.errors
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAllRequests: () => dispatch(actions.getAllRequests()),
    onClearError: () => dispatch(actions.clearErrors())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Comunication);



