import React, { Component } from 'react';
import { BaseButton, BaseTextField, BaseSelect, BaseFileSelect, BaseCheckBox, TextEditor } from "@dclab/dc-react-ui-kit";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import classes from './varifyEin.module.css';
import { Link } from 'react-router-dom';

class Index extends Component {
  constructor(props){
    super(props);
    this.state = {
      checked: true,
      details: [{ type: 'paragraph', children: [{ text: "" }] }],
      taxId: "",
      urlLink: '',
      status: '',

      object: [
        {
          value: "Approved",
          label: "Approved",
        },
        {
          value: "Resubmitted",
          label: "Resubmitted",
        },
        {
          value: "Submitted",
          label: "Submitted",
        },
      ],
    };
  }

  componentDidMount(){
    console.log(`Your ${this.state.checked} function here`);
  }

  callback = (selectedFiles) => {
    console.log(selectedFiles);
  }

  handleChange = (prop) => (event) => {
    this.setState({
      [prop]: event.target.value
    });
  }

  
  onChange = (e) => {
    this.setState({
      details: e
    })
  };

  save = data => {
    console.log(data);
  };

  styles = {
    txt:{
      color:'#4D4D4D',
      letterSpacing: '1.26px',
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '15px'
    },
    backBtn: {
      backgroundColor: 'white', 
      borderRadius: '18px', 
      color:'#4D4D4D !important',
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '15px',
      width: '86px',
      height: '36px'
    },
    routes: {
      marginLeft: '70px',
      width: '90%',
      padding: '30px 0',
      display: 'flex'
    },
    input:{
      height: '48px',
      marginTop: '20px',
      width: '100%',
      border:"#707070",
    },
    inviteBtn:{
      textAlign: 'center',
      color:'white',
      letterSpacing: '1.26px',
      height: '45px',
      background: '#2490F9 0% 0% no-repeat padding-box',
      borderRadius: '23px',
      padding: '14px 45px'
    },
    cancelBtn:{
      textAlign: 'center',
      color:'black',
      letterSpacing: '1.26px',
      height: '45px',
      borderRadius: '23px',
      backgroundColor: 'white',
      padding: '14px 45px'
    }
  };


  render() {
    const { checked, taxId, urlLink, status, object } = this.state;
    const styles = this.styles;
    return (
    <React.Fragment>
     
      <div style={styles.routes}>
        <div>
          <Link to="/organization-detail" style={{textDecoration: 'none'}} >
            <BaseButton 
              onClick={this.click} 
              text="back" 
              textStyle={styles.txt}
              disableElevation={false}
              disableFocusRipple={false}
              disableRipple={false}
              style={styles.backBtn}
              startIcon={<ChevronLeftIcon />}
            />
          </Link>
        </div>
        <div className={classes.card}>
          <div className={classes.headingDiv}>
            <h1 className={classes.headingInvite}>Verify Tax Exemption</h1>
          </div>
          <div style={{padding: '30px 0px',borderBottom: '1px solid #E8E9E9'}}>
            <BaseCheckBox
              label="We are 501(c)(3) tax exempted not-for-profit organization."
              checked={checked}
              color="secondary"
              showHelperText={true}
              helperText="This will be displayed to potential visitors/donors as VERIFIED - 501(c)(3)"
              onChange={this.handleChange("checked")}
            />
          </div>
          <BaseTextField
            value={taxId}
            onChange={this.handleChange("taxId")}
            label='EIN/Tax ID Number'
            placeholder="EIN/Tax ID Number"
            InputProps={false}
            phone={false}
            search={false}
            count={100}
            style={styles.input}
            color="green"
            //type="search"
          />
          <BaseTextField
            value={urlLink}
            onChange={this.handleChange("urlLink")}
            label='Add a link/url'
            placeholder="Add a link/url"
            InputProps={false}
            phone={false}
            search={false}
            count={100}
            style={styles.input}
            color="green"
            //type="search"
          />
          <div className={classes.fileSelector}>
            <BaseFileSelect selectedFiles={this.callback}/>
          </div>

          <BaseSelect
            value={status}
            onChange={this.handleChange("status")}
            label="Status"
            placeholder="Status"
            object={object}
            autoWidth={true}
            style={styles.input}
            //helperText="helper text"
          />
        <div style={{marginTop: '40px'}}>
            <TextEditor label={"Write a message to the Org Admin"} 
              maxCharCount={100} 
              //helperText={"Helper Text"}
              onChange={(e) => this.onChange(e)}
              value={this.state.details} 
              />
          </div>
          <div className={classes.bottonDiv}>
            <BaseButton 
              onClick={this.click} 
              text="cancel" 
              textStyle={styles.txt}
              disableElevation={false}
              disableFocusRipple={false}
              disableRipple={false}
              style={styles.cancelBtn}
              variant='text'
            />
            <BaseButton 
              onClick={() => console.log("this is click")}
              text={'Save'}
              disableElevation={false}
              disableFocusRipple={false}
              disableRipple={false}
              style={styles.inviteBtn}
              
            />
          </div>        
        </div>            
      </div>
    </React.Fragment>
    )
  }
}


export default Index;


