import React, {useEffect, useState} from 'react';
import { SimpleTable, MasterLayoutMiddle, FooterInner, CardDetailsTable, EventLogs, AlertDialogue } from "@dclab/dc-react-ui-kit";
import { Link } from "react-router-dom";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Grid from '@material-ui/core/Grid';
import Check from "./check_circle-24px.svg";
import { connect } from 'react-redux';
import * as actions from '../../store/Actions/index';
import "./style.css"
import moment from 'moment';
import { serialize2 } from "../ContactUs/markdowntoreact";
import Spinner from '../../components/common/Spinner';


function RequestDetails(props) {
  const {requestDetails} = props;
  const [openAlert, setOpenAlert] = useState(false);
  console.log("requestDetail", requestDetails);

  useEffect(() => {
    if(props.match.params.id) {
      props.onGetRequest(props.match.params.id);
    }
  }, [])

  const checkStatus = (status) => {
    status = status ? status.toLowerCase() : "";
    if (status === 'new') {
     return "#7266FB"
   } else if (status === 'scheduled') {
      return "#EEB000"
    } else if (status === 'done'){
      return "#3CC480"
    } else{
      return "#3CC480"
    }
 }

  // ==============================Card Detail Table Start ======================================//
  let header = requestDetails?.applications?.length > 0 && requestDetails?.schedule?.date && requestDetails?.schedule?.time &&  requestDetails?.queries?.length > 0 ? {
    tableName: `Details`,
  } : {
    tableName: `Details`,
    chip: 'PROSPECT'
  }

  let data = [

    {
      field: "Date",
      value: requestDetails?.createdAt ? moment(requestDetails.createdAt).format('MMM DD, YYYY, hh:mm A') : ""
    },

    {
      field: "Demo Apps",
      value: requestDetails?.applications?.length ? requestDetails.applications.map((app, i) => app).join(", ") : "<span style = 'color: gray'>No data</span>"
    },

    {
      field: "Name",
      value: requestDetails?.name?.firstName ? requestDetails.name.firstName +  " " + requestDetails.name.lastName : ""
    },

    {
      field: "App stream",
      value: requestDetails?.stream ? requestDetails.stream === "DCWEBSITE" ? "DC WEBSITE" : requestDetails.stream : ""
    },
    {
      field: "Demo date",
      value: requestDetails?.schedule?.date && requestDetails?.schedule?.time ? requestDetails.schedule.date +  ", " + requestDetails.schedule.time : "<span style = 'color: gray'>No data</span>"
    },

    {
      field: "Email",
      value: requestDetails?.email ? requestDetails.email : ""
    },
    
    {
      field: "Location",
      value: "City, NY 11201"
    },
    {
      field: " ",
      value: " "
    },

    {
      field: "Phone number",
      value: requestDetails?.phoneNo ? requestDetails.phoneNo : ""
    },
    {
      field: " ",
      value: " "
    },
    {
      field: " ",
      value: " "
    },
    {
      field: "Org name",
      value: requestDetails?.organizationName ? requestDetails.organizationName : ""
    },

  ];

  let footerLinkName = "ASSIGN";
  let footerDeleteButton = "Delete";

  // HANDLE FOOTER LINK
  const onClick = () => {
    props.history.push('request-respond-' + props.match.params.id)
  }

  const onDeleteClick = () => {
    console.log('delete')
    setOpenAlert(true);
  }


  const handleConfirmDelete = () => {
    setOpenAlert(false)
    props.onDeleteRequest(requestDetails._id);
  }
  // ==============================Card Detail Table END ======================================//

  // ============================== Table Start ======================================//
  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };
  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };
  let col = [
    { type: "number", name: "serial_number", label: "NO.", width: "auto" },
    { type: "link", name: "lebel", label: "QUESTION", width: "60%" },
    { type: "string", name: "name", label: "Answer", width: "71%" },

  ];

  let rows = [];
  if(requestDetails?.queries?.length){
    rows = requestDetails.queries.map((query, index) => {
      return (
        [
          { type: "id", id: query._id ? query._id : '',  value: index+1 },
          { type: "string", name: "name", value: query?.question ? query.question : '' },
          { type: "string", name: "frequency", value: query?.answer ? query.answer : ''},
        ]
      )
    });
  }
  // ============================== Table End ======================================//

  // ============================== Timeline Start ======================================//
  let details = requestDetails?.responseTimeline?.length ? requestDetails.responseTimeline.map((log, i) => {
    let x = log?.note && log?.note?.includes('"type":') && JSON.parse(log?.note);
    let editor;
    if(x) {
      editor = { children: [ ...x ] }
    }

    const description = log?.note && log?.note?.includes('"type":') ? serialize2(editor) : log?.note;
    return {
      id: i + 1, icon: Check, status: log.status, date: log.createdAt ? moment(log.createdAt).format('lll') : "",  details: [
        { title: 'Reviewed by', message: log?.reviewedBy ? log.reviewedBy : '' },
        { title: 'Assignee', message: log?.assignee ? log.assignee : '' },
        { title: 'Note', message: description.replace(/<p>/g, '').replace(/<\/p>/g, '')}
      ]
    }
}) : null;

  details = details && details.length ? details.reverse() : [];

  // ============================== Timeline End ======================================//

  const style = {
    width: '100%',
  };

  return (
    <MasterLayoutMiddle

      header={
        <div>
          <div className="backBtn">
            <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
            <Link
              to={{ pathname: "/communication", state: 1, }}
              style={{ alignSelf: "center", color: "black", textDecoration: "none", }} > Back </Link>{" "}
          </div>
        </div>
      }

      content={
        <div className='RequestTableContainer'>
      {props.page && props.page.loading && <Spinner />}
      <AlertDialogue
            title="Delete Demo Request?"
            description="Are you sure you want to delete this demo request?"
            description2=" Deleting will permanently remove from everywhere."
            cancelText="Cancel"
            confirmText="Confirm"  
            open={openAlert}
            handleCancel={() => setOpenAlert(false)}
            handleClose={() => setOpenAlert(false)}
            handleConfirm={() => handleConfirmDelete()}
            styles={{
              width:470,
              marginLeft:800
            }}
          />
          <Grid container spacing={3}>

            <Grid item xs={12}>
              <CardDetailsTable
                data={data}
                header={header}
                footerLinkName={footerLinkName}
                onClick={() => onClick()}
                footerDeleteButton={footerDeleteButton}
                onDeleteClick= {onDeleteClick}
                Status={true}
                StatusLabel="Status"
                StatusText={requestDetails.status ?? ''}
                statuscolor={checkStatus(requestDetails.status)}


              />
            </Grid>

            {rows.length > 0 ? <Grid item xs={12}>
              <SimpleTable
                showHeader={true}
                title="Querries"
                width="100%"
                rows={rows}
                columns={col}
                colSpan={16}
                actionSendButton={true}
                classProps={changeClassProps()}
                donationsCount={1}
                noTitle="No Service found"
                noPara="Add new AnnouncementsAnnouncements."
                noBtnText="Add Announcements"
                actionLink={(name) => this.details(name)}
                showIconCol={false}
                actionSendButton={true}
                deleteAction={(e) => this.deleteServiceAction(e)}
                showFooter={false}
                showAdd={false}
                addText="ADD NEW"
                iconTextSize={16}
              />
            </Grid> : null}

            <Grid item xs={12}>
            {details && details.length ? <EventLogs data={details}
                headTitle="Timeline"
                schedule={true}
              /> : null }
            </Grid>

          </Grid>
        </div>
      }
      isFooter={true}
      footer={
        <FooterInner style={style} />
      }
      isWhiteBackground={false}
    />

  )
}

const mapStateToProps = state => {
  return {
    requestDetails: state.contacts.requestDetails,
    page: state.page,
    errors: state.errors
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onGetRequest: (id) => dispatch(actions.getRequest(id)),
    onDeleteRequest: (id) => dispatch(actions.deleteRequest(id, ownProps)),
    onClearError: () => dispatch(actions.clearErrors())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestDetails);


