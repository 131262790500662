import { 
	SET_ALL_CAMPAIGN,
	
} from '../Actions/actionTypes';

const initialState = {
	allCampaign: [],
	
};

export default function(state = initialState, action) {
	switch (action.type) {
		case SET_ALL_CAMPAIGN:
			return { ...state, allCampaign: action.payload }
			
		default:
			return state;
	}
}