import React, { Component } from 'react';
import { BaseTabs, MasterLayoutMiddle, FooterInner, } from "@dclab/dc-react-ui-kit";
import FinanceTeamMembers from './FinanceTeamMembers';

import Navbar from '../../components/NavDawer/NavDrawer';
import Alert from '../../components/common/AlertAction';
import { connect } from 'react-redux';
import * as actions from '../../store/Actions/index';
import Spinner from '../../components/common/Spinner';
import Grid from '@material-ui/core/Grid';


class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      errors: {},
      loading: false,
      teamMembers: [],

    };
  }


  static getDerivedStateFromProps(props, state) {
    let errors = props.errors;
    let page = props.page;
    let teamMembers = props.teamMembers ? props.teamMembers : {};

    let stateChanged = false;
    let changedState = {};

    if (teamMembers && JSON.stringify(state.teamMembers) !== JSON.stringify(teamMembers)) {
      changedState.teamMembers = teamMembers;
      stateChanged = true;
    }

    if (errors && JSON.stringify(state.errors) !== JSON.stringify(errors)) {
      changedState.errors = errors;
      stateChanged = true;
    }

    if (page && JSON.stringify(state.loading) !== JSON.stringify(page.loading)) {
      changedState.loading = page.loading;
      stateChanged = true;
    }

    if (stateChanged) {
      return changedState;
    }


    return null;
  }

  styles = {
    routes: {
      marginLeft: '70px',
      width: '90%',
      padding: '30px 0',
    }

  };

  showAlertHandler = () => {
    this.props.onClearError();
  }

  componentDidMount() {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    this.props.onGetTeamMembers()
  }

  onDeleteMember = (data) => {
    console.log("delete Id: ", data);
    this.props.onDeleteMember(data[0].id);
  }

  render() {

    let { errors, loading, show, teamMembers } = this.state;
    let pageContent = '';
    let message = 'Invite sent successfully! ';
    let type = 'success';
    let styles = this.styles;

  

    if (loading) {
      pageContent = <Spinner />

    }
    if (errors && errors.message) {
      message = errors.message;
      type = 'error';
      show = true;
    }

    const style = {
      width:'100%',
    };

    return (


      <MasterLayoutMiddle

                header={          
                  <div>
                    <Alert message={message} type={type} click={this.showAlertHandler} show={show} />
                    <div style={{ display: 'none' }}>
                    <Navbar orgDetail={true} />
                    </div>  
                  </div>
                  }
                  content={
		              <div>

                  <Grid container spacing={3}>	      

                    <Grid item xs={12}> 
                      <FinanceTeamMembers teamMembers={teamMembers} deleteAction={this.onDeleteMember} id={this.state.id} history={this.props.history} />
                    </Grid>

                  </Grid>

                  
                 </div>
                }
        
                isFooter={true}
                footer={
                  <FooterInner style={style}/>
                }

                isWhiteBackground={false}

            />
      
    )
  }
}


const mapStateToProps = state => {
  return {
    teamMembers: state.teamMembers.financeTeamMembers,
    page: state.page,
    errors: state.errors
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onGetTeamMembers: () => dispatch(actions.getAllFinanceTeamMembers()),
    onDeleteMember: (id, orgId) => dispatch(actions.deleteTeamMember(id, orgId)),
    onClearError: () => dispatch(actions.clearErrors())
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(Index);













