import React from 'react';
import { SimpleTable, OrgEventLogs  } from "@dclab/dc-react-ui-kit";
import Pic1 from "../../assets/images/Ellipse_1.png";
import CreateIcon from "../../assets/img/create-24px.svg";
import DeleteIcon from "../../assets/img/delete_forever-24px.svg";
import moment from 'moment'

export default function TabContent2(props) {
  const organization = props.organization;

  let logsData = [];

  console.log("org logs: ", organization)
  
  if(organization?.registration?.agreement?.logs?.length){ 
    logsData = organization.registration.agreement.logs.map((org, index) => {
        return { id: org._id , 
                  user: org.user, action: org.logType === "agreement" ? " agreed to local agreement" : " agreed to terms and conditions", date: moment(org.createdAt).format('LLL'), ipAddress: org.ipAddress ,
                  details:[
                  {title:'User', message: org.user},
                  {title:'IP Address', message: org.ipAddress},
                  {title:'Browser', message: org.browser},
                  {title:'Operating System', message: org.operatingSystem},
                  {title:'Device', message: org.device},
                  {title:'Log', message: org.log},
              ]
          }
      });
  }

  return <OrgEventLogs logsData={logsData}
  headTitle="Agreements"
  schedule={true}
  />





  // const id = props.id;
  // const deleteAction = props.deleteAction;

  // let columns = [
  //   { type: "string", name: "name", label: "ACTION", minWidth: 500 },
  //   { type: "string", name: "name", label: "IP ADDRESS", minWidth: 120 },
  //   { type: "date", name: "date", label: "DATE", minWidth: 120 },
  //   { type: 'string', name: 'date', label: '', minWidth: 70 },
  //   { type: "action", name: "actionIcons", label: "ACTION", minWidth: 90 },

  // ];
  
  // let classProps = () => {
  //   const tempArray = [];
  //   columns.forEach((column, index) => {
  //     tempArray.push(`class${index}`);
  //   });
  //   return tempArray;
  // };

  // const changeClassProps = () => {
  //   let tempArray = classProps();
  //   tempArray[0] = "class15";
  //   return tempArray;
  // };
  
  // const columns1 = (columns) =>
  //   columns.map((obj) => {
  //     // delete obj.id;
  //     // delete obj.name;
  //     return obj;
  //   });

  //   const subMenuColumns = [
  //     { id: 'id', label: '', minWidth: '0px' },
  //     { type: 'string', name: 'name', label: 'User', width: '50%' },
  //     { type: 'string', name: 'name', label: 'IP Address', width: '50%' },
  //     // { type: 'string', name: 'name', label: 'Browser', width: '50%' },
  //     // { type: 'string', name: 'name', label: 'Operationg System', width: '50%' },
  //     // { type: 'string', name: 'name', label: 'Device', width: '50%' },
  //     // { type: 'string', name: 'name', label: 'Log', width: '50%' },
  //   ];
    

  //   let rows = [];
  //   if(organization && organization.logs && organization.logs.length){
  //     rows = organization.logs.map((member, index) => {
  //       return (
  //         [
  //           { type: "string", name: "name", value: member.user && (member.logType).toLowerCase() === 'agreement' ? member.user + ' agreed to local agreement' : member.user + ' agreed to terms and conditions'  },
  //           { type: "string", name: "name", value: member.ipAddress ? member.ipAddress : '' },
  //           { type: "date", name: "date", value: member.createdAt && moment(member.createdAt).format('LLL') },
  //           { type: 'string', name: 'collapse', value: 'Show' },
  //           {
  //             type: 'additionalData',
  //             columns: subMenuColumns,
  //             value: [[
  //                 { type: 'string', name: 'link', value: member.user },
  //                 { type: 'string', name: 'menu', value: member.ipAddress },
  //               ]]
  //           },
  //         ]
  //       )
  //     });
  //   }
  

  // const click = (msg) => {
  //   console.log(msg);
  // };
  
  // const btnClick=() => {
  //   console.log('invite')
  // }
  // // console.log(rowFinder(rows));
  // // console.log(value);
  
  // // <SimpleTable rows={rowFinder(rows)} columns={columns1(columns)} colSpan={6} />;
  
  // const editAction = () => {
  //   console.log('edit')
  // }

  // const actionLink = (name) => {
  //   // props.history.push(`/add-team-member-${props.id}`)
  // }
  
  
  // return (
  //   <SimpleTable
  //   title="Agreements"
  //   showHeader={true}
  //   addText=""
  //   rows={rows}
  //   columns={columns1(columns)}
  //   colSpan={6}
  //   actionSendButton={false}
  //   classProps={changeClassProps()}
  //   onClick={(message, index) => click(message)}
  //   // showFilter={true}
  //   // showFilterText={true}
  //   // showAdd={true}
  //   donationsCount={10}
  //   noTitle="No recent donations"
  //   noPara="Add donation categories so that people can donate for a specific cause."
  //   noBtnText="Invite Donors"
  //   onInviteClick={() => btnClick()}
  //   editIcon={CreateIcon}
  //   deleteIcon={DeleteIcon}
  //   showIconCol={true}
  //   // onAddClick={() => props.history.push(`/add-team-member-${id}`)}
  //   editAction={() => editAction()}
  //   // deleteAction = { (data) => deleteAction(data)}
  //   showFooter={true}
  //   actionLink={(name) => actionLink(name)}
  //   iconTextSize={14}
  // />
  // );
}





