import React, { useState, Fragment, useRef } from 'react'
import { BaseButton, MasterLayoutMiddle, FooterInner } from "@dclab/dc-react-ui-kit";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { connect } from 'react-redux';
import * as actions from '../../store/Actions/index';
import Spinner from '../../components/common/Spinner';
import "./products.css"
import {Grid, Container, Button} from '@material-ui/core';  
import { BaseStepperExtended } from "@dclab/dc-react-ui-kit";
import BasicInfo from './IntegrationSteps/basicInfo';
import Features from './IntegrationSteps/features';
import Pricings from './IntegrationSteps/pricings';
import AppStatus from './IntegrationSteps/status';
import { makeStyles } from '@material-ui/core/styles';

function AddIntegration(props) {
const [activeStep, setActiveStep] = React.useState(0);
const [stepDisabled, setstepDisabled] = useState(true)

const childRef = useRef();
const childRef2 = useRef();
const childRef3 = useRef();
const childRef4 = useRef();

const getSteps = () => {
  return [
    { number: 1, label: "Basic Info", value: <BasicInfo ref={childRef} integrationInfo={props.integrationInfo} onAddIntegrationInfo={props.onAddIntegrationInfo} setstepDisabled={setstepDisabled} applications={props.applications} /> },
    { number: 2, label: "Features", value: <Features ref={childRef2} integrationInfo={props.integrationInfo} onAddIntegrationInfo={props.onAddIntegrationInfo} setstepDisabled={setstepDisabled} /> },
    { number: 3, label: "Pricing", value: <Pricings ref={childRef3} integrationInfo={props.integrationInfo} onAddIntegrationInfo={props.onAddIntegrationInfo} setstepDisabled={setstepDisabled} history={props.history} /> 
    },
    { number: 4, label: "Status", value: <AppStatus ref={childRef4} integrationInfo={props.integrationInfo} onAddIntegrationInfo={props.onAddIntegrationInfo} setstepDisabled={setstepDisabled} /> },
  ];
};
const handleNext = () => {
    if(activeStep === 0) {
        childRef.current.onSubmit()
    } else if (activeStep === 1) {
        childRef2.current.onSubmit()
    } else if (activeStep === 2) {
        childRef3.current.onSubmit()
    } else if (activeStep === 3) {
        childRef4.current.onSubmit()
        props.onAddIntegration()
    }

    if(activeStep < 3) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
};

const handleBack = () => {
    if(activeStep > 0) {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    } else {
        props.history.push({pathname: "/admin-products", state: {step: "integration"}});
    }
};


const styles2 = {
  stepper: {
    marginTop: 150,
    padding: "8px 20px",
    borderRadius: "30px",
  },
};

let styles = {
    txt: {
        color: '#4D4D4D ',
        letterSpacing: '1.26px',
        textAlign: 'left',
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontSize: '15px'
    },
    chipInput: {
        width: "100%"
    },
    backBtn: {
        backgroundColor: 'white',
        borderRadius: '18px',
        color: '#4D4D4D',
        textAlign: 'left',
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '15px',
        width: '86px',
        height: '36px'
    },
    routes: {
        marginLeft: '70px',
        width: '90%',
        padding: '30px 0',
        display: 'flex'
    },
    input: {
        height: '48px',
        marginTop: '20px',
        width: '100%',
        border: "#707070",
    },
    input2: {
        height: '48px',
        marginTop: '20px',
        marginRight: '22px',
        width: '48%',
        float: 'left',
        border: "#707070",
    },
    input3: {
        height: '48px',
        marginTop: '20px',
        width: '48%',
        border: "#707070",
    },
    inviteBtn: {
        textAlign: 'center',
        color: 'white',
        letterSpacing: '1.26px',
        height: '45px',
        background: '#2490F9 0% 0% no-repeat padding-box',
        borderRadius: '23px',
        padding: '14px 45px'
    },
    disableBtn: {
        textAlign: 'center',
        color: '#222',
        letterSpacing: '1.26px',
        height: '45px',
        background: '#eee',
        borderRadius: '23px',
        padding: '14px 45px'
    },
    cancelBtn: {
        textAlign: 'center',
        color: 'black',
        letterSpacing: '1.26px',
        height: '45px',
        borderRadius: '23px',
        backgroundColor: 'white',
        padding: '14px 45px'
    }
}

const style = {
    width:'100%',
};

const useStyles = makeStyles((theme) => ({

    SteperContainer: {

        "& .stepper_container .MuiStepper-horizontal": {
            width:"80% !important",
            maxWidth:"990px",
        },

    },

 }));

 const classes = useStyles();

    return (
        <Fragment>
            <MasterLayoutMiddle
                header={
                    <div>
                    <div className="backBtnFrom">
                    <BaseButton
                        onClick={() => props.history.push({pathname: "/admin-products", state: {step: "integration"}})}
                        text="back"
                        textStyle={styles.txt}
                        disableElevation={false}
                        disableFocusRipple={false}
                        disableRipple={false}
                        style={styles.backBtn}
                        startIcon={<ChevronLeftIcon />}
                    />
                    </div>
                    {props.page && props.page.loading && <Spinner />}
                    
                </div>
                }
                
                content ={
                    <div className={classes.SteperContainer}>
                    <BaseStepperExtended
                        title="Add Integration"
                        getSteps={getSteps}
                        alternativeLabel={true}
                        handleBack={() => handleBack()}
                        handleNext={() => handleNext()}
                        nonLinear={true}
                        bottomLine={true}
                        backButton={false}
                        backgroundColor={'transparent'}
                        orientation={"horizontal"}
                        btnStyles={styles2.stepper}
                        activeStep={activeStep}
                    />
                    </div>
                }
                isFooter={true}
                footer={
                <FooterInner style={style}/>
                }
                isWhiteBackground={true}
                />

            <div className="sticky-proceed-footer-wrapper stickyfooter_border">
                <div className="sticky-proceed-footer">
                    
                            <div className="cancel-next-btn">
                                <Button 
                                    // disabled={activeStep === 0} 
                                    onClick={handleBack} 
                                >
                                    Back
                                </Button>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={stepDisabled}
                                    onClick={handleNext}
                                    className={`proceed-btn ${stepDisabled ? "disabledStepper" : ""}`}>
                                    {activeStep === 3 ? 'ADD INTEGRATION' : 'CONTINUE'}
                                </Button>
                            </div>
                       
                </div>
                </div>
        </Fragment>
    )
}

const mapStateToProps = state => {
    return {
          page: state.page,
          errors: state.errors,
          integrationInfo: state.products.integrationInfo,
          applications: state.products.applications,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onAddIntegrationInfo: (integration) => dispatch(actions.addIntegrationInfo(integration)),
        onAddIntegration: () => dispatch(actions.addIntegration(ownProps)),
        onGetApplications: () => dispatch(actions.getAllApplications()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddIntegration);
