import React from 'react';
import check_circle from '../../assets/img/check_circle.svg';
import { Link, withRouter } from 'react-router-dom';
import { CardDetailsTable, CardStatus } from "@dclab/dc-react-ui-kit";

function tabContent1(props) {
    let organization = props.organization;

    let orgStatus = "";

    if(organization && organization.orgStatus && (organization.orgStatus).toLowerCase() === "onboarding") {
        orgStatus = <CardStatus styleClassName="warning" text="Onboarding" style={{paddingTop: "0px"}} />
    } else if (organization && organization.orgStatus &&  (organization.orgStatus).toLowerCase() === "approved") {
        orgStatus = <CardStatus styleClassName="success" text="Approved" style={{paddingTop: "0px"}} />
    } else if (organization && organization.orgStatus &&  (organization.orgStatus).toLowerCase() === "blocked") {
        orgStatus = <CardStatus styleClassName="danger" text="Blocked" style={{paddingTop: "0px"}} />
    } else {
        orgStatus = <CardStatus styleClassName="warning" text="Onboarding" style={{paddingTop: "0px"}} />
    }
        
    let header = {
        avatar: "",
        tableName: organization && organization.orgName ? organization.orgName : "",
        icon: "",
        status: [orgStatus]
    };

    let data = [
        {
            field: "Org Type",
            value:  organization && organization.orgType ? organization.orgType : ""
        },
        {
            field: "Phone",
            value:  organization?.phoneFax?.primaryPhone ? `<span><div>${organization.phoneFax.primaryPhone}</div><div>${organization?.phoneFax?.secondaryPhone ? organization.phoneFax.secondaryPhone : ""}</div></span>` : '<span className="ondisableText">No phone</span>'
        },
        {
            field: "Address",
            value: organization?.orgAddress ? organization?.orgAddress?.line1 + " " + organization?.orgAddress?.city +  " " + organization?.orgAddress?.state +  " " + organization?.orgAddress?.postalCode : '<span className="ondisableText">No address</span>'
        },
        {
            field: "Shortname",
            value: organization && organization.orgShortName ? organization.orgShortName : ""
        },
        {
            field: "Org Email",
            value: organization && organization.orgEmail ? organization.orgEmail : '<span className="ondisableText">No email</span>'
        },
        {
            field: "Website",
            value: organization && organization.orgWebsite ? organization.orgWebsite : '<span className="ondisableText">No website</span>'
        },

        {
            field: "Icon",
            value: organization?.orgIcon?.iconName && organization?.orgIcon?.iconName !== "false" ? `<span>Added <img src="${check_circle}" class="verified-icon" /> </span>` :'<span className="ondisableText">No icon</span>',
        },

        {
            field: "Tax Exempted",
            value: organization && organization.taxIdStatus && organization.taxIdStatus.taxExempt ? `<span>Verified <img src="${check_circle}" class="verified-icon" /></span>` : '<span className="ondisableText">Not Yet Submitted</span>'
        },

        
        
        {
            field: "Reffered By",
            value: '<span className="ondisableText">No data</span>'
        },
        
        {
            field: "Logo",
            value: organization?.orgLogo?.logoName && organization?.orgLogo?.logoName !== "false" ? `<span>Added <img src="${check_circle}" class="verified-icon" /> </span>` : '<span className="ondisableText">No logo</span>'
        },

        
        
    ];
    
	const onClick = () => {
		props.history.push(`/edit-organization-detail-${organization && organization._id && organization._id}`)
    }	

    let footerLinkName = "Edit Organization";

    return ( 
		<CardDetailsTable data={data} header={header} footerLinkName={footerLinkName} onClick={() => onClick()}/>		
	)
}

export default withRouter(tabContent1)