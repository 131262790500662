import {
	PAGE_LOADING,
	PAGE_LOADED,SHOW_MESSAGE
} from '../Actions/actionTypes';

const initialState = {
	loading: false,
	showMessage : ""
};

export default function (state = initialState, action) {
	
	switch (action.type) {
		case PAGE_LOADING:
			return {
				...state,
				loading: true
			}
		case PAGE_LOADED:
			return {
				...state,
				loading: false
			}
		case SHOW_MESSAGE:
			return {...state, showMessage: action.payload}
		default:
			return state;
	}
}