import React, { useState, Fragment, useEffect, forwardRef, useImperativeHandle  } from 'react'
import { DrawerMain, BaseTextField, AlertAction, TextEditor, BaseImgChip, BaseFileSelect, DrawerListCheckbox, BaseSelect } from "@dclab/dc-react-ui-kit";
import "../products.css"
import {Grid, TextField} from '@material-ui/core';  
import MediaUpload from '../../../components/common/MediaUpload/MediaUpload';

const BasicInfo = forwardRef((props, ref) => {
    const {integrationInfo, setstepDisabled} = props;
    const [open, setOpen] = React.useState(false);
    const [logoDrawer, setlogoDrawer] = useState(true)
    const [uploadedImage, setuploadedImage] = useState(null)
    const [integration, setintegration] = useState({ name: "", stripeProductId: "", shortDescription: "", image: "", compatibleWith: [], integrationApi: ""})

    const [longDescription, setlongDescription] = useState([{ type: 'paragraph', children: [{ text: '' }] }]);
    const [chipData, setchipData] = useState([])
    const [chipData2, setchipData2] = useState([])
    const [selectedData, setselectedData] = useState([])
    const [appsData, setappsData] = useState([])
    const [logoError, setlogoError] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [])

    useEffect(() => {
       setintegration({ 
           name: integrationInfo?.name ?? "", 
           stripeProductId: integrationInfo?.stripeProductId ?? "",
           shortDescription: integrationInfo?.shortDescription ?? "", 
           image: integrationInfo?.imageMetaData?.imageUrl ?? "",
           compatibleWith: integrationInfo?.compatibleWith ? integrationInfo.compatibleWith : [],
           integrationApi: integrationInfo?.integrationApi
        })
        integrationInfo?.imageMetaData?.imageUrl && setchipData([{ key: 0, label: integrationInfo?.imageMetaData?.imageName, src: integrationInfo?.imageMetaData?.imageUrl, alt: integrationInfo?.imageMetaData?.imageName }])
        integrationInfo?.longDescription && setlongDescription(JSON.parse(integrationInfo?.longDescription))
        if(integrationInfo?.compatibleWith) {
            setchipData2(integrationInfo?.compatibleWith?.length ? integrationInfo?.compatibleWith.map((el) => {
                return { key: el.application, label: el.applicationName, src: "", alt:""  }
            }) : [])
            setselectedData(integrationInfo?.compatibleWith?.length ? integrationInfo?.compatibleWith.map((el) => {
                return { id: el.application, primary: el.applicationName }
            }) : [])
        }
    }, [integrationInfo])

    let handleChange = (prop) => (event) => {
        if(prop === "shortDescription" && event.target?.value?.length > 100) {
            return
        } 
        setintegration({ ...integration, [prop]: event.target.value });
    }

    const onChange = (e) => {
        setlongDescription(e)
    };

    useImperativeHandle(ref, () => ({
        onSubmit() {    
            if (name && shortDescription && image && longDescription) {
                let formData = { 
                    name: integration.name?.trim(), 
                    stripeProductId: integration.stripeProductId ?? "",
                    shortDescription: integration.shortDescription,
                    longDescription: JSON.stringify(longDescription),
                    imageMetaData: {
                        imageUrl: image,
                        imageName: uploadedImage?.title ?? "",
                        imageSize: 0
                    },
                    compatibleWith: integration.compatibleWith,
                    integrationApi: integration.integrationApi
                };
                console.log("submit", formData)
                props.onAddIntegrationInfo(formData)
            }
        }
    }));

    const callback = (selectedFiles) => {
        // console.log(selectedFiles[0])
        if(selectedFiles.src) {
            setuploadedImage(selectedFiles);
            setintegration({ ...integration, image: selectedFiles.src }); 
        }
    };

    const closeDrawer = () => {
        setOpen(!open);
    };
    
    const onCancel = () => {
        setOpen(false);
    };
      
    const onSave = (type) => {
        if(logoDrawer) {
            if(uploadedImage?.src?.length && uploadedImage?.size > 1048576) {
                setlogoError(true)
                setTimeout(() => {
                    setlogoError(false)
                }, 8000);
            } else if(uploadedImage) {
                setchipData([{ key: 0, label: uploadedImage.name, src: uploadedImage.src, alt: uploadedImage.name }])
                setintegration({ ...integration, image: uploadedImage.src })
                setOpen(!open);
                setlogoDrawer(false)
                setlogoError(false)
            }
        } else {
            let chipData = selectedData?.length ? selectedData.map((el, i) => {
                return { key: el.id, label: el.primary, src: "", alt:"" }
            }) : []
            let data = selectedData?.length ? selectedData.map((el, i) => {
                return { application: el.id, applicationName: el.primary }
            }) : []
            setchipData2(chipData)
            setintegration({ ...integration, compatibleWith: data })
            setOpen(!open);
        }

    };

    const onInputClick = () => {
    console.log("input clicked");
    };

    const onChipInputClick = (type) => {
        console.log("input chip clicked");
        if(type === "logo") {
            setlogoDrawer(true)
        } else {
            setlogoDrawer(false)
        }
        setOpen(!open);
    };

    const deleteChip = () => {
        setintegration({ ...integration, image: "" })
        setchipData([])
    }

    const deleteChip2 = (val) => {
        console.log("deleteChip2", val)
        let removedData = integration?.compatibleWith?.filter((el) => el.application !== val.key)
        console.log(removedData)
        setintegration({ ...integration, compatibleWith: removedData })
        setchipData2(removedData?.length ? removedData.map((el) => {
            return { key: el.application, label: el.applicationName, src: "", alt:""  }
        }) : [])
        setselectedData(removedData?.length ? removedData.map((el) => {
            return { id: el.application, primary: el.applicationName }
        }) : [])
    }

    let styles = {
        txt: {
            color: '#4D4D4D ',
            letterSpacing: '1.26px',
            textAlign: 'left',
            fontFamily: 'Roboto',
            fontWeight: 500,
            fontSize: '15px'
        },
        chipInput: {
            width: "100%"
        },
        backBtn: {
            backgroundColor: 'white',
            borderRadius: '18px',
            color: '#4D4D4D',
            textAlign: 'left',
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '15px',
            width: '86px',
            height: '36px'
        },
        routes: {
            marginLeft: '70px',
            width: '90%',
            padding: '30px 0',
            display: 'flex'
        },
        input: {
            height: '48px',
            marginTop: '20px',
            width: '100%',
            border: "#707070",
        },
        input2: {
            height: '48px',
            marginTop: '20px',
            marginRight: '22px',
            width: '48%',
            float: 'left',
            border: "#707070",
        },
        input3: {
            height: '48px',
            marginTop: '20px',
            width: '48%',
            border: "#707070",
        },
        inviteBtn: {
            textAlign: 'center',
            color: 'white',
            letterSpacing: '1.26px',
            height: '45px',
            background: '#2490F9 0% 0% no-repeat padding-box',
            borderRadius: '23px',
            padding: '14px 45px'
        },
        disableBtn: {
            textAlign: 'center',
            color: '#222',
            letterSpacing: '1.26px',
            height: '45px',
            background: '#eee',
            borderRadius: '23px',
            padding: '14px 45px'
        },
        cancelBtn: {
            textAlign: 'center',
            color: 'black',
            letterSpacing: '1.26px',
            height: '45px',
            borderRadius: '23px',
            backgroundColor: 'white',
            padding: '14px 45px'
        },
        select:{
            marginLeft: 0,
            minWidth: 120,
            border: "#D3D8DA",
            width:"100%",
            color: "#707070"
        }
    }
    
    const { name, shortDescription, image, compatibleWith, integrationApi, stripeProductId } = integration;
    let disableBtn = name && shortDescription && image && longDescription && compatibleWith?.length ? false : true; 
    setstepDisabled(disableBtn)

    useEffect(() => {
        let appData = props?.applications?.length ? props.applications.map((el) => {
            return { id: el._id, primary: el?.name?.toUpperCase() ?? "" }
        }) : [];
        setappsData(appData)
    }, [props.applications])



    const getData = (values) => {
        console.log(values);
        setselectedData(values)
    };

    const integrationApiList = [
        {
            value: "contact",
            label: "Messages",
        },
        {
            value: "announcements",
            label: "Announcements",
        },
        {
            value: "communications",
            label: "Communications",
        },
        {
            value: "ramadhan",
            label: "Ramadhan",
        },
        {
            value: "reminders",
            label: "Reminders",
        },
        {
            value: "build-project",
            label: "Build Project",
        },
        {
          value: "salah",
          label: "Salah",
        },
        {
          value: "events",
          label: "Events",
        },
        {
            value: "donation-category",
            label: "Donation - Multiple Categories", 
        },
        {
            value: "ads",
            label: "Ads", 
        },
        {
            value: "planSubscription",
            label: "Pricing/Plans - Websites", 
        },
        {
            value: "orders",
            label: "Orders", 
        },
        {
            value: "subscriptions",
            label: "Subscriptions", 
        },
    ];
      

    return (
        <Fragment>
            <DrawerMain
                open={open}
                openHandler={closeDrawer}
                title={logoDrawer ? "Upload Logo" : "Compatible With Applications"}
                onCancel={onCancel}
                onSave={onSave}
                loading={false}
                saveDisabled={false}
            >
                {logoDrawer ? <BaseFileSelect
                    selectedFiles={callback}
                /> : <DrawerListCheckbox objects={appsData} subheader={""} getData={getData} selectedItems={selectedData}/>
            }
                
            </DrawerMain>

        <form>
        {
            logoError && (
                <div className="header_alert">
                <AlertAction
                alert={"Logo size should be less than 1MB"}
                alertType={"error"}
                onClick={() => setlogoError(false)}
                />
            </div>
            )
        }
        <div className="formContainerHikmah bottomSpacing">
        <Grid container spacing={3}>
            <Grid item xs={12}> 
                <h1 className="title_top noBorder" style={{textAlign: "left"}}>Basic Information</h1>	 
            </Grid>	      

            <Grid item xs={12}> 
                <div className="field_box">
                <BaseTextField
                    value={name}
                    onChange={handleChange("name")}
                    label='Product Name'
                    placeholder="Product Name"
                    InputProps={false}
                    phone={false}
                    search={false}
                    style={styles.input}
                    color="green"
                    count={50}
                />
                </div>
            </Grid>

            <Grid item xs={12}> 
                <div className="field_box">
                <BaseTextField
                    value={stripeProductId}
                    onChange={handleChange("stripeProductId")}
                    label='Stripe Product ID'
                    placeholder="Stripe Product ID"
                    InputProps={false}
                    phone={false}
                    search={false}
                    style={styles.input}
                    color="green"
                />
                </div>
            </Grid>

            <Grid item xs={12}> 
            {chipData?.[0]?.src && <MediaUpload
                addMediaItem = {callback}
                label = "Product Logo Icon"
                buttonName = "ADD LOGO"
                typeOfMedia='/images'  
                selectedMedia = {{logoUrl: chipData?.[0]?.src, logoName: chipData?.[0]?.label }}  
            />}
                      {!chipData?.[0]?.src && <MediaUpload
                addMediaItem = {callback}
                label = "Product Logo Icon"
                buttonName = "ADD LOGO"
                typeOfMedia='/images'  
                selectedMedia = {{logoUrl: chipData?.[0]?.src, logoName: chipData?.[0]?.label }}  
            />}
                {/* <div className="field_box" style={{marginTop: 15, cursor: "pointer"}} onClick={() => onChipInputClick("logo")}>
                <BaseImgChip
                    chipData={chipData}
                    onInputClick={onInputClick}
                    onChipInputClick={() => onChipInputClick("logo")}
                    deleteChip={deleteChip}
                    label="Logo"
                />
                </div> */}
            </Grid>

            {/* <Grid item xs={12}> 
            {chipData?.[0]?.src && <MediaUpload
                addMediaItem = {callback}
                label = "Logo Icon"
                buttonName = "ADD LOGO ICON "
                typeOfMedia='/images'  
                selectedMedia = {{logoUrl: chipData?.[0]?.src, logoName: chipData?.[0]?.label }}  
            />}
                      {!chipData?.[0]?.src && <MediaUpload
                addMediaItem = {callback}
                label = "Logo Icon"
                buttonName = "ADD LOGO ICON "
                typeOfMedia='/images'  
                selectedMedia = {{logoUrl: chipData?.[0]?.src, logoName: chipData?.[0]?.label }}  
            />}
               
            </Grid> */}

            <Grid item xs={12}> 
            {chipData?.[0]?.src && <MediaUpload
                addMediaItem = {callback}
                label = "Logo Icon"
                buttonName = "ADD LOGO ICON "
                typeOfMedia='/images'  
                selectedMedia = {{logoUrl: chipData?.[0]?.src, logoName: chipData?.[0]?.label }}  
            />}
                      {!chipData?.[0]?.src && <MediaUpload
                addMediaItem = {callback}
                label = "Logo Icon"
                buttonName = "ADD LOGO ICON "
                typeOfMedia='/images'  
                selectedMedia = {{logoUrl: chipData?.[0]?.src, logoName: chipData?.[0]?.label }}  
            />}
               
            </Grid>

            <Grid item xs={12}> 
                <div className="field_box" style={{marginTop: 15, cursor: "pointer"}} onClick={() => onChipInputClick("apps")}>
                <BaseImgChip
                    chipData={chipData2}
                    onChipInputClick={() => onChipInputClick("apps")}
                    deleteChip={(val) => deleteChip2(val)}
                    label="Compatible With Applications"
                />
                </div>
            </Grid>
            
            <Grid item xs={12} > 
                <div className="field_box" style={{marginTop: "-10px"}}>
                <BaseSelect
                    value={integrationApi}
                    onChange={handleChange("integrationApi")}
                    object={integrationApiList}
                    style={styles.select}
                    helperText=""
                    label="Api Name"
                />
              </div>
            </Grid>

            <Grid item xs={12}> 
                <div className="field_box short-description">
                    <div className="description-label">Short Description</div>
                <TextField 
                    // label="Short Description"
                    variant={"outlined"}
                    value={shortDescription}
                    onChange={handleChange("shortDescription")}
                    placeholder="Short description here"
                    rows={2}
                    multiline
                    style={styles.input}
                    inputProps={{
                        maxLength: 100
                    }}
                />
                </div>
                <div className="description-count">CHAR {shortDescription?.length}/100</div>
            </Grid>
            

            <Grid item xs={12}> 
                <TextEditor label={"Long Description"} maxCharCount={500} onChange={(e) => onChange(e)} value={longDescription}  />
            </Grid>

            </Grid>
            </div>
        </form>
        </Fragment>
    )
})

export default BasicInfo;
