import React, {Fragment, useState, useEffect, forwardRef, useImperativeHandle} from 'react';
import { SimpleTable } from "@dclab/dc-react-ui-kit";
import CreateIcon from "../../../assets/img/create-24px.svg";
import DeleteIcon from "../../../assets/img/delete_forever-24px.svg";
// import InputAdornment from '@material-ui/core/InputAdornment';
// import { TextField } from '@material-ui/core';
// import { withRouter } from 'react-router';

const Plans = forwardRef((props, ref) => {
  const {applicationInfo, setstepDisabled} = props;

  const [selectedPlans, setselectedPlans] = useState([])

  useEffect(() => {
    setselectedPlans(applicationInfo?.plans?.length ? applicationInfo.plans : []);
 }, [applicationInfo])

//  useEffect(() => {
//    props.setChangeData(formDataFunc());
//  }, [selectedPlans]);

 const formDataFunc = () => ({ 
  plans: selectedPlans
});
  useImperativeHandle(ref, () => ({
    onSubmit() {    
        if (selectedPlans?.length) {
            let formData = formDataFunc();
            props.onAddAppInfo(formData)
        }
    }
  }));

  let columns = [
    { type: "number", name: "serial_number", label: "", width:"auto" },
    { type: "string", name: "name", label: "PLAN NAME", width:"20%" },
    { type: "string", name: "name", label: "PRICE", width:"20%" },
    { type: "string", name: "price", label: "PRICE", width:"60%" },
    { type: "status", name: "status", label: "STATUS", width:"20%"  },
    { type: "action", name: "actionIcons", label: "ACTION", width:"20%"  },
  ];

  let classProps = () => {
    const tempArray = [];
    columns.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };

  const changeClassProps = () => {
    let tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };

  const columns1 = (columns) =>
    columns.map((obj) => {
      return obj;
    });

    const checkStatus = (status) => {
      status = status ? status.toLowerCase() : "";
      if (status === 'available') {
        return "success"
      } else if (status === 'not available'){
        return "failed"
      } else{
        return "discontinued"
      }
   }

  const click = (msg) => {
    console.log(msg);
  };

  const editAction = (data) => {
    // console.log("edit", data[0].id)
    let id = data[0].id;
    props.history.push({pathname: '/edit-plan', state: {planId: id, appId: props.appId ?? ""}});
  }

  const deleteAction = (data) => {
    let id = data[0].id;
    let prevPlans = [...selectedPlans];
    prevPlans.splice(id, 1);
    setselectedPlans([...prevPlans]);
  }

  let rows = [];
  if (selectedPlans && selectedPlans.length) {
    rows = selectedPlans.map((app, index) => {
      return (
        [
          { type: "", id: index, name: '', value: " "},
          { type: "string", name: "category", value: app.name, id: index },
          { type: "string", name: "category", value: `$${app?.monthlyBilling?.price}${app?.monthlyBilling?.billingPeriod && app?.monthlyBilling?.price ? "/month" : ""}` },          
          { type: "string", name: "category", value: `$${app?.annuallyBilling?.price}${app?.annuallyBilling?.billingPeriod && app?.annuallyBilling?.price ? "/year": ""}` },
          { type: "status", status: checkStatus(app.status), value: app.status },
        ]
      )
    });
  }

  setstepDisabled(selectedPlans?.length ? false : true);
  
  return (<div className="plan-listing-table"> 
    <SimpleTable
    title="Plans"
    showHeader={true}
    rows={rows}
    columns={columns1(columns)}
    colSpan={6}
    actionSendButton={true}
    classProps={changeClassProps()}
    onClick={(message, index) => click(message)}
    showFilter={false}
    showAdd={false}
    // addText="ADD NEW"
    showFooter={false}
    // onAddClick={() => props.history.push({pathname: "/add-plan", state: {appId: props.appId ?? ""}})}
    // donationsCount={rows?.length ? 1 : 0}
    // noTitle="No Plans"
    // noPara="Add new plans to the application."
    // noBtnText="Add Plan"
    onInviteClick={() => props.history.push({pathname: "/add-plan", state: {appId: props.appId ?? ""}})}
    iconTextSize={14}
    sortColumnIndex={[4]}
    editIcon={CreateIcon}
    deleteIconChild={false}
    /* deleteIcon={DeleteIcon} */
    showIconCol={true}
    editAction={(data) => editAction(data)}
   /*  deleteAction = { (data) => deleteAction(data)} */
  />
  </div>
  )
})

export default Plans;