import { 
	SET_ALL_ORDERS,
	SET_ORDER,

} from '../Actions/actionTypes';

const initialState = {
	allOrders: [],
	orderDetails: {},
};

export default function(state = initialState, action) {
	switch (action.type) {
		case SET_ALL_ORDERS:
			return { ...state, allOrders: action.payload }

		case SET_ORDER:
			return { ...state, orderDetails: action.payload }

			
		default:
			return state;
	}
}