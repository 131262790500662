import React from 'react';
import moment from 'moment';
import { SimpleTable } from "@dclab/dc-react-ui-kit";



export default function tabContent(props) {
  let organizations = props.organizations;

  let columns = [
    { type: "number", name: "serial_number", label: "NO.", width:"auto" },
    { type: "string", name: "name", label: "NAME", width:"20%" },
    { type: "string", name: "orgtype", label: "ORG TYPE", width:"20%" },

    { type: "string", name: "date/time", label: "JOINED", width:"20%" },

    { type: "string", name: "time", label: "CITY/STATE", width:"30%"  },

    { type: "status", name: "status", label: "STATUS", width:"20%"  },
    { type: "action", name: "action", label: "ACTION", width:"20%"  },
  ];

  let classProps = () => {
    const tempArray = [];
    columns.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };

  const changeClassProps = () => {
    let tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };

  const columns1 = (columns) =>
    columns.map((obj) => {
      // delete obj.id;
      // delete obj.name;
      return obj;
    });

    const checkStatus = (status) => {
      status = status ? status.toLowerCase() : "";
      if (status === 'approved') {
        return "success"
      } else if (status === 'onboarding') {
        return "discontinued"
      } else if (status === 'blocked'){
        return "failed"
      } else{
        return "discontinued"
      }
   }


    
  let rows = [];
  if (organizations && organizations.length) {
    rows = organizations.map((org, index) => {
      return (
        [
          { type: "id", id: org._id, name: 'serial_number', value: index + 1},
          { type: "string", name: "category", value: org.orgName },
          { type: "string", name: "date", value: org.orgType },
          { type: "string", name: "date/time", value: moment(org.createdAt).format('ll') },
          { type: "string", name: "time", value: `${org.orgAddress && org.orgAddress.city && org.orgAddress.city}, ${org.orgAddress && org.orgAddress.state && org.orgAddress.state}` },
          { type: "status", status: checkStatus(org.orgStatus), value: org.orgStatus ? org.orgStatus : "" },
          { type: "action", status: "details", value: "see details" },
        ]
      )
    });
  }

  // [
  //   { type: "id",name: 'serial_number',  value: "02" },
  //   { type: "string", name: "category", value: "Masjid Bilal of New York City" },
  //   { type: "string", name: "date", value: "Masjid/Islamic Center" },
  //   { type: "string", name: "date/time", value: "Jan 23, 2020" },
  //   { type: "string", name: "time", value: "Brooklyn, NY" },
  //   { type: "status", status: "discontinued", value: "Onboarding"  },
  //   { type: "action", status: "details", value: "see details" },
  // ],
  // [
  //   { type: "id",name: 'serial_number',  value: "03" },
  //   { type: "string", name: "category", value: "Darul Jannah Masjid" },
  //   { type: "string", name: "date", value: "Masjid/Islamic Center" },
  //   { type: "string", name: "date/time", value: "Jan 23, 2020" },
  //   { type: "string", name: "time", value: "Brooklyn, NY" },
  //   { type: "status", status: "failed", value: "Blocked"  },
  //   { type: "action", status: "details", value: "see details" },
  // ],
  // [
  //   { type: "id",name: 'serial_number',  value: "04" },
  //   { type: "string", name: "category", value: "Darul Jannah Masjid" },
  //   { type: "string", name: "date", value: "Masjid/Islamic Center" },
  //   { type: "string", name: "date/time", value: "Jan 23, 2020" },
  //   { type: "string", name: "time", value: "Brooklyn, NY" },
  //   { type: "status", status: "failed", value: "Blocked"  },
  //   { type: "action", status: "details", value: "see details" },
  // ],
  // [
  //   { type: "id",name: 'serial_number',  value: "05" },
  //   { type: "string", name: "category", value: "Masjid Bilal of New York City" },
  //   { type: "string", name: "date", value: "Masjid/Islamic Center" },
  //   { type: "string", name: "date/time", value: "Jan 23, 2020" },
  //   { type: "string", name: "time", value: "Brooklyn, NY" },
  //   { type: "status", status: "discontinued", value: "Onboarding"  },
  //   { type: "action", status: "details", value: "see details" },
  // ],
  // [
  //   { type: "id",name: 'serial_number',  value: "06" },
  //   { type: "string", name: "category", value: "Islamic Institute of Brooklyn" },
  //   { type: "string", name: "date", value: "Masjid/Islamic Center" },
  //   { type: "string", name: "date/time", value: "Jan 23, 2020" },
  //   { type: "string", name: "time", value: "Brooklyn, NY" },
  //   { type: "status", status: "success", value: "Approved"  },
  //   { type: "action", status: "details", value: "see details" },
  // ],


  // ];


  const click = (msg) => {
    console.log(msg);
  };

  const btnClick = () => {
    console.log('invite');
  }

  const actionLinkHandler = (data) => {
    // console.log("checking Link data: ", data);
    let id = data[0].id;
    // console.log("id is: ", id);
    props.history.push(`/organization-detail-${id}`)
  }

  return (<SimpleTable
    title="Organizations"
    showHeader={true}
    rows={rows}
    columns={columns1(columns)}
    colSpan={6}
    actionSendButton={true}
    actionLink={(data) => actionLinkHandler(data)}
    classProps={changeClassProps()}
    onClick={(message, index) => click(message)}
    showFilter={false}
    showAdd={true}
    addText="INVITE ORG"
    showFooter={true}
    donationsCount={10}
    onAddClick={() => props.history.push('/invite-organization')}
    noTitle="No recent donations"
    noPara="Add donation categories so that people can donate for a specific cause."
    noBtnText="Invite Donors"
    onInviteClick={() => btnClick()}
    iconTextSize={14}
    sortColumnIndex={[5]}
  />
  )
}


