import React, {useState, useEffect} from 'react';
import moment from 'moment';
import { SimpleTable } from "@dclab/dc-react-ui-kit";
import { withRouter } from 'react-router-dom';

 function TabContent(props) {
const [rows, setrows] = useState([])
  let columns = [
    { type: "number", name: "serial_number", label: "NO.", width:"auto" },
    { type: "string", name: "name", label: "App Name", width:"20%" },
    { type: "string", name: "orgtype", label: "Plan Name", width:"15%" },

    { type: "string", name: "date/time", label: "Price", width:"15%" },

    { type: "string", name: "time", label: "Billing cycle", width:"20%"  },
    { type: "string", name: "time", label: "Payment method", width:"30%"  },

    { type: "status", name: "status", label: "STATUS", width:"15%"  },
    { type: "action", name: "action", label: "ACTION", width:"15%"  },
  ];

  let classProps = () => {
    const tempArray = [];
    columns.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };

  const changeClassProps = () => {
    let tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };

  const columns1 = (columns) =>
    columns.map((obj) => {
      // delete obj.id;
      // delete obj.name;
      return obj;
    });

    const checkStatus = (status) => {
      status = status ? status.toLowerCase() : "";
      if (status === 'approved') {
        return "success"
      } else if (status === 'onboarding') {
        return "discontinued"
      } else if (status === 'blocked'){
        return "failed"
      } else{
        return "discontinued"
      }
   }


    useEffect(() => {
      setrows(props.appRows?.length ?  props.appRows : [])
    }, [props.appRows])
  //  if (organizations && organizations.length) {
  //   rows = organizations.map((org, index) => {
  //     return (
  //       [
  //         { type: "id", id: org._id, name: 'serial_number', value: index + 1},
  //         { type: "string", name: "name", value: org.orgName },
  //         { type: "string", name: "date", value: org.orgType },
  //         { type: "string", name: "date/time", value: moment(org.createdAt).format('ll') },
  //         { type: "string", name: "time", value: `${org.orgAddress && org.orgAddress.city && org.orgAddress.city}, ${org.orgAddress && org.orgAddress.state && org.orgAddress.state}` },
  //         { type: "string", name: "time", value: `${org.orgAddress && org.orgAddress.city && org.orgAddress.city}, ${org.orgAddress && org.orgAddress.state && org.orgAddress.state}` },
  //         { type: "status", status: checkStatus(org.orgStatus), value: org.orgStatus ? org.orgStatus : "" },
  //         { type: "action", status: "details", value: "see details" },
  //       ]
  //     )
  //   });
  // }

  


  const click = (msg) => {
    console.log(msg);
  };

  const btnClick = () => {
    console.log('invite');
  }

  const actionLinkHandler = (data) => {
    console.log("checking Link data: ", props.history);
    let planData = data[0].plan;
    console.log("id is: ", planData);
    props.history.push({pathname: `/plans/details`, state: { plan: planData }})

  }

  return (<SimpleTable
    title="Plans"
    showHeader={true}
    rows={rows}
    columns={columns1(columns)}
    colSpan={6}
    actionSendButton={true}
    actionLink={(data) => actionLinkHandler(data)}
    classProps={changeClassProps()}
    onClick={(message, index) => click(message)}
    showFilter={false}
    showAdd={false}
    addText="INVITE ORG"
    showFooter={true}
    donationsCount={10}
    onAddClick={() => props.history.push('/invite-organization')}
    noTitle="No recent donations"
    noPara="Add donation categories so that people can donate for a specific cause."
    noBtnText="Invite Donors"
    onInviteClick={() => btnClick()}
    iconTextSize={14}
    // sortColumnIndex={[5]}
  />
  )
}


export default withRouter(TabContent)