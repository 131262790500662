import React, { useState } from 'react';
import styles from './Header.module.css';
import MainLogo from '../../assets/img/DC_Logo.png';
import Crown from '../../assets/img/crown.svg';
import {connect} from 'react-redux';
import * as actions from '../../store/Actions/index';
import { SimpleAppBar } from "@dclab/dc-react-ui-kit";
import './Header.module.css';
import Alert from '../../components/common/AlertAction';
import './header.css';
import { showMessage } from '../../store/Actions/pageActions';

function Header(props) {
    // const {currentUser} = props;
    // console.log("porp user", props.currentUser)
    let userName = localStorage.getItem("userName");
    let role = localStorage.getItem("role");
    let image = localStorage.getItem("image");

      const logout = () => {
        props.logoutUser(props.history, true);
      };
      const getInitials = () => {
        let nameArr = userName ? userName?.trim()?.split(" "): '';
        let initials;
        if (nameArr?.length > 1) {
          initials = nameArr[0][0] + nameArr[1][0];
        } else {
          initials = nameArr?.[0]?.[0] + nameArr[0]?.[1] ?? '';
        } 
        return initials;
      }

      const userProfile = () => {
        props.history.push("/admin-profile")
      }
      
      const showAlertHandler = () => {
          props.onShowMessage("")
      }

       setTimeout(() => {
          props.onShowMessage("")
        }, 8000);
        
          return(
            <div className="header_container">
              <div style={{position: "absolute", left: "25%", zIndex: "9999"}}>
                {props.showMessage && <Alert message={props.showMessage} type={"success"} 
                click={showAlertHandler} show={true} />}
              </div>
                        
            <header style={styles.header} style={{ boxShadow:"0px 1px 4px #0000001A", }}>
            <SimpleAppBar 
                src={MainLogo}
                name={userName ? userName : ""}
                role={role && role === "hikmah_owner" ? "Owner" : role && role === "hikmah_admin" ? "Admin" : "" }
                icon={Crown}
                showImg={image && image !== "undefined" ? true : false}
                img={image && image !== "undefined" ? image : ""}
                onClickProfile={() => userProfile()}
                onClickLogout = {() => logout()}
                initials = {getInitials()}
               
            />
          </header>
          </div>
          );
      }


const mapStateToProps = state => {
    return {
      showMessage: state.page.showMessage,
      currentUser: state.auth.currentUserDetails
    }
};
  
const mapDispatchToProps = dispatch => {
  return {
    logoutUser: (history, unsetCookie) => dispatch(actions.logoutUser(history, unsetCookie)),
    onShowMessage: (data) => dispatch(showMessage(data)) 
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Header);