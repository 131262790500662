import React, { Component, useState } from 'react'
import { withRouter } from 'react-router-dom';

import { NavDrawer, BaseIcon } from "@dclab/dc-react-ui-kit";

import { ReactComponent as Dashboard } from '../../assets/nav_icons/dash.svg';
import { ReactComponent as Message } from '../../assets/nav_icons/message.svg';
import { ReactComponent as Announcement } from '../../assets/nav_icons/announcements.svg';
import { ReactComponent as Organization } from '../../assets/nav_icons/orgranization.svg';
import { ReactComponent as Events } from '../../assets/nav_icons/events.svg';
import { ReactComponent as Monetization } from '../../assets/nav_icons/monetization.svg';
import { ReactComponent as Website } from '../../assets/nav_icons/website.svg';
import { ReactComponent as MoreApps } from '../../assets/nav_icons/moreapps.svg';
import { ReactComponent as Support } from '../../assets/nav_icons/support.svg';
import { useEffect } from 'react';

import LocalOfferRoundedIcon from '@material-ui/icons/LocalOfferRounded';

import { connect } from 'react-redux';
import * as actions from '../../store/Actions/index';



function NavBar(props) {
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         orgDetail: window.location.href.indexOf("organization-detail") > -1 ? true : false
    //     };
    // }

    // const [orgDetail, setorgDetail] = useState(window.location.href.indexOf("organization-detail") > -1 ? true : false)

    useEffect(() => {
        if (props.match.params.id && props.orgDetail) {
            props.onGetSingleOrganiztion(props.match.params.id)
        }
    }, [props.match.params.id])

    let navigate = (x, y) => {
        // console.log("checking Props: ", props);
        props.history.replace(y);
    }



    let headerItems = [
        {
            title: "Dashboard",
            // badgeText:'Coming Soon',
            icon: <BaseIcon icon={Dashboard} />,
            path: "/dashboard",
        },
        {
            title: "Communication",
            icon: <BaseIcon icon={Monetization} />,
            path: "/communication/contact-us",
            child: [],
        },
        // {
        //     title: "Messages",
        //     icon: <BaseIcon icon={Message} />,
        //     path: "/dashbaord",
        // },
    ];

    let bodyItems = {
        manage: [
            {
                title: "Orders",
                icon: <BaseIcon icon={Announcement} />,
                path: "/order",
                child: [],
            },
            {
                title: "Organization",
                icon: <BaseIcon icon={Organization} />,
                path: "/admin-organization",
                child: [],
            },
            {
                title: "Products",
                icon: <BaseIcon icon={Events} />,
                path: "/admin-products",
                child: [],
            },
            {
                title: "Hardware/Devices",
                icon: <BaseIcon icon={Events} />,
                path: "/hardware",
                child: [],
            },
            

        ],
        promotion: [
            {
                title: "Campaign",
                icon: <BaseIcon icon={LocalOfferRoundedIcon} />,
                path: "/campaign",
                child: [],
                // badgeText:'Coming Soon'
            },
            {
                title: "Ads",
                icon: <BaseIcon style={{ background: 'white', borderRadius: '20px' }} icon={Support} />,
                path: "/organization-detail",
                child: [],
                // badgeText:'Coming Soon'
            },

        ],
        // accounts: [
        //     {
        //         title: 'Finance',
        //         path: "/organization-detail",
        //         icon: <BaseIcon icon={Website} />,
        //         child: [],
        //         // badgeText:'Coming Soon',
        //         // icon: <BaseIcon icon={MoreApps} />,
        //     },
        //     {
        //         title: 'Admin',
        //         path: "/organization-detail",
        //         icon: <BaseIcon style={{ background: 'white', borderRadius: '20px' }} icon={Support} />,
        //         child: [],
        //         // secondaryIcon:<BaseIcon icon={Launch} />,
        //         // badgeText:'Coming Soon'
        //     },
        // ],
        Accounts: [
            {
                title: 'Finance',
                path: "/organization-detail",
                icon: <BaseIcon icon={Website} />,
                child: [],
                // badgeText:'Coming Soon',
                // icon: <BaseIcon icon={MoreApps} />,
            },
            {
                title: 'Team Members',
                path: "/team-members",
                icon: <BaseIcon icon={Website} />,
                child: [],
                // badgeText:'Coming Soon',
                // icon: <BaseIcon icon={MoreApps} />,
            },
            {
                title: 'Manage Roles',
                path: "/organization-detail",
                icon: <BaseIcon style={{ background: 'white', borderRadius: '20px' }} icon={Support} />,

                //  icon: <BaseIcon icon={MoreApps} />,
                child: [],
                // secondaryIcon:<BaseIcon icon={Launch} />,
                // badgeText:'Coming Soon'
            }
        ]
    };

    let orderDetailItem = [
        {
            title: "Org Overview",
            path: `/organization-detail-${props.match.params.id}`,
        },
        {
            title: "Plans",
            path: `/plans/${props.match.params.id}`,
        },
        {
            title: "Hardware",
            path: `/assign-hardware/${props.match.params.id}`,
        },
        {
            title: "Orders",
            path: `/orgorders/${props.match.params.id}`,
        },
    ];
    
    let communicationItem = [
        {
            title: "Contact Us",
            path: `/communication/contact-us`,
        },
        {
            title: "Demo Request",
            path: "/communication",
        },
    ];




    let backBottonHandler = (orgDetail) => {
        if (orgDetail) {
            props.history.push('/admin-organization');
        } else if (props.communicationPage) {
            props.history.push('/admin-organization');
        }
    }
    
    let orgDetail = props.orgDetail;
    return (
        <NavDrawer
            selectedTitle={`${props.communicationPage ? "Communication" : props?.organization?.orgName ? props.organization.orgName : "" }`}
            headerNavItems={props.communicationPage ? communicationItem : orgDetail !== true ? headerItems : orderDetailItem}
            bodyNavItems={!props.communicationPage && orgDetail !== true ? bodyItems : {}}
            buttonShow={props.communicationPage || orgDetail ? true : false}
            showTitle={props.communicationPage || orgDetail ? true : false}
            onBackBtnClick={() => backBottonHandler(orgDetail)}
            onClick={(message) => console.log("checking message: ", message)}
            navigate={(x, y) => navigate(x, y)}
        />
    )
}

const mapStateToProps = state => {
    return {
        organization: state.organizations.organization,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetSingleOrganiztion: (id) => dispatch(actions.getOrganizationDetail(id)),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));

