import React from 'react';
import { BaseSelect} from "@dclab/dc-react-ui-kit";
import { Fragment, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import { TextField,Grid } from '@material-ui/core';
import axios from 'axios';

const backendServerURL = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;

const Pricing = forwardRef((props, ref) => {
  const {integrationInfo, setstepDisabled} = props;

  const [pricingType, setpricingType] = useState("")

    const [monthlyBilling, setmonthlyBilling] = useState({
        stripePlanId: "",
        price: undefined,
        billingPeriod: "Monthly",
        discountPercentage: 0,
    })

    const [annuallyBilling, setannuallyBilling] = useState({
        stripePlanId: "",
        price: undefined,
        billingPeriod: "Annually",
        discountPercentage: 0,
    })

    useEffect(() => {
        const {pricing} = integrationInfo;
        if(pricing?.pricingType){
            setpricingType(pricing?.pricingType);
            setannuallyBilling({
                stripePlanId: pricing?.annuallyBilling?.stripePlanId ?? undefined,
                price: pricing?.annuallyBilling?.price ?? undefined,
                billingPeriod: "Annually",
                discountPercentage: pricing?.annuallyBilling?.discountPercentage ?? 0,
            })
            setmonthlyBilling({
                stripePlanId: pricing?.monthlyBilling?.stripePlanId ?? undefined,
                price: pricing?.monthlyBilling?.price ?? undefined,
                billingPeriod: "Monthly",
                discountPercentage: pricing?.monthlyBilling?.discountPercentage ?? 0,
            })
        }
    }, [integrationInfo])

  useImperativeHandle(ref, () => ({
    onSubmit() {    
        if (pricingType === "Paid") {
            let formData = {
                pricing: {pricingType, annuallyBilling, monthlyBilling}
            }
            props.onAddIntegrationInfo(formData)
        } else {
          let formData = {
            pricing: {pricingType}
        }
        props.onAddIntegrationInfo(formData)
        }
    }
  }));

let handleChange2 = (prop) => (event) => {
    if(prop === 'stripePlanId') {
        let val = event.target.value ? event.target.value?.trim() : "";
        setmonthlyBilling({...monthlyBilling, [prop]: event.target.value });
        if(val) {
            axios
            .get(backendServerURL + '/stripe/getStripePlan/' + val)
            .then(res => {
                const data = res?.data?.body?.data;
                if(data?.id) {
                    setmonthlyBilling({...monthlyBilling, stripePlanId: data.id ?? "", price: data.amount ? data.amount / 100 : 0 });
                }
            })
            .catch(err => {
                console.log("error: ", err)
            })
        }
    } else if((prop === "discountPercentage" || prop === "price") && event.target.value >= 0){
        setmonthlyBilling({...monthlyBilling, [prop]: event.target.value });
    }
}

let handleChange3 = (prop) => (event) => {
    if(prop === 'stripePlanId') {
        let val = event.target.value ? event.target.value?.trim() : "";
        setannuallyBilling({...annuallyBilling, [prop]: event.target.value });
        if(val) {
            axios
            .get(backendServerURL + '/stripe/getStripePlan/' + val)
            .then(res => {
                const data = res?.data?.body?.data;
                if(data?.id) {
                    setannuallyBilling({...annuallyBilling, stripePlanId: data.id ?? "", price: data.amount ? data.amount / 100 : 0 });
                }
            })
            .catch(err => {
                console.log("error: ", err)
            })
        }
    } else if((prop === "discountPercentage" || prop === "price") && event.target.value >= 0){
        setannuallyBilling({...annuallyBilling, [prop]: event.target.value });
    }
}
    //(monthlyBilling.price && monthlyBilling.stripePlanId && annuallyBilling.price && annuallyBilling.stripePlanId
  if(pricingType === "Paid") {
    setstepDisabled(monthlyBilling.price && annuallyBilling.price ? false : true);
  } else {
    setstepDisabled(pricingType ? false : true);
  }

  let styles = {
    input: {
        height: '48px',
        // marginTop: '20px',
        width: '100%',
        border: "#707070",
        marginLeft: '0px'
    }
  };

  return (
    <Fragment> 
        <div className="formContainerHikmah bottomSpacing">
        <Grid container spacing={3}>
        <Grid item xs={12}> 
            <h1 className="title-top title-top-section">Pricing</h1>	 
        </Grid>	      

        <Grid item xs={12}> 
            <div className="field_box">
            <BaseSelect
                value={pricingType}
                onChange={(e) => setpricingType(e.target.value)}
                object={[
                    {
                        value: "Free",
                        label: "Free",
                    },
                    {
                        value: "Paid",
                        label: "Paid",
                    }
                ]}
                style={styles.input}
                helperText=""
                label='Integration Type'
                placeholder="Integration Type"
            />
              </div>
        </Grid>
        
        {pricingType === "Paid" ? <Fragment> 
        <Grid item xs={4}> 
            <div className="field_box">
            <TextField
                value={monthlyBilling.price}
                onChange={handleChange2("price")}
                label='Price'
                phone={false}
                search={false}
                style={styles.input}
                color="green"
                type="number"
                InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
            />
            </div>
        </Grid>

        <Grid item xs={4}> 
        <div className="field_box">
            <TextField
            value={monthlyBilling.billingPeriod}
            onChange={handleChange2("billingPeriod")}
            label='Billing Cycle'
            phone={false}
            search={false}
            style={styles.input}
            disabled
            />
            </div>
        </Grid>

        <Grid item xs={4}> 
        <div className="field_box">
            <TextField
                value={monthlyBilling.discountPercentage}
                onChange={handleChange2("discountPercentage")}
                helperText=""
                label='Discount'
                phone={false}
                search={false}
                style={styles.input}
                color="green"
                type="number"
                InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
            />
              </div>
        </Grid>

        <Grid item xs={12}> 
        <div className="field_box">
            <TextField
                value={monthlyBilling.stripePlanId}
                onChange={handleChange2("stripePlanId")}
                helperText=""
                label='Stripe Price ID'
                phone={false}
                search={false}
                style={styles.input}
                color="green"
            />
              </div>
        </Grid>

        <Grid item xs={12}>
                <div className="title_top"></div>
        </Grid>


        <Grid item xs={4}> 
            <div className="field_box">
            <TextField
                value={annuallyBilling.price}
                onChange={handleChange3("price")}
                label='Price'
                phone={false}
                search={false}
                style={styles.input}
                color="green"
                type="number"
                InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
            />
            </div>
        </Grid>

        <Grid item xs={4}> 
        <div className="field_box">
            <TextField
            value={annuallyBilling.billingPeriod}
            onChange={handleChange3("billingPeriod")}
            label='Billing Cycle'
            phone={false}
            search={false}
            style={styles.input}
            disabled
            />
        </div>
        </Grid>

        <Grid item xs={4}> 
        <div className="field_box">
        <TextField
            value={annuallyBilling.discountPercentage}
            onChange={handleChange3("discountPercentage")}
            helperText=""
            label='Discount'
            phone={false}
            search={false}
            style={styles.input}
            color="green"
            type="number"
            InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
        />
                </div>
        </Grid>

        <Grid item xs={12}> 
        <div className="field_box">
            <TextField
                value={annuallyBilling.stripePlanId}
                onChange={handleChange3("stripePlanId")}
                helperText=""
                label='Stripe Price ID'
                phone={false}
                search={false}
                style={styles.input}
                color="green"
            />
              </div>
        </Grid>
          </Fragment> : null}
        
        </Grid>
        </div>
      
  </Fragment>
  )
})

export default Pricing;