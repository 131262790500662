import axios from 'axios';


const orgBaseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;


export async function getMediaItems(  token , path=''){
      
    if( path.substr(-1) === '/') {
      path = path.substr(0, path.length -1 );
    }
  
    return new Promise((resolve, reject) => {
      axios
        .get(`${orgBaseUrl}/hikmah${path}` , {
          headers: {
            "Content-Type": "application/json",
            token
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }


export async function uploadMedia(imageMetaData, postData, token) {
    return new Promise((resolve, reject) => {
        axios
          .post(`${orgBaseUrl}/hikmah/image`, imageMetaData, {
            headers: {
              "Content-Type": "application/json",
              token
            }
          })
          .then((res) => {
            resolve(res);
            /* setImageuploaded(false );
            setUploadButton('importUrlButton'); 
            
            setFiles([]);
            props.onChange(imageMetaData.imageSize);*/
            
  
          })
          .catch((error) => {
            reject(error);
          });
      })
  }