import React, { useEffect, useState } from 'react'
import { AlertAction } from "@dclab/dc-react-ui-kit";
import { connect } from 'react-redux';
import * as actions from '../../store/Actions/index';
import AssignHardwareTable from './AssignHardwareTable';
import Spinner from '../../components/common/Spinner';
import axios from 'axios';

function AssignHardware(props) {

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        props.onClearError();
        props.onGetOrgAssignedDevices(props.match.params.id);
    }, [])

    let styles = {
        routes: {
            
        }
    }

    const deleteDevice = (id) => {
        console.log("assign deleteAction", id);
        let formData = { deviceId: id, organizationId: "" };
        const orgBaseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
        axios.put(`${orgBaseUrl}/digital-device/assignDeviceToOrganization/${id}`, formData, {
            headers: {
                'token': localStorage.authToken
            }
        })
        .then((response) => {
            console.log(response);
            props.onGetOrgAssignedDevices(props.match.params.id);
            props.showRemoveDeviceAlert();
            //props.history.push(`/assign-hardware/${props.match.params.id}`)
        })
        .catch((error) => {
            console.log(error);
        });
    }

    return (
        <div style={{ ground: '#F7F8FA', height: '100%', minHeight: '995px', width: '100%' }}>
            <div style={styles.routes}>
            {
              props.errors && props.errors.error && <AlertAction
              alert={props.errors.message}
              alertType={"error"}
            //   onClick={this.closeAlert}
            />
            }

                {props.page && props.page.loading && <Spinner />}
                <AssignHardwareTable loading={props.page.loading} devices={props.orgAssignedDevices} history={props.history} deleteDevice={deleteDevice} />
            </div>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        orgAssignedDevices: state.devices.orgAssignedDevices ? state.devices.orgAssignedDevices : [],
        page: state.page,
        errors: state.errors
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetOrgAssignedDevices: (id) => dispatch(actions.getOrgAssignedDevices(id)),
        onDeleteDevice: (id) => dispatch(actions.deleteDevice(id)),
        showRemoveDeviceAlert: () => dispatch(actions.showRemoveDeviceAlert()),
        onClearError: () => dispatch(actions.clearErrors())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignHardware);