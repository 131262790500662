import React, { useState, Fragment, useEffect } from 'react'
import { DrawerMain, BaseButton, BaseTextField, BaseSelect, BaseImgChip, BaseFileSelect, MasterLayoutMiddle, FooterInner, DrawerListRadioButton  } from "@dclab/dc-react-ui-kit";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import axios from 'axios'
import { connect } from 'react-redux';
import * as actions from '../../store/Actions/index';
import Spinner from '../../components/common/Spinner';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import { TextField } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';



function EditCampaign(props) {
  const [open, setOpen] = React.useState(false);
  const [uploadedImage, setuploadedImage] = useState(null)
  const [device, setdevice] = useState({ appStream: "", deviceId: "", macAddress: "", status: "Available", image: "", price: "" })

  const [deviceError, setdeviceError] = useState({ appStreamError: false, macAddressError: false, imageError: false, priceError: false })
  const [details, setdetails] = useState([{ type: 'paragraph', children: [{ text: '' }] }]);
  const [chipData, setchipData] = useState([])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [])

  let handleChange = (prop) => (event) => {
    if ((prop === "price") && event.target.value && event.target.value <= 0) {
      return
    }
    setdevice({
      ...device, [prop]: event.target.value
    });

    let streamInitials = '';
    let value = event.target.value;

    if (prop === "appStream") {
      if (event.target.value === 'DEENKIOSK') {
        streamInitials = 'DK'
      } else if (event.target.value === 'DEENSCREEN') {
        streamInitials = 'DS'
      } else {
        streamInitials = ''
      }
      const orgBaseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
      axios.get(`${orgBaseUrl}/digital-device/getUniqueDeviceId?appStream=${streamInitials}`).then((result) => {
        console.log(result.data)
        setdevice({ ...device, appStream: value, deviceId: result.data })
      }).catch((err) => {
        console.log(err);
      });
    }
  }

  const onChange = (e) => {
    setdetails(e)
  };

  let onSubmit = (e) => {
    e.preventDefault();
    console.log("submit", details)
    let formData = {
      ...device,
      macAddress: device.macAddress.toUpperCase(),
      price: device.price.match(/\d/g).join(""),
      details: JSON.stringify(details),
      imageMetaData: {
        imageUrl: image,
        imageName: uploadedImage.name,
        imageSize: uploadedImage.size
      },
    };

    if (appStream && deviceId && macAddress && status && image && price && details) {
      props.onAddDevice(formData)
    }
  }

  let styles = {
    txt: {
      color: '#4D4D4D ',
      letterSpacing: '1.26px',
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: '15px'
    },
    chipInput: {
      width: "100%"
    },
    backBtn: {
      backgroundColor: 'white',
      borderRadius: '18px',
      color: '#4D4D4D',
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '15px',
      width: '86px',
      height: '36px'
    },
    routes: {
      marginLeft: '70px',
      width: '90%',
      padding: '30px 0',
      display: 'flex'
    },
    input: {
      height: '48px',
      marginTop: '20px',
      width: '100%',
      border: "#707070",
    },
    input2: {
      height: '48px',
      marginTop: '20px',
      marginRight: '22px',
      width: '48%',
      float: 'left',
      border: "#707070",
    },
    input3: {
      height: '48px',
      marginTop: '20px',
      width: '48%',
      border: "#707070",
    },
    inviteBtn: {
      textAlign: 'center',
      color: 'white',
      letterSpacing: '1.26px',
      height: '45px',
      background: '#2490F9 0% 0% no-repeat padding-box',
      borderRadius: '23px',
      padding: '14px 45px'
    },
    disableBtn: {
      textAlign: 'center',
      color: '#222',
      letterSpacing: '1.26px',
      height: '45px',
      background: '#eee',
      borderRadius: '23px',
      padding: '14px 45px'
    },
    cancelBtn: {
      textAlign: 'center',
      color: 'black',
      letterSpacing: '1.26px',
      height: '45px',
      borderRadius: '23px',
      backgroundColor: 'white',
      padding: '14px 45px'
    }
  }

  const style = {
    width: '100%',
  };

  const callback = (selectedFiles) => {
    console.log(selectedFiles[0])
    if (selectedFiles.length > 0 && selectedFiles[0].src) {
      setuploadedImage(selectedFiles[0])
    }
  };

  const closeDrawer = () => {
    setOpen(!open);
  };

  const onCancel = () => {
    setOpen(false);
  };

  const onSave = () => {
    if (uploadedImage) {
      setchipData([{ key: 0, label: uploadedImage.name, src: uploadedImage.src, alt: uploadedImage.name }])
      setdevice({ ...device, image: uploadedImage.src })
      setOpen(!open);
    }
  };


  const onInputClick = () => {
    console.log("input clicked");
  };

  const onChipInputClick = () => {
    console.log("input chip clicked");
    setOpen(!open);
  };

  const deleteChip = () => {
    setdevice({ ...device, image: "" })
    setchipData([])
  }

  const { appStream, deviceId, macAddress, status, image, price } = device;
  const { appStreamError, macAddressError, priceError } = deviceError;
  let disableBtn = appStream && deviceId && macAddress && status && image && price && details[0].children[0].text ? false : true;




const [content, setContent] = React.useState('');
const [repeatcontent, setRepeatContent] = React.useState('');

  const childRef = React.useRef();

  const saveRepeats = (e) => {
    console.log('e',e)
    setRepeatContent(e)
    setContent(e.repeat_content)
    setOpen(false);
  };
  
  const cancleRepeats = (e) => {
    setOpen(false);
  };


  const data1 = [
    {
      id: 1,
      primary: "Discount Coupons",
      secondary: "Special event discount codes (e.g. EID2021, RAMADAN27)",
    },
    {
      id: 2,
      primary: "Promotion",
      secondary: "Automatically applied discount based on shopping cart",
    },
  
    {
      id: 3,
      primary: "Referral Codes",
      secondary: "Referral program with rewards (e. g. $20 for you and every invited friend)",
    },
  
  ];
  
  const getData = (value, secondary) => {
    console.log(value);
    console.log(secondary);
  };
  
  const selectedData = {
      id: 4,
      primary: "Salāt al-Eid al-Adha",
      secondary: "It is possible that some time this subtitle can be two line",
  };

  const [value, setValue] = React.useState('female');

  const handleChanges = (event) => {
    setValue(event.target.value);
  };



  return (
    <Fragment>
      <DrawerMain
        open={open}
        openHandler={closeDrawer}
        title="Image"
        onCancel={onCancel}
        onSave={onSave}
        loading={false}
        // img={Logo}
        saveDisabled={false}
      >

        <BaseFileSelect
          selectedFiles={callback}
        />
      </DrawerMain>

      <MasterLayoutMiddle
        header={
          <div>
            {/* {
                    props.errors && props.errors.error && <AlertAction
                    alert={props.errors.message}
                    alertType={"error"}
                    //   onClick={this.closeAlert}
                    />
                    } */}
            {/* <Link to="/admin-organization" style={{textDecoration: 'none'}} > */}
            <div className="backBtnFrom">
              <BaseButton
                onClick={() => props.history.push("/hardware")}
                text="back"
                textStyle={styles.txt}
                disableElevation={false}
                disableFocusRipple={false}
                disableRipple={false}
                style={styles.backBtn}
                startIcon={<ChevronLeftIcon />}
              />
            </div>
            {/* </Link> */}
            {props.page && props.page.loading && <Spinner />}
          </div>
        }
        isForm={true}
        formData={
          <div>
          <form onSubmit={onSubmit}>
            <Grid container spacing={3}>

              <Grid item xs={12}>
                <h1 className="title_top">Edit Campaign</h1>
              </Grid>

              <Grid item xs={12}>
              <FormControl component="fieldset" className="RedrioGroupContainer">
                <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChanges}>
                  <FormControlLabel value="female" control={<Radio />} label="Add From Stripe" />
                  <FormControlLabel value="male" control={<Radio />} label="Custom Campaign" />
                </RadioGroup>
              </FormControl>
              </Grid>

              <Grid item md={12} sm={12} xs={12}>
                <div className="field_box">
                  <BaseTextField
                    value={macAddress.toUpperCase()}
                    onChange={handleChange("macAddress")}
                    label='Stripe Promo ID'
                    placeholder="Stripe Promo ID"
                    InputProps={false}
                    phone={false}
                    search={false}
                    //count={100}
                    style={styles.input}
                    onBlur={() => macAddress ? setdeviceError({ macAddressError: false }) : setdeviceError({ macAddressError: true })}
                    error={!macAddress && macAddressError ? true : false}
                  />
                </div>
              </Grid>

              <Grid item md={6} sm={6} xs={6}>
              <div className="field_box">
                <BaseTextField
                  value={content}
                  // onChange={handleChange("name")}
                  InputProps={true}
                  style={{width:'100%'}}
                  onClick={() => setOpen(true)}
                  disabled={false}
                  onArrowClick={() => setOpen(true)}
                  label="Campaign Type"

                />
                </div>
              </Grid>

              <Grid item md={6} sm={6} xs={6}>
                <div className="field_box">
                  <BaseTextField
                    value={macAddress.toUpperCase()}
                    onChange={handleChange("macAddress")}
                    label='Code Name'
                    placeholder="Code Name"
                    InputProps={false}
                    phone={false}
                    search={false}
                    //count={100}
                    style={styles.input}
                    onBlur={() => macAddress ? setdeviceError({ macAddressError: false }) : setdeviceError({ macAddressError: true })}
                    error={!macAddress && macAddressError ? true : false}
                  />
                </div>
              </Grid>


              <Grid item md={4} sm={4} xs={12}>
                <div className="field_box">
                  <BaseSelect
                    value={status}
                    onChange={handleChange("status")}
                    label='Discount Type'
                    placeholder="Discount Type"
                    object={[
                      { value: "Available", label: "Available" },
                      { value: "Unavailable", label: "Unavailable" },
                      { value: "Defective", label: "Defective" },
                      { value: "Lost", label: "Lost" },
                      { value: "Locked", label: "Locked" },
                      { value: "Active", label: "Active" },
                      { value: "Shipped", label: "Shipped" },
                    ]}
                    style={styles.input}

                  />
                </div>
              </Grid>
              

              <Grid item md={4} sm={4} xs={12}>
                <div className="field_box">
                  <BaseTextField
                    value={macAddress.toUpperCase()}
                    onChange={handleChange("macAddress")}
                    label='Value (%)'
                    placeholder="Value (%)"
                    InputProps={false}
                    phone={false}
                    search={false}
                    //count={100}
                    style={styles.input}
                    onBlur={() => macAddress ? setdeviceError({ macAddressError: false }) : setdeviceError({ macAddressError: true })}
                    error={!macAddress && macAddressError ? true : false}
                  />
                </div>
              </Grid>

              <Grid item md={4} sm={4} xs={12}>
                <div className="field_box">
                  <BaseTextField
                    value={macAddress.toUpperCase()}
                    onChange={handleChange("macAddress")}
                    label='Redemption Limit'
                    placeholder="Redemption Limit"
                    InputProps={false}
                    phone={false}
                    search={false}
                    //count={100}
                    style={styles.input}
                    onBlur={() => macAddress ? setdeviceError({ macAddressError: false }) : setdeviceError({ macAddressError: true })}
                    error={!macAddress && macAddressError ? true : false}
                  />
                </div>
              </Grid>

              <Grid item xs={12}>
              <div className="field_box">
                <BaseTextField
                  value={content}
                  // onChange={handleChange("name")}
                  InputProps={true}
                  style={{width:'100%'}}
                  onClick={() => setOpen(true)}
                  disabled={false}
                  onArrowClick={() => setOpen(true)}
                  label="Duration"

                />
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="BaseImgChipField" onClick={onChipInputClick}>
                  <BaseImgChip
                    chipData={chipData}
                    onInputClick={onInputClick}
                    onChipInputClick={onChipInputClick}
                    deleteChip={deleteChip}
                    label="Applicable to"
                  />
                </div>
              </Grid>

              <Grid item xs={12}>
              <div className="field_box">
                <BaseTextField
                  value={content}
                  // onChange={handleChange("name")}
                  InputProps={true}
                  style={{width:'100%'}}
                  onClick={() => setOpen(true)}
                  disabled={false}
                  onArrowClick={() => setOpen(true)}
                  label="Organization limit"

                />
                </div>
              </Grid>

              <Grid item xs={12}>
              <div className="field_box">
                <BaseTextField
                  value={content}
                  // onChange={handleChange("name")}
                  InputProps={true}
                  style={{width:'100%'}}
                  onClick={() => setOpen(true)}
                  disabled={false}
                  onArrowClick={() => setOpen(true)}
                  label="Valid From"

                />
                </div>
              </Grid>

              <Grid item xs={12}>
              <div className="field_box">
                <BaseTextField
                  value={content}
                  // onChange={handleChange("name")}
                  InputProps={true}
                  style={{width:'100%'}}
                  onClick={() => setOpen(true)}
                  disabled={false}
                  onArrowClick={() => setOpen(true)}
                  label="Valid Till"

                />
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="field_box">
                  <BaseSelect
                    value={status}
                    onChange={handleChange("status")}
                    label='Status'
                    placeholder="Status"
                    object={[
                      { value: "Available", label: "Available" },
                      { value: "Unavailable", label: "Unavailable" },
                      { value: "Defective", label: "Defective" },
                      { value: "Lost", label: "Lost" },
                      { value: "Locked", label: "Locked" },
                      { value: "Active", label: "Active" },
                      { value: "Shipped", label: "Shipped" },
                    ]}
                    style={styles.input}

                  />
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="form_button">
                  <BaseButton
                    onClick={() => props.history.push("/hardware")}
                    text="cancel"
                    textStyle={styles.txt}
                    disableElevation={false}
                    disableFocusRipple={false}
                    disableRipple={false}
                    variant='text'
                  />
                  <BaseButton
                    onClick={onSubmit}
                    type="submit"
                    text={'Add Now'}
                    disabled={disableBtn}
                    disableElevation={false}
                    disableFocusRipple={false}
                    disableRipple={false}
                    style={disableBtn ? styles.disableBtn : styles.inviteBtn}
                  />
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="field_box">

                </div>
              </Grid>

            </Grid>
          </form>


          <DrawerMain
          open={open}
          openHandler={closeDrawer}
          title="Campaign Type"
          onCancel={() => childRef.current.cancle()}
          onSave={() => childRef.current.save()}
          loading={false}
          >

          <DrawerListRadioButton objects={data1} getData={getData} selectedData={selectedData} />
        
          </DrawerMain>

          </div>

        }



        isFooter={true}
        footer={
          <FooterInner style={style} />
        }

        isWhiteBackground={true}


      />


    </Fragment>
  )

}

const mapStateToProps = state => {
  return {
    page: state.page,
    errors: state.errors
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onAddDevice: (device) => dispatch(actions.addDevice(device, ownProps)),
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(EditCampaign);