import {
	GET_ALL_DEVICES,
	GET_EDIT_DEVICE,
	DELETE_DEVICE,
	GET_NOT_ASSIGNED_DEVICES,
	GET_ORG_ASSIGNED_DEVICES
} from '../Actions/actionTypes';

const initialState = {
	devices: [],
	editDevice: {},
	notAssignedDevices: [],
	orgAssignedDevices: []

};

export default function (state = initialState, action) {
	switch (action.type) {
		case GET_ALL_DEVICES:
			return { ...state, devices: action.payload }
		case GET_EDIT_DEVICE:
			return { ...state, editDevice: action.payload }
		case DELETE_DEVICE:
			let devicesArr = state.devices.filter((device) => device._id !== action.payload)
			return { ...state, devices: devicesArr }
		case GET_NOT_ASSIGNED_DEVICES:
			return { ...state, notAssignedDevices: action.payload }
		case GET_ORG_ASSIGNED_DEVICES:
			return { ...state, orgAssignedDevices: action.payload }

		default:
			return state;
	}
}