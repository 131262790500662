import React, { Component } from "react";
import { Verify, ComponentOtpLockedCard  } from "@dclab/dc-react-ui-kit";
// import Logo from './DC_Logo.png';

import Logo from "../../assets/img/DC_Logo.png";
// import FooterLogo from "../../assets/img/DF_Logo_clr_2020.png";

import styles from './otp.module.css';


import Alert from '../../components/common/AlertAction';
import { connect } from 'react-redux';
import * as actions from '../../store/Actions/index';
import Spinner from '../../components/common/Spinner';
import { showMessage } from '../../store/Actions/pageActions';



class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {

      show: false,
      errors: {},
      loading: false,
      tempUser: {},
      responseData: '',

      code: "",
    };
  }


  static getDerivedStateFromProps(props, state) {

    let errors = props.errors;
    let page = props.page;
    let tempUser = props.tempUser ? props.tempUser : {};
    let responseData = props.responseData ? props.responseData : '';


    let stateChanged = false;
    let changedState = {};

    if (tempUser && JSON.stringify(state.tempUser) !== JSON.stringify(tempUser)) {
      changedState.tempUser = tempUser;
      stateChanged = true;
    }

    if (responseData && JSON.stringify(state.responseData) !== JSON.stringify(responseData)) {
      changedState.responseData = responseData;
      stateChanged = true;
    }

    if (errors && JSON.stringify(state.errors) !== JSON.stringify(errors)) {
      changedState.errors = errors;
      stateChanged = true;
    }

    if (page && JSON.stringify(state.loading) !== JSON.stringify(page.loading)) {
      changedState.loading = page.loading;
      stateChanged = true;
    }

    if (stateChanged) {
      return changedState;
    }


    return null;
  }

  showAlertHandler = () => {

    if (this.state.errors && this.state.errors.message) {
      this.props.onClearError();
    }
    else {
      this.setState({
        show: !this.state.show
      });
    }

  }

  // if(event.key === 'Enter'){

  // }


  click = () => {
    console.log("Into click Function");
    const { tempUser } = this.state;

    const formData = {
      code: this.state.code.toString(),
      userName: tempUser.userName,
      password: tempUser.password
    };
    this.props.onSubmit(formData, this.props.history);
  };

  resend = () => {
    this.props.onClearError();
    this.setState({
      code: "",
      show: false,
      errors: {},
    });
    console.log("Into Resend Function");
    const { tempUser } = this.state;
    this.props.onResend(tempUser.userName, tempUser.password, this.props.history);
    this.setState({show: true, errors: {} });
  };

   showAlertHandler = () => {
    this.props.onShowMessage("")
}

  setOtp = (code) => {
    this.setState({
      code,
    });
  };

  render() {

    let pageContent = '';
    // let message = 'Code sent successfully! ';
    // let type = 'success';

    let { errors, loading, tempUser, show, code } = this.state;

    console.log("Checking states: ", this.state);

    if (loading) {
      pageContent = <Spinner />
    } else if (errors && errors.message && errors.message.includes("Login Attempts Exceeded")) {
      pageContent = <ComponentOtpLockedCard src={Logo} tooAttempts={false} />
    } else {
      pageContent = <Verify
        name="verifyCode"
        disabled={code.toString().length !== 6 && true}
        title="Enter OTP"
        metaTitle={"Please enter a verification code send to your email address"}
        email={tempUser && tempUser.userName && tempUser.userName}
        btnText="Verify"
        forVerify={true}
        src={Logo}
        value={code}
        onChangeCode={(char) => this.setOtp(char)}
        onClickVerify={this.click}
        onClickResend={this.resend}
        hasErrored={errors && errors.message}
        errorStyle={{
          border: "1px solid #B00020",
        }}
        error={false}
      />
    }

    // if (errors && errors.message && errors.message.includes("Login Attempts Exceeded")) {
    //   message = errors.message;
    //   type = 'error';
    //   show = false;
    // } else if (errors && errors.message) {
    //   message = errors.message;
    //   type = 'error';
    //   show = true;    
    // }

    return (
      <div className="opt_container">
      <div className={styles.otpContainer} >
      {this.props.showMessage && <Alert message={this.props.showMessage} type={"success"} 
                click={this.showAlertHandler} show={true} />}
        {pageContent}
        {/* <Footer logo={FooterLogo} /> */}
      </div>
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    tempUser: state.auth.tempUser,
    responseData: state.auth.responseData,
    errors: state.errors,
    page: state.page,
    showMessage: state.page.showMessage,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: (formData, history) => dispatch(actions.varifyOTP(formData, history)),
    onResend: (userName, password, history) => dispatch(actions.authenticateUser(userName, password, false, history)),
    onClearError: () => dispatch(actions.clearErrors()),
    onShowMessage: (data) => dispatch(showMessage(data)) 

  }
};


export default connect(mapStateToProps, mapDispatchToProps)(Index);

