import React from 'react'
import { SimpleTable } from "@dclab/dc-react-ui-kit";
import Send from "../../assets/images/add-24px.svg";
import CreateIcon from "../../assets/images/create-24px.svg";
import DeleteIcon from "../../assets/images/delete_forever-24px.svg";
import "./hardwareDevice.css";
import { AlertDialogue } from "@dclab/dc-react-ui-kit";
import {useState} from 'react';
import { Fragment } from 'react';

function createData(...value) {
    return { ...value };
}

let columns = [
    { type: "number", name: "serial_number", label: "NO.", width:"auto"},
    // { type: "link", name: "invoice_number", label: "DEVICE ID", minWidth: 120 },
    { type: "link", name: "invoice_number", label: "DEVICE ID", width:"15%" },
    { type: "string", name: "orgName", label: "ORG", width:"20%" },
    { type: "string", name: "amount", label: "PRICE", width:"15%" },
    { type: "string", name: "name", label: "APP STREAM", width:"15%" },
    { type: "string", name: "amount", label: "REVENUE", width:"40%" },

    { type: "status", name: "status", label: "STATUS", width:"20%" },
    { type: "action", name: "action", label: "ACTION", width: "10%" },
];

const columns1 = (columns) =>
    columns.map((obj) => {
        // delete obj.id;
        // delete obj.name;
        return obj;
});

const value = ["Cupcake", "Donut"];

export default function HardwareDevicesTable(props) {
    const [open, setOpen] = useState(false);
    const [deleteId, setdeleteId] = useState("")
    
    const checkStatus = (status) => {
        status = status.toLowerCase();
        status = status === "live" ? "active" : status;
        if (status === 'available') {
          return "success"
        } else if (status === 'shipped') {
          return "discontinued"
        } else if (status === 'unavailable' || status === 'lost' || status === 'defective'){
          return "pause"
        } else if (status === 'locked'){
          return "failed"
        } else if (status === 'active'){
            return "active"
        } else{
          return "discontinued"
        }
    }

    let rows = props.devices && props.devices.length ? props.devices.map((device, i) => {
        return [
            { type: "id", id: device._id, value: i + 1 },
            {
                type: "link",
                name: "invoice_number",
                value: device.deviceId,
                url: `/hardware-details/${device._id}`,
            },
            // { type: "action", status: "", value: device.deviceId },
            { type: "string", name: "name", value: device?.orgId?.orgName ? device.orgId.orgName : "" },
            { type: "string", name: "amount", value: `$${device.price}` },
            { type: "string", name: "name", value: device.appStream },
            { type: "string", name: "amount", value: `$${device.revenue}` },
            { type: "status", status: checkStatus(device.status), value: device.status },
            { type: "action", status: "success", value: "See details" },

        ]
    }) : []

    let classProps = () => {
        const tempArray = [];
        columns.forEach((column, index) => {
            tempArray.push(`class${index}`);
        });
        return tempArray;
    };

    let changeClassProps = () => {
        let tempArray = classProps();
        tempArray[0] = "class15";
        return tempArray;
    };

    const rowFinder = (rows) =>
        rows.map((obj) => {
            let filtered = [];
            value.map((val) => {
                const filObj = obj.filter((item) => {
                    return item !== val;
                });
                console.log(filObj);
                // filtered.push(filObj);
                console.log(filtered);
            });
            // console.log(filtered);
            return filtered;
    });

    const click = (msg) => {
        console.log(msg);
    };

    const btnClick = () => {
        props.history.push('/add-hardware')
    };

    const editAction = (edit) => {
        props.history.push(`/edit-hardware/${edit[0].id}`)
        console.log(edit[0].id);
    };

    const handleConfirm = () => {
        if(deleteId) {
            props.deleteDevice(deleteId)
            setOpen(false)
            setdeleteId("")
        }
    };

    const deleteAction = (deleteData) => {
        setdeleteId(deleteData[0].id)
        setOpen(true)
    };

    const actionLink = (data) => {
        let id = data[0].id;
        props.history.push(`/hardware-details/${id}`)
    }
    
    return (
        <Fragment>
            <AlertDialogue
                title="Device Delete Confirmation"
                description="Are you sure you want to delete this device?"
                cancelText="Cancel"
                confirmText="Confirm"  
                open={open}
                handleCancel={() => setOpen(false)}
                handleClose={() => setOpen(false)}
                handleConfirm={() => handleConfirm()}
                styles={{
                    width:470,
                    marginLeft:800
                }}
            />
        <SimpleTable
            showHeader={true}
            showCol={false}
            title="Hardware Inventory"
            rows={rows}
            columns={columns1(columns)}
            colSpan={6}
            actionSendButton={true}
            classProps={changeClassProps()}
            onClick={(message, index) => click(message)}
            showFilter={false}
            filterText="Filter"
            showAdd={false}
            showSend={true}
            sendText={"Add New"}
            addText="Add Organization"
            donationsCount={10}
            noTitle=""
            noPara=""
            noBtnText=""
            onInviteClick={() => btnClick()}
            // editIcon={CreateIcon}
            // deleteIcon={DeleteIcon}
            showIconCol={false}
            // editAction={(edit) => editAction(edit)}
            // deleteAction={(deleteItem) => deleteAction(deleteItem)}
            showFooter={true}
            actionLink={(name) => actionLink(name)}
            iconTextSize={14}
            sendIcon={Send}
            onSendClick={() => btnClick()}
            sortColumnIndex={[6]}
        />
        </Fragment>

    )
}






