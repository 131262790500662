import React, { useState, Fragment, useEffect, forwardRef, useImperativeHandle } from 'react'
import { DrawerMain, BaseTextField, DrawerListRadioButton } from "@dclab/dc-react-ui-kit";
import "../products.css"
import { Grid } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';


const AppStatus = forwardRef((props, ref) => {
    const { integrationInfo, setstepDisabled } = props;
    const [open, setOpen] = React.useState(false);
    const [status, setstatus] = useState("")
    const [selectedData, setselectedData] = useState("")

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [])

    useEffect(() => {
        setstatus(integrationInfo?.status ?? "")
    }, [integrationInfo])

    useImperativeHandle(ref, () => ({
        onSubmit() {
            if (status) {
                props.onAddIntegrationInfo({ status })
            }
        }
    }));


    let onSubmit = (e) => {
        e.preventDefault();
        console.log("submit")
    }

    const data = [
        {
            id: 1,
            primary: "Available",
            secondary: "The product/app is available for organization to add.",
        },
        {
            id: 2,
            primary: "Coming Soon",
            secondary: "The product/app is available for organization to add.",
        },
        {
            id: 3,
            primary: "New",
            secondary: "New App/Integration. Status as New for 2/3 weeks",
        },

        {
            id: 4,
            primary: "Hidden",
            secondary: "Hide from fronted. Unavailable from console to add",
        },
    ];

    const getData = (value, secondary) => {
        console.log(value, secondary);
        setselectedData({
            id: 3,
            primary: value,
            secondary: secondary,
        })
    };


    const closeDrawer = () => {
        setOpen(!open);
    };

    const onCancel = () => {
        setOpen(false);
    };

    const onSave = () => {
        if (selectedData?.primary) {
            setstatus(selectedData.primary)
            setOpen(!open);
        }
    };



    let styles = {
        input: {
            height: '48px',
            marginTop: '0px',
            width: '100%',
            border: "#707070",
            cursor: "pointer"
        },
    }

    // let handleChange = (prop) => (event) => {
    //     setstatus(event.target.value);
    // }

    setstepDisabled(status ? false : true)

    return (
        <Fragment>
            <DrawerMain
                open={open}
                openHandler={closeDrawer}
                title="Select Status"
                onCancel={onCancel}
                onSave={onSave}
                loading={false}
                saveDisabled={selectedData?.primary ? false : true}
            >
                <DrawerListRadioButton objects={data} getData={getData} selectedData={selectedData} />
            </DrawerMain>

            <div className="formContainerHikmah bottomSpacing">
                <form onSubmit={onSubmit}>
                    <Grid container spacing={3}>

                        <Grid item xs={12}>
                            <h1 className="title_top" style={{ textAlign: "left", border:"none", padding:"0", }}>Integration Status</h1>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="">
                                <BaseTextField
                                    value={status}
                                    onClick={() => setOpen(true)}
                                    // onChange={handleChange("status")}
                                    label='Integration Status'
                                    placeholder="Integration Status"
                                    phone={false}
                                    search={false}
                                    style={styles.input}
                                    color="green"
                                    disabled={false}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">{">"}</InputAdornment>,
                                    }}
                                />
                            </div>
                        </Grid>

                    </Grid>
                </form>
            </div>
        </Fragment>
    )
})

export default AppStatus;