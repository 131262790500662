import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';
import Cookies from 'universal-cookie';

import {
    SET_ERRORS,
    SET_CURRENT_USER,
    CLEAR_CURRENT_USER,
    SET_TEMP_USER,
    SET_CURRENT_USER_DETAILS,
    GET_CURRENT_USER_DETAIL
} from './actionTypes';

import {
    setPageLoading,
    clearPageLoading,
    clearErrors,
    showMessage
} from './pageActions';

let backendServerURL = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
let routesPrefix = '/';

// Checking user authentication via getting userName and password
export const authenticateWithRememberMe = (userName, password, checked, history) => dispatch => {
    dispatch(setPageLoading());
    axios
        .post(
            backendServerURL + '/hikmah/auth/authenticateWithRememberMe',
            { userName, password, checked }
        )
        .then(res => {
            console.log("checking response in authenticateUser: ", res);
            history.push(`${routesPrefix}dashboard`)

            console.log("checking res into varifyOTP: ", res);
            console.log("checking Response DATA: ", res.data.body.data.token);
            const token = res && res.data && res.data.body && res.data.body.data && res.data.body.data.token && res.data.body.data.token;
            console.log("Checking TOKEN: ", token);
            localStorage.setItem('authToken', token);
            setAuthToken('deenconnect-elobyte');
            dispatch(setCurrentUser());
            history.push(`${routesPrefix}dashboard`)

        })
        .catch(err => {
            console.log("error: ", err);
            dispatch({ type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! " } })

        })
        .finally(() => dispatch(clearPageLoading()))
};

// Checking user authentication via getting userName and password
export const authenticateUser = (userName, password, checked, history) => dispatch => {
    dispatch(setPageLoading());

    axios
        .post(
            backendServerURL + '/hikmah/auth/authenticateWithPassword',
            { userName, password, checked }
        )
        .then(res => {
            // console.log("checking response in authenticateUser: ", res);
            // console.log("checking Response DATA: ", res.data);
            const token = res && res.data && res.data.body && res.data.body.data && res.data.body.data.token && res.data.body.data.token;
            //console.log("Checking TOKEN: ", token);
            //localStorage.setItem('authToken', token);
            //setAuthToken(token);
            //dispatch(setCurrentUser());
            if(history.location.pathname === "/otp") {
                dispatch(showMessage("Code sent successfully"));
            }

            if(res && res.data && res.data.body && res.data.body.data && res.data.body.data.token && res.data.body.data.token && checked) {
                localStorage.setItem('checkedToken', token);
                // const cookies = new Cookies();
                // cookies.set('rememberMe', token, { path: '/' });
            }

            dispatch({ type: SET_TEMP_USER, payload: { userName: userName, password: password }, responseData: '' });
            dispatch(clearErrors())
            
            history.push(`${routesPrefix}otp`)
        })
        .catch(err => {
            console.log("error: ", err);
            dispatch({ type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! " } })

        })
        .finally(() => dispatch(clearPageLoading()))
};


// Varifying otp
export const varifyOTP = (formData, history) => dispatch => {
    dispatch(setPageLoading());

    axios
        .post(
            backendServerURL + '/hikmah/auth/authenticate',
            formData
        )
        .then(res => {
            console.log("checking res into varifyOTP: ", res);
            console.log("checking Response DATA: ", res.data.body.data.token);
            const token = res && res.data && res.data.body && res.data.body.data && res.data.body.data.token && res.data.body.data.token;
            console.log("Checking TOKEN: ", token);
            localStorage.setItem('authToken', token);
            setAuthToken('deenconnect-elobyte');
            dispatch(setCurrentUser());
            dispatch(setCurrentUserDetails(res && res.data && res.data.body && res.data.body.data));
            dispatch(showMessage(""));
            let checkedToken = localStorage.getItem('checkedToken');
            if(checkedToken) {
                const cookies = new Cookies();
                cookies.set('rememberMe', checkedToken, { path: '/' });
                localStorage.removeItem('checkedToken');
            }
            dispatch(clearErrors())
            history.push(`${routesPrefix}dashboard`)

        })
        .catch(err => {
            console.log("error: ", err);
            dispatch({ type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! " } })

        })
        .finally(() => dispatch(clearPageLoading()))
};

export const getCurrentUserDetail = (id) => dispatch => {
    dispatch(setPageLoading());

    axios
        .get(backendServerURL + `/hikmah/auth/getCurrentUser`,            
        {
            headers: {
                'token': localStorage.authToken
            }
        })
        .then(res => {
            // console.log("Checking Result org: ", res.data);
            const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
            dispatch(clearErrors());
            if (data) {
                dispatch({ type: GET_CURRENT_USER_DETAIL, payload: data });
            }
        })
        .catch(err => {
            console.log("error: ", err && err.response && err.response.data && err.response.data);
            dispatch({ type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! " } })

        })
        .finally(() => dispatch(clearPageLoading()))
};

export const editProfile = (id, user, ownProps) => dispatch => {
    dispatch(setPageLoading());

        axios.put(`${process.env.REACT_APP_API_URL}/hikmah/auth/updateProfile/${id}`, user, {
            headers: {
                'token': localStorage.authToken
            }
        })
        .then(res => {
            ownProps.history.push("/admin-profile")
            // const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
            dispatch(showMessage("Profile Updated Successfully!"));
            dispatch(clearErrors());
        })
        .catch(err => {
            console.log("error: ", err)
            dispatch({ type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! " } })
        })
        .finally(() => dispatch(clearPageLoading()))
};

// Checking user authentication via getting userName and password
export const changePassword = (userName, oldPassword, newPassword, history) => dispatch => {
    dispatch(setPageLoading());

    console.log("user", userName, oldPassword, newPassword);

    axios
        .post(
            backendServerURL + '/hikmah/auth/changePassword',
            { userName, oldPassword, newPassword }
        )
        .then(res => {
            const data = res && res.data && res.data.body && res.data.body.data;
            dispatch(showMessage("Password changed successfully"));

            dispatch(clearErrors())
            history.push(`${routesPrefix}edit-profile`)
        })
        .catch(err => {
            console.log("error: ", err);
            dispatch({ type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! " } })

        })
        .finally(() => dispatch(clearPageLoading()))
};




// Set logged in user (Verified)
export const setCurrentUser = () => {
    return {
        type: SET_CURRENT_USER
    };
};

export const setCurrentUserDetails = (data) => {
    return {
        type: SET_CURRENT_USER_DETAILS,
        payload: data
    };
};


export const clearCurrentUser = () => {
    return {
        type: CLEAR_CURRENT_USER
    };
};


// Log user out (Verified)
export const logoutUser = (history, unsetCookie) => dispatch => {
    console.log("History in logoutUser: ");
    if(unsetCookie) {
        const cookies = new Cookies();
        cookies.remove('rememberMe')
        localStorage.removeItem("userName");
        localStorage.removeItem("role");
        localStorage.removeItem("image");
    }
    setAuthToken(false);
    localStorage.removeItem('authToken');
    dispatch(clearErrors())
    dispatch(clearCurrentUser());
    // console.log("History is: ", history);
    history.push(routesPrefix + 'login');
};



export const sendPasswordRecoveryLink = (userData, history) => dispatch => {
    dispatch(setPageLoading());

    axios
        .post(
            backendServerURL + '/api/users/recover-password-email',
            userData
        )
        .then(res => {
            if (res.data.success) {
            }
        })
        .catch(err => dispatch({ type: SET_ERRORS, payload: err.response.data }))
        .finally(() => dispatch(clearPageLoading()));
};


export const resetUserPassword = (inputData, verificationToken, history) => dispatch => {
    dispatch(setPageLoading());

    axios
        .post(
            backendServerURL + '/api/users/reset-user-password',
            {
                token: verificationToken,
                passwordData: inputData
            }
        )
        .then(res => {
            history.push(routesPrefix + 'login?reset=true');
        })
        .catch(err => dispatch({ type: SET_ERRORS, payload: err.response.data }))
        .finally(() => dispatch(clearPageLoading()));
};