import React, {useState} from 'react'
import { SimpleTable, EmptyTable , MasterLayoutMiddle, FooterInner, AlertDialogue} from "@dclab/dc-react-ui-kit";
import { withRouter } from 'react-router-dom';

import Send from "../../assets/images/add-24px.svg";
import CreateIcon from "../../assets/images/create-24px.svg";
import DeleteIcon from "../../assets/images/delete_forever-24px.svg";
import "../HardwareDevices/hardwareDevice.css";

function createData(...value) {
    return { ...value };
}

let columns = [
    { type: "number", name: "serial_number", label: "NO.", width:"auto" },
    { type: "link", name: "invoice_number", label: "ORDER NO", width:"20%"},
    { type: "string", name: "deviceName", label: "Name", width:"20%" },
    { type: "link", name: "invoice_number", label: "DEVICE ID", minWidth: "20%" },
    // { type: "action", name: "action", label: "DEVICE ID", width:"20%" },
    { type: "string", name: "name", label: "APP STREAM", width:"20%" },
    { type: "string", name: "amount", label: "REVENUE", width:"10%" },
    { type: "string", name: "date/time", label: "LAST ACTIVE", width:"20%" },
    { type: "status", name: "status", label: "STATUS", width:"15%" },
    { type: "action", name: "actionIcons", label: "ACTION", width:"15%" },
];


const columns1 = (columns) =>
    columns.map((obj) => {
        return obj;
    });
const value = ["Cupcake", "Donut"];



function AssignHardwareTable(props) {
    const [open, setOpen] = useState(false)
    const [deleteDeviceId, setdeleteDeviceId] = useState("")

    const checkStatus = (status) => {
        status = status.toLowerCase();
        if (status === 'live') {
            return "success"
          } else if (status === 'pending') {
            return "discontinued"
          } else if (status === 'paused'){
            return "pause"
          } else if (status === 'locked' || status === 'inactive'){
            return "failed"
          } else if (status === 'active'){
              return "active"
          } else{
            return "discontinued"
          }
     }

    let rows = props.devices && props.devices.length ? props.devices.map((device, i) => {
        console.log("Device Detail is: ", device);
        return [
            { type: "id", id: device._id, value: i + 1 },
            {
                type: "link", name: "invoice_number", value: "1234DG8MIS", url: "", linkType: "internal",
            },
            { type: "string", name: "name", value: device.name ? device.name : "No Name" },
            {
                type: "link", name: "invoice_number", value: device.deviceId, url: `/hardware-details/${device._id}`,
                linkType: "external"
            },
            // { type: "action", status: "", value: device.deviceId },
            { type: "string", name: "name", value: device.appStream },
            { type: "string", name: "amount", value: `$${device.revenue}` },
            { type: "string", name: "date/time", value: device.lastActive },

            { type: "status", status: checkStatus(device.status), value: device.status },
        ]
    }) : []

    let classProps = () => {
        const tempArray = [];
        columns.forEach((column, index) => {
            tempArray.push(`class${index}`);
        });
        return tempArray;
    };

    let changeClassProps = () => {
        let tempArray = classProps();
        tempArray[0] = "class15";
        return tempArray;
    };

    const rowFinder = (rows) =>
        rows.map((obj) => {
            let filtered = [];
            value.map((val) => {
                const filObj = obj.filter((item) => {
                    return item !== val;
                });
                console.log(filObj);
                // filtered.push(filObj);
                console.log(filtered);
            });
            // console.log(filtered);
            return filtered;
        });

    const click = (msg) => {
        console.log(msg);
    };

    const btnClick = () => {
        props.history.push(`/select-hardware/${props.match.params.id}`)
    };
    // console.log(rowFinder(rows));
    // console.log(value);

    // <SimpleTable rows={rowFinder(rows)} columns={columns1(columns)} colSpan={6} />;

    const editAction = (edit) => {
        props.history.push(`/edit-hardware/${edit[0].id}`)
        console.log(edit[0].id);
    };

    const deleteAction = (deleteData) => {
        setOpen(true);
        setdeleteDeviceId(deleteData[0].id);
        //props.deleteDevice(deleteData[0].id)
    };

    const actionLink = (data) => {
        // console.log("checking Link data: ", data);
        let id = data[0].id;
        console.log("id is: ", id);
        props.history.push(`/hardware-details/${id}`)
    }

    const onAddClick = () => {
        props.history.push(`/select-hardware/${props.match.params.id}`)
    }

    const handleConfirm = () => {
        if(deleteDeviceId) {
            props.deleteDevice(deleteDeviceId)
            setOpen(false);
            setdeleteDeviceId()
            //props.history.push("/hardware")
        }
    };

    const onLinkClick = (val) => {
        console.log(val)
        // props.history.push(`/select-hardware/${props.match.params.id}`)
    }

    const style = {
        width:'100%',
      };

    return (
        <div className="hardware-inventory-table">
            <MasterLayoutMiddle

            content={			
                <div>
                    <AlertDialogue
                        title="Remove Hardware?"
                        description="Are you sure you want to remove this hardware from"
                        description2="this organization?"
                        cancelText="Cancel"
                        confirmText="Confirm"  
                        open={open}
                        handleCancel={() => setOpen(false)}
                        handleClose={() => setOpen(false)}
                        handleConfirm={() => handleConfirm()}
                        styles={{
                            width:470,
                            marginLeft:800
                        }}
                    />

                    {!props.loading ? props.devices && props.devices.length ? <SimpleTable
                    showHeader={true}
                    showCol={false}
                    title="Hardware"
                    rows={rows}
                    columns={columns1(columns)}
                    colSpan={6}
                    actionSendButton={true}
                    classProps={changeClassProps()}
                    onClick={(message, index) => click(message)}
                    showFilter={false}
                    filterText="Filter"
                    showAdd={false}
                    showSend={true}
                    sendText={"Add"}
                    addText="Add Organization"
                    donationsCount={10}
                    noTitle=""
                    noPara="Add donation categories so that people can donate for a specific cause."
                    noBtnText=""
                    onInviteClick={() => btnClick()}
                    //editIcon={CreateIcon}
                    deleteIcon={DeleteIcon}
                    showIconCol={true}
                    //editAction={(edit) => editAction(edit)}
                    deleteAction={(deleteItem) => deleteAction(deleteItem)}
                    showFooter={true}
                    actionLink={(name) => actionLink(name)}
                    iconTextSize={14}
                    sendIcon={Send}
                    onSendClick={() => btnClick()}
                    /> : <EmptyTable
                    title="Hardware"
                    noTitle="No Devices Assigned"
                    noPara=""
                    onAddClick={onAddClick}
                    onLinkClick={(val) => console.log(val)}
                    /> : null}                     
                </div>
                }

            isFooter={true}
            footer={
            <FooterInner style={style}/>
            }
            isWhiteBackground={true}
            />
        </div>
    )
}

export default withRouter(AssignHardwareTable)