import React, { useState, useEffect } from 'react'

import { BaseTabs, MasterLayoutMiddle, FooterInner, AlertDialogue} from "@dclab/dc-react-ui-kit";

import TabContent1 from './tabContent1';
import TabContent2 from './tabContent2';

import { connect } from 'react-redux';
import * as actions from '../../store/Actions/index';
import Spinner from '../../components/common/Spinner';
import TopCardsData from './TopCardsData';
import './products.css';
import Grid from '@material-ui/core/Grid';


function AdminProducts(props) {
  // console.log("productCardData", props.productCardData)
  // console.log("integrations", props.integrations)

  const [applications, setapplications] = useState([])
  const [integrations, setintegrations] = useState([])
  // const [productCardData, setproductCardData] = useState({})
  const [value, setvalue] = useState(false)
  const [loading, setloading] = useState(false)
  
  const [appModal, setappModal] = useState(false);
  const [intModal, setintModal] = useState(false);
  const [appDeleteId, setappDeleteId] = useState("")
  const [intDeleteId, setintDeleteId] = useState("")

  const handleApplicationConfirm = (id) => {
    if(id) {
      console.log('confirm', id)
      props.onDeleteApplication(id);
      setappModal(false)
      setappDeleteId("")
    }
  };

  const handleIntegrationConfirm = (id) => {
    if(id) {
      console.log('confirm', id)
      props.onDeleteIntegration(id);
      setintModal(false)
      setintDeleteId("")
    }
  };

  const onClick = (x, y) => {
    console.log('checking onClick function: ', x)
    console.log('checking onClick function Y:', y)
  }

  const deleteAction = (data) => {
    console.log("delete", data[0].id)
    setappModal(true)
    setappDeleteId(data[0].id)
  }

  const deleteIntegration = (data) => {
    console.log("delete", data[0].id)
    setintModal(true)
    setintDeleteId(data[0].id)
  }

  const handleChange = (event, newValue) => {
    setvalue(newValue);
  };

  useEffect(() => {
    props.onClearError();
    props.onClearAppInfo();
    props.onClearIntegrationInfo();
    props.onGetProductCardData();
    props.onGetApplications();
    props.onGetIntegrations();
  }, [])

  useEffect(() => {
    setapplications(props.applications)
  }, [props.applications])

  useEffect(() => {
    setintegrations(props.integrations)
  }, [props.integrations])

  useEffect(() => {
    if(props.location?.state?.step === "integration") {
      setvalue(1)
    } else {
      setvalue(0)
    }
}, [])

  // useEffect(() => {
  //   setproductCardData(props.productCardData)
  // }, [props.productCardData])

    let pageContent = '';
  
    if (loading) {
      pageContent = <Spinner />
    }

    const sortIntegrations = (obj) => {
      props.onSortIntegrations(obj);
    }

    const sortApplications = (obj) => {
      props.onSortApplications(obj);
    }
    

    let object = [
      {
        label: "CORE APPLICATIONS",
        item: <TabContent1 applications={applications} history={props.history} deleteAction={deleteAction} sortApplications = {sortApplications}/>
      },
      {
        label: "INTEGRATIONS",
        item: <TabContent2 integrations={integrations} applications={applications} history={props.history} deleteIntegration={deleteIntegration} sortIntegrations={sortIntegrations} />,
      }
    ];

    const style = {
      width:'100%',
    };

    return (
     <div>
          <AlertDialogue
            title="Delete Confirmation"
            description="Are you sure you want to delete this application?"
            cancelText="Cancel"
            confirmText="Confirm"  
            open={appModal}
            handleCancel={() => setappModal(false)}
            handleClose={() => setappModal(false)}
            handleConfirm={() => handleApplicationConfirm(appDeleteId)}
            styles={{
              width:470,
              marginLeft:800
            }}
          />
          <AlertDialogue
            title="Delete Confirmation"
            description="Are you sure you want to delete this integration?"
            cancelText="Cancel"
            confirmText="Confirm"  
            open={intModal}
            handleCancel={() => setintModal(false)}
            handleClose={() => setintModal(false)}
            handleConfirm={() => handleIntegrationConfirm(intDeleteId)}
            styles={{
              width:470,
              marginLeft:800
            }}
          />
          <MasterLayoutMiddle
            content={
              <div>
                  <Grid container spacing={4}>	      
                    <Grid item xs={12}> 
                        <TopCardsData productCardData={props.productCardData} />
                    </Grid>

                    <Grid item xs={12}> 
                      <BaseTabs
                          title=""
                          object={object}
                          indicatorColor="#7266fb"
                          onClick={(x, y) => onClick(x, y)}
                          onChange={(event, newValue) => handleChange(event, newValue)}
                          value={value}
                        />
                      {pageContent}
                    </Grid>

                  </Grid>
              </div>
            }

          isFooter={true}
          footer={
          <FooterInner style={style}/>
          }
          isWhiteBackground={false}
          />
      </div>
    )
  }

const mapStateToProps = state => {
  return {
    integrations: state.products.integrations,
    applications: state.products.applications,
    productCardData: state.products.productCardData,
    page: state.page,
    errors: state.errors,
    showMessage: state.errors.showMessage
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onGetIntegrations: () => dispatch(actions.getAllIntegrations()),
    onGetApplications: () => dispatch(actions.getAllApplications()),
    onDeleteApplication: (id) => dispatch(actions.deleteApplication(id)),
    onDeleteIntegration: (id) => dispatch(actions.deleteIntegration(id)),
    onClearError: () => dispatch(actions.clearErrors()),
    onClearAppInfo: () => dispatch(actions.clearAppInfo()),
    onClearIntegrationInfo: () => dispatch(actions.clearIntegrationInfo()),
    onGetProductCardData: () => dispatch(actions.getProductCardData()),
    onSortIntegrations: (data) => dispatch(actions.sortIntegrations(data)),
    onSortApplications: (data) => dispatch(actions.sortApplications(data))

  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminProducts);


