import axios from "axios";

const backendServerURL = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;


export function getApps(){
  return new Promise((resolve, reject) => {
    axios
      .get(`${backendServerURL}/hikmah/getAllDcApplications`,{
        headers:{
          'token': localStorage.authToken
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject([]);
      });
  });
}

export function getIntegrations(token, compatibleApp){
  return new Promise((resolve, reject) => {
    axios
      .get(`${backendServerURL}/hikmah/getAllDcIntegrations`,{
        headers:{
          'token': localStorage.authToken
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject([]);
      });
  });
}

export function getPlans(id){
  return new Promise((resolve, reject) => {
    axios
      .get(`${backendServerURL}/planSubscription/getOrganizationPlan/${id}`,{
        headers:{
          'token': localStorage.authToken
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject([]);
      });
  });
}

export function getPlanSubscriptions(id){
  return new Promise((resolve, reject) => {
    axios
      .get(`${backendServerURL}/planSubscription/getPlanSubscriptions/${id}`,{
        headers:{
          'token': localStorage.authToken
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject([]);
      });
  });
}

export function getCurrentUserDetail(token) {
  return new Promise((resolve, reject) => {
      axios
          .get(backendServerURL + `/auth/getCurrentUser`, {
              headers: {
                  token
              },
          })
          .then((res) => {
              const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
              if (data) {
                  resolve(data);
              }
          })
          .catch((error) => {
              reject(error);
          });
  });
}