import React from 'react';
import { SimpleTable, DrawerMain, BaseSelect, FormSwitch  } from "@dclab/dc-react-ui-kit";
import CreateIcon from "../../../assets/img/create-24px.svg";
import DeleteIcon from "../../../assets/img/delete_forever-24px.svg";
import { Fragment, useState, useEffect, forwardRef, useImperativeHandle  } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import { TextField } from '@material-ui/core';
import "../products.css";


const Hardware = forwardRef((props, ref) => {
  const {applicationInfo, setstepDisabled} = props;

  const [open, setOpen] = React.useState(false);
  const [editHardware, seteditHardware] = useState(false)
  const [hardwares, sethardwares] = useState({
    name: "", setupCost: 0, price: 0, billingPeriod: "", shipping: "Required",
  })

  const [values, setvalues] = useState(false)
  const [selectedHardwares, setselectedHardwares] = useState([]);

  useEffect(() => {
    setselectedHardwares(applicationInfo?.hardware?.length ? applicationInfo.hardware : [])
    setvalues(applicationInfo?.showHardware === true ? true : false)
 }, [applicationInfo])

 const formDataFunc = () => ({
  hardware: selectedHardwares,
  showHardware: values
 });

 useImperativeHandle(ref, () => ({
  onSubmit() {    
      // if (selectedHardwares?.length) {
          let formData = formDataFunc();
          console.log("submit hardware", formData)
          props.onAddAppInfo(formData)
      // }
  }
}));

  let columns = [
    { type: "string", name: "name", label: "HARDWARE NAME", width:"40%" },
    { type: "string", name: "name", label: "PRICE", width:"15%" },
    { type: "string", name: "price", label: "ONE TIME SETUP COST", width:"40%" },
    { type: "action", name: "actionIcons", label: "ACTION", width:"20%"  },
  ];

  let classProps = () => {
    const tempArray = [];
    columns.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };

  const changeClassProps = () => {
    let tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };

  const columns1 = (columns) =>
    columns.map((obj) => {
      return obj;
    });

  const editAction = (data) => {
    console.log("edit", data)
    let id = data[0].id;
    let hardware = selectedHardwares.find((el, i) => i === id);
    sethardwares({id: id, ...hardware});
    setOpen(true)
    seteditHardware(true)
  }

  const deleteAction = (data) => {
    let id = data[0].id;
    let prevHardwares = [...selectedHardwares];
    prevHardwares.splice(id, 1);
    setselectedHardwares([...prevHardwares]);
  }

  const closeDrawer = () => {
    setOpen(!open);
  };
  
  const onCancel = () => {
    setOpen(false);
    seteditHardware(false)
  };
  

  const onSave = () => {
    const {name, setupCost, shipping, price, billingPeriod } = hardwares;
    if(editHardware && hardwares.id != undefined) {
      let prevHardwares = [...selectedHardwares];
      prevHardwares[hardwares.id] = { id: undefined, ...hardwares};
      setselectedHardwares([...prevHardwares]);
      /* console.log("setselectedHardwares: ", selectedHardwares)
      props.setChangeData(formDataFunc()); */
      seteditHardware(false)
      setOpen(false);
      sethardwares({
        name: "", setupCost: 0, price: 0, billingPeriod: "", shipping: "Required",
      });
    } else {
      if(name && shipping && billingPeriod && price) {
        let prevHardwares = [...selectedHardwares];
        hardwares.price = parseInt(hardwares.price)
        prevHardwares.push(hardwares);
        setselectedHardwares([...prevHardwares]);
        //props.setChangeData(formDataFunc());
        setOpen(false);
        sethardwares({
          name: "", setupCost: 0, price: 0, billingPeriod: "", shipping: "Required",
        });
      }
    }
  };

  let styles = {
    input: {
      // height: '48px',
      // marginTop: '20px',
      width: '100%',
      border: "#707070",
      marginLeft: 0
  },
  }

  let handleChange = (prop) => (event) => {
      if((prop === "price" || prop === "setupCost") && event.target.value && event.target.value <= 0){
        return
      }
      sethardwares({ ...hardwares, [prop]: event.target.value });
      props.setChanges(true);
  }
  const {name, setupCost, shipping, price, billingPeriod } = hardwares;

  let rows = [];
  if (selectedHardwares && selectedHardwares.length) {
    rows = selectedHardwares.map((app, index) => {
      return (
        [
          { type: "string", name: "category", value: app.name },
          { type: "string", name: "category", value: `$${app.price}${app.billingPeriod ? "/" + app.billingPeriod : ""}` },
          { type: "string", name: "category", value: `$${app.setupCost}` }
        ]
      )
    });
  }


  const handleSwitchChange = (event) => {
    setvalues(event)
    console.log(event, 'swuitch')
  };

  setstepDisabled(!values ? false : selectedHardwares?.length ? false : true)


  return (<Fragment> 
      <DrawerMain
            open={open}
            openHandler={closeDrawer}
            title="Add Hardware"
            onCancel={onCancel}
            onSave={onSave}
            loading={false}
            saveDisabled={name && shipping && billingPeriod && price ? false : true}
        >

          <div className="field_box">
            <BaseSelect
                value={name}
                onChange={handleChange("name")}
                object={[
                  {
                    value: "DEENKIOSK Player",
                    label: "DEENKIOSK Player",
                  },
                  {
                      value: "DEENSCREEN Player",
                      label: "DEENSCREEN Player",
                  },
                ]}
                style={{width: "100%", marginLeft: 0}}
                helperText=""
                label='Select Hardware'
                placeholder="Select Hardware"
                style={styles.input}
            />
          </div>
       
          <div className="field_box">
            <TextField
                value={price}
                onChange={handleChange("price")}
                label='Price'
                phone={false}
                search={false}
                style={styles.input}
                color="green"
                type="number"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
            />
          </div>

          <div className="field_box">
            <BaseSelect
                value={billingPeriod}
                onChange={handleChange("billingPeriod")}
                object={[
                    {
                        value: "Monthly",
                        label: "Monthly",
                    },
                    {
                        value: "Annually",
                        label: "Annually",
                    }
                ]}
                style={styles.input}
                helperText=""
                label='Billing Cycle'
                placeholder="Billing Cycle"
            />
          </div>

          <div className="field_box">
            <TextField
                value={setupCost}
                onChange={handleChange("setupCost")}
                label='One Time Setup Cost'
                placeholder="One Time Setup Cost"
                phone={false}
                search={false}
                style={styles.input}
                color="green"
                type="number"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
            />
          </div>

          <div className="field_box">
            <BaseSelect
                value={shipping}
                onChange={handleChange("shipping")}
                object={[
                    {
                        value: "Required",
                        label: "Required",
                    },
                    {
                        value: "Not Required",
                        label: "Not Required",
                    }
                ]}
                helperText=""
                label="Shipping"
                placeholder="Shipping"
                style={styles.input}
            />
          </div>
       
      </DrawerMain>
    
    <div className="hardware-switch">
    <FormSwitch
      headline='Hardware'
      body=""
      values={values}
      handleSwitchChange={(e) => handleSwitchChange(e)}
    />
    </div>
    
    {values && <SimpleTable
    title="Hardware"
    showHeader={true}
    rows={rows}
    columns={columns1(columns)}
    colSpan={6}
    actionSendButton={true}
    classProps={changeClassProps()}
    showFilter={false}
    showAdd={true}
    addText="ADD NEW"
    donationsCount={rows?.length ? 1 : 0}
    onAddClick={() => setOpen(true)}
    noTitle="No Hardwares"
    noPara="Add new hardware for the application."
    noBtnText="Add Hardware"
    onInviteClick={() => setOpen(true)}
    showFooter={rows?.length > 10 ? true : false}
    rowsPerPage={10}
    iconTextSize={14}
    editIcon={CreateIcon}
    deleteIcon={DeleteIcon}
    showIconCol={true}
    editAction={(data) => editAction(data)}
    deleteAction = { (data) => deleteAction(data)}
   />}
  </Fragment>
  )
})


export default Hardware;