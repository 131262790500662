import React, { useState, Fragment, useEffect } from 'react'
import { DrawerMain, BaseButton, BaseTextField, BaseTextFieldPassword,DrawerListRadioButton , BaseImgChip, AlertAction, MasterLayoutMiddle, FooterInner, FormSwitch } from "@dclab/dc-react-ui-kit";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import axios from 'axios'
import { connect } from 'react-redux';
import * as actions from '../../store/Actions/index';
import Spinner from '../../components/common/Spinner';
import Grid from '@material-ui/core/Grid';


function VerifyFinanceMemberEmail(props) {
    console.log(props)
    const [credentials, setcredentials] = useState({ code: "", userName: props.teamMemberEmail })

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [])

    
    useEffect(() => {
        setcredentials({...credentials, userName: props.teamMemberEmail })
    }, [props.teamMemberEmail])

    let handleChange = (prop) => (event) => {
        setcredentials({ ...credentials, [prop]: event.target.value });
    }

    let onSubmit = () => {
        if (credentials.code.length === 6  && credentials.userName) {
            props.onVerifyMemberEmail(credentials)
        }
    }

    let styles = {
        txt: {
            color: '#4D4D4D ',
            letterSpacing: '1.26px',
            textAlign: 'left',
            fontFamily: 'Roboto',
            fontWeight: 500,
            fontSize: '15px'
        },
        backBtn: {
            backgroundColor: 'white',
            borderRadius: '18px',
            color: '#4D4D4D',
            textAlign: 'left',
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '15px',
            width: '86px',
            height: '36px'
        },
        routes: {
            marginLeft: '70px',
            width: '90%',
            padding: '30px 0',
            display: 'flex'
        },
        input: {
            height: '48px',
            marginTop: '20px',
            width: '100%',
            border: "#707070",
        },
        inviteBtn: {
            textAlign: 'center',
            color: 'white',
            letterSpacing: '1.26px',
            height: '45px',
            background: '#2490F9 0% 0% no-repeat padding-box',
            borderRadius: '23px',
            padding: '14px 45px'
        },
        disableBtn: {
            textAlign: 'center',
            color: '#222',
            letterSpacing: '1.26px',
            height: '45px',
            background: '#eee',
            borderRadius: '23px',
            padding: '14px 45px'
        },
        cancelBtn: {
            textAlign: 'center',
            color: 'black',
            letterSpacing: '1.26px',
            height: '45px',
            borderRadius: '23px',
            backgroundColor: 'white',
            padding: '14px 45px'
        }
    }

    const style = {
        width:'100%',
      };

      console.log("credentials",  credentials)
    const { code, userName } = credentials;
    let disableBtn = code.length === 6 && userName ? false : true;    

    return (
        <Fragment>

            <MasterLayoutMiddle
                header={
                    <div>
                    <div className="backBtnFrom">
                    <BaseButton
                        onClick={() => props.history.push("/add-finance-team-member")}
                        text="back"
                        textStyle={styles.txt}
                        disableElevation={false}
                        disableFocusRipple={false}
                        disableRipple={false}
                        style={styles.backBtn}
                        startIcon={<ChevronLeftIcon />}
                    />
                    </div>
                    {props.page && props.page.loading && <Spinner />}
                </div>
                }
                isForm={true}
                formData ={
                    <div>
                                            {
                    props.errors && props.errors.error && <AlertAction
                    alert={props.errors.message}
                    alertType={"error"}
                    //   onClick={this.closeAlert}
                    />
                    }
                    <Grid container spacing={3}>

                        <Grid item xs={12}> 
                            <h1 className="title_top">Add Team Member</h1>	 
                        </Grid>	      

                        <Grid item xs={12}> 
                            <div className="field_box">
                            <BaseTextField
                                value={code}
                                onChange={handleChange("code")}
                                label='Enter Code'
                                placeholder="Enter Code"
                                InputProps={false}
                                phone={false}
                                search={false}
                                // count={100}
                                style={styles.input}
                                // onBlur={() => code ? setcredentialsError({codeError: false }) : setcredentialsError({ codeError: true })}
                                // error={!code && codeError ? true : false}
                                helperText="Enter the 6 digit code sent to the email address"
                            />
                            </div>
                        </Grid>

                        <Grid item xs={12}> 
                            <div className="form_button">
                            <BaseButton
                            onClick={() => props.history.push("team-members")}
                            text="CANCEL"
                            textStyle={styles.txt}
                            disableElevation={false}
                            disableFocusRipple={false}
                            disableRipple={false}
                            variant='text'
                        />

                        <BaseButton
                            onClick={onSubmit}
                            text={'CONFIRM'}
                            disabled={disableBtn}
                            disableElevation={false}
                            disableFocusRipple={false}
                            disableRipple={false}
                            style={disableBtn ? styles.disableBtn : styles.inviteBtn}
                        />
                            </div>
                        </Grid>

                        <Grid item xs={12}> 
                            <div className="field_box">

                            </div>
                        </Grid>

                        </Grid>
                    </div>
                }

                isFooter={true}
                footer={
                <FooterInner style={style}/>
                }
                isWhiteBackground={true}
                />
        </Fragment>
    )

}

const mapStateToProps = state => {
    return {
          page: state.page,
          errors: state.errors,
          teamMemberEmail: state.teamMembers.verifyTeamMemberEmail,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onVerifyMemberEmail: (credentials) => dispatch(actions.verifyMemberEmail(credentials, ownProps)),
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(VerifyFinanceMemberEmail);
