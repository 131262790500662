import React, { useState, useEffect } from 'react';
import { SimpleTable } from "@dclab/dc-react-ui-kit";
import CreateIcon from "../../assets/img/create-24px.svg";
import DeleteIcon from "../../assets/img/delete_forever-24px.svg";
import Handle from '../../assets/img/more_vert-24px.svg';


export default function TabContent(props) {
  const [integrations, setintegrations] = useState([])

  useEffect(() => {
    if(props.integrations?.length) {
      setintegrations(props.integrations.sort((a, b) => a.order - b.order));
    }
  }, [props.integrations])

  let columns = [
    { id: 'drag', label: ' ', width: 'auto' },
    { type: "number", name: "serial_number", label: "NO.", width:"auto" },
    { type: "url", name: "single_pic", label: "LOGO", width: 'auto'},
    { type: "string", name: "name", label: "NAME", width:"45%" },
    { type: "string", name: "orgtype", label: "PRICE/PLAN", width:"65%" },
    { type: "status", name: "status", label: "STATUS", width:"20%"  },
    { type: "action", name: "actionIcons", label: "ACTION", width:"20%"  },
  ];

  let classProps = () => {
    const tempArray = [];
    columns.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };

  const changeClassProps = () => {
    let tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };

  const columns1 = (columns) =>
    columns.map((obj) => {
      return obj;
    });

    const checkStatus = (status) => {
      status = status ? status.toLowerCase() : "";
      if (status === 'available' || status === 'new') {
        return "success"
      } else if (status === 'coming soon'){
        return "discontinued"
      } else if (status === 'hidden' || status === 'not available'){
        return "failed"
      } else{
        return "discontinued"
      }
   }
    
  let rows = [];
  if (integrations && integrations.length) {
    rows = integrations.map((app, index) => {
      let name = app?.name ?? "";
      let image = app?.imageMetaData?.imageUrl ?? ""
      return (
        [
          { type: "id", id: app._id, name: 'serial_number', value: index + 1},
          { type: "url", name: "userPic", src: image, userInitials: name  },
          { type: "string", name: "category", value: app.name },
          { type: "string", name: "date", value: app?.pricing?.pricingType },
          { type: "status", status: checkStatus(app.status), value: app.status },
        ]
      )
    });
  }

  const click = (msg) => {
    console.log(msg);
  };

  const btnClick = () => {
    console.log('invite');
  }

  const actionLinkHandler = (data) => {
    let id = data[0].id;
    props.history.push(`/organization-detail-${id}`)
  }

  const editAction = (data) => {
    console.log("edit", data[0].id)
    props.history.push(`/edit-integration/${data[0].id}`)
  }

  const sortData = async (data) => {
      console.log("data", data)
      if(data?.length) {
        const sortedData = data.sort((a, b) => a.order - b.order)
        setintegrations(sortedData);
  
        let obj = {
          sortedList: data.map((el) => {
            return { _id: el._id, order: el.order }
          })
        }
        const res = await props.sortIntegrations(obj);
        console.log("sortRes", res)
    }
  }

  return (<SimpleTable
    title="Integrations"
    showHeader={true}
    rows={rows}
    data={props.integrations}
    columns={columns1(columns)}
    colSpan={6}
    actionSendButton={true}
    actionLink={(data) => actionLinkHandler(data)}
    classProps={changeClassProps()}
    onClick={(message, index) => click(message)}
    showFilter={false}
    showAdd={true}
    addText="ADD NEW"
    donationsCount={10}
    onAddClick={() => props.history.push('/add-integration')}
    noTitle="No recent donations"
    noPara="Add donation categories so that people can donate for a specific cause."
    noBtnText="Invite Donors"
    onInviteClick={() => btnClick()}
    iconTextSize={14}
    sortColumnIndex={[4]}
    editIcon={CreateIcon}
    showIconCol={true}
    deleteIcon={DeleteIcon}
    editAction={(data) => editAction(data)}
    deleteAction = { (data) => props.deleteIntegration(data)}
    showFooter={rows?.length > 10 ? true : false}
    rowsPerPage={10}
    parentTableDrag={true}
    DragIcon={Handle}
    sortData={(e) => sortData(e)}
  />
  )
}