import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import * as actions from '../../store/Actions/index';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {
  BaseTabs, SimpleTable, FormSwitch, MasterLayoutMiddle, Initials, CardStatus,
  FooterInner, BaseButton, CardData
} from "@dclab/dc-react-ui-kit";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as Crown } from '../../assets/img/crown.svg'

import CreateIcon from "../../assets/images/create-24px.svg";
import DeleteIcon from "../../assets/images/delete_forever-24px.svg";
import Spinner from '../../components/common/Spinner';
import Moment from 'moment';

import './style.css'



const useStyles = makeStyles((theme) => ({}));

function Order(props) {

  const [rows, setRows] = useState([]);
  useEffect(() => {
    props.onGetAllOrders();
    // if(props?.location?.state?.contactDeleted) {
    //   setshowAlert(true);
    //   setTimeout(() => {
    //     setshowAlert(false)
    //   }, 8000);
    // }
  }, [])

  const checkStatus = (status) => {
    status = status ? status.toLowerCase() : "";
    if (status === 'new') {
      return "invite"
    } else if (status === 'in-progress'){
      return "discontinued"
    } else if (status === 'closed'){
      return "failed"
    } else if (status === 'done'){
      return "success"
    }else if (status === 'completed'){
      return "success"
    }
    else {
      return "discontinued"
    }
 }

  useEffect(() => {
    const { allOrders } = props;
    if(allOrders && allOrders.length > 0) {
      const tempArray = allOrders.map((text, index) => {
        
        return [
          { type: "id", value: index + 1 , data: {...text}},
          {
            type: 'link',
            name: 'invoice_number',
            value: text?.orderNo ? text?.orderNo.replace("#", "") : '',
            // url: text?.orderUrl ?? '',
            linkType: "internal"
          },
          { type: "string", name: "frequency", value: Moment(text.created).format("MMM DD, YYYY"), },
          { type: "string", name: "frequency", value: text?.orgId?.orgName ?? '', },
          { type: "string", name: "frequency", value: `$${(text.totalAmount / 100).toFixed(2)}`, },
          { type: 'status', status: checkStatus(text?.status ?? "new"), value:  text?.status },
          { type: "action", status: "success", value: "See details" },
        ]
      })
      setRows(tempArray);
    }

  }, [props.allOrders])
  // ============================== Table Start ======================================//


  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };

  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };

  let col = [
    { type: "number", name: "serial_number", label: "NO.", width: "auto" },
    { type: "string", name: "name", label: "Order no.", width: "15%" },
    { type: "string", name: "name", label: "Order date", width: "15%" },
    { type: "string", name: "name", label: "Org name", width: "15%" },
    { type: "string", name: "name", label: "Amount", width: "15%" },
    { type: "status", name: "status", label: "STATUS", width: "15%" },
    { type: "action", name: "action", label: "ACTION", width: "10%" },
  ];

  // let rows = [];
  // if(allOrders && allOrders.length) { 
  //   rows = allOrders.map((text, index) => {
  //     return ([
  //       { type: 'id', value: index + 1, data: { ...text } },
  //       { type: "link", name: "invoice_number", value: text.orderNo ?? '',url: text.orderUrl ?? '', linkType: "external",_id: text._id },
  //       { type: "string", name: "frequency", value: Moment(text.created).format("MMM DD, YYYY")},
  //       { type: "string", name: "frequency", value: text.orgName ?? ''},
  //       { type: 'string', name: 'frequency', value:  text.amount ?? '' },
  //       { type: 'status', status: "new", value: text?.status ?? "New order" },
  //       { type: "action", status: "success", value: "View Details" },
  //     ])
  //   })
  // }

  // let rows = [];
  // if(allOrders && allOrders.length) { 
  //   rows = allOrders.map((text, index) => {
  //     return ([
  //       { type: "id", value: index + 1 },
  //       {
  //         type: 'link',
  //         name: 'invoice_number',
  //         value: text.orderNo ?? '',
  //         url: text.orderUrl ?? '',
  //         linkType: "external",
  //       },
  //       { type: "string", name: "frequency", value: Moment(text.created).format("MMM DD, YYYY")},
  //       { type: "string", name: "frequency", value: text.orgName ?? ''},
  //       { type: "string", name: "frequency", value: text.amount ?? '' },
  //       { type: 'status', status: "new", value: text?.status ?? "New order" },
  //       { type: "action", status: "success", value: "See details" },
  //     ]);
  //   }
  // }

  // let rows = [
  //   [
  //     { type: "id", value: "01" },
  //     {
  //       type: 'link',
  //       name: 'invoice_number',
  //       value: '1848-5131',
  //       url: 'https://www.google.com',
  //       linkType: "external"
  //     },
  //     { type: "string", name: "frequency", value: "May 06, 2020", },
  //     { type: "string", name: "frequency", value: "Brooklyn Islamic Center", },
  //     { type: "string", name: "frequency", value: "$19.99", },
  //     { type: "string", name: "frequency", value: "New order", },
  //     { type: "action", status: "success", value: "See details" },
  //   ],

  //   [
  //     { type: "id", value: "02" },
  //     {
  //       type: 'link',
  //       name: 'invoice_number',
  //       value: '1848-5131',
  //       url: 'https://www.google.com',
  //       linkType: "external"
  //     },
  //     { type: "string", name: "frequency", value: "May 06, 2020", },
  //     { type: "string", name: "frequency", value: "Brooklyn Islamic Center", },
  //     { type: "string", name: "frequency", value: "$19.99", },
  //     { type: "string", name: "frequency", value: "New order", },
  //     { type: "action", status: "success", value: "See details" },
  //   ],


  //   [
  //     { type: "id", value: "03" },
  //     {
  //       type: 'link',
  //       name: 'invoice_number',
  //       value: '1848-5131',
  //       url: 'https://www.google.com',
  //       linkType: "external"
  //     },
  //     { type: "string", name: "frequency", value: "May 06, 2020", },
  //     { type: "string", name: "frequency", value: "Brooklyn Islamic Center", },
  //     { type: "string", name: "frequency", value: "$19.99", },
  //     { type: "string", name: "frequency", value: "New order", },
  //     { type: "action", status: "success", value: "See details" },
  //   ],

  

  // ];
const details = (obj) => {
  console.log("Obj is: ", obj);
}

const onReceiptClick = (obj) => {
  const { allOrders } = props;

  const orderDetails = allOrders?.filter((value) => value.orderNo === obj.value);
  if(orderDetails.length > 0)
    props.history.push({pathname: '/order/order-details', state: {orderData: orderDetails[0]}})
}




  // ============================== Table End ======================================//

  // ============================== Style Start ===============================//
  const styles = {
    first: {
      marginBottom: 31,
    },
    second: {
      marginBottom: 111,
    },
    third: {
      marginBottom: 1,
    },
  };

  // ============================== Style END ===============================//

  const classes = useStyles();

  const style = {
    width: '100%',
  };

  return (
    <MasterLayoutMiddle
      content={
        <div>
           {props.page && props.page.loading && <Spinner />}
          <Grid container spacing={3}>

              <Grid item xs={12} >
                <div className="table_box">
                  <SimpleTable
                    showHeader={true}
                    title="Orders"
                    width="100%"
                    rows={rows}
                    columns={col}
                    colSpan={16}
                    actionSendButton={true}
                    classProps={changeClassProps()}
                    donationsCount={1}
                    noTitle=""
                    noPara=""
                    noBtnText=""
                    onInviteClick={() =>
                      this.props.history.push(
                        "/add-new-campaign"
                      )
                    }
                    actionLink={(name) => props.history.push({pathname: '/order/order-details', state: {orderData: name[0].data}})}
                    onLinkClick={(name) => onReceiptClick(name)}
                    showIconCol={false}
                    actionSendButton={true}
                    //editIcon={Edit}
                    //deleteIcon={Delete}
                    editIcon={CreateIcon}
                    deleteIcon={DeleteIcon}
                    //editAction = {(e) => this.editService(e)}
                    deleteAction={(e) => console.log(e)}
                    showFooter={true}
                    showAdd={false}
                    addText="ADD NEW"
                    onAddClick={() =>
                      this.props.history.push(
                        "/add-new-campaign"
                      )
                    }
                    iconTextSize={16}
                  />
                </div>
              </Grid>

          </Grid>

        </div>
      }

      isFooter={true}
      footer={
        <FooterInner style={style} />
      }
      isWhiteBackground={false}
    />
  );
}



const mapStateToProps = state => {
  return {
    allOrders: state.orders.allOrders,
    page: state.page,
    errors: state.errors
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAllOrders: () => dispatch(actions.getAllOrders()),
    onClearError: () => dispatch(actions.clearErrors())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Order);