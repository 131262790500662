import axios from 'axios';

import {
    SET_ERRORS,
    SET_TEAM_MEMBERS,
    SET_FINANCE_TEAM_MEMBERS,
    SET_FINANCE_TEAM_MEMBER,
    SET_VERIFY_TEAM_MEMBER_EMAIL
} from './actionTypes';

import {
    setPageLoading,
    clearPageLoading,
    clearErrors,
    showMessage
} from './pageActions';

const backendServerURL = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;

//Getting all team Members from 
export const getAllTeamMembers = (id) => dispatch => {
    dispatch(setPageLoading());

    axios
    .get( backendServerURL+`/hikmah/team-members?orgId=${id}`, {
        headers: { 
            'token': localStorage.authToken
        }
    } )
    .then(res => {
        const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
        dispatch(clearErrors());
        dispatch({type: SET_TEAM_MEMBERS, payload:data })
        
    })
    .catch(err => {
        console.log("error: ", err && err.response && err.response.data && err.response.data);
        // dispatch({type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! "} })

    })      
    .finally(() => dispatch(clearPageLoading()))
};


//Getting all team Members from 
export const getAllFinanceTeamMembers = () => dispatch => {
    dispatch(setPageLoading());

    axios
    .get( backendServerURL+`/hikmah/finance-team-members`, {
        headers: { 
            'token': localStorage.authToken
        }
    } )
    .then(res => {
        const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
        dispatch(clearErrors());
        dispatch({type: SET_FINANCE_TEAM_MEMBERS, payload:data })
        
    })
    .catch(err => {
        console.log("error: ", err && err.response && err.response.data && err.response.data);
        dispatch({type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! "} })

    })      
    .finally(() => dispatch(clearPageLoading()))
};



// Add Team member
export const addTeamMember = (formData, history) => dispatch => {
    dispatch(setPageLoading());

    axios
    .post(
        backendServerURL+`/hikmah/team-members`, 
        formData, {
            headers: { 
                'token': localStorage.authToken
            }
        }
    )
    .then(res => {
        console.log("Checking Result in addTeamMember: ", res);
        history.push('/admin-organization');
        dispatch(clearErrors());
    })
    .catch(err => {
        console.log("error: ", err && err.response && err.response.data && err.response.data);
        dispatch({type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! "} })

    })      
    .finally(() => dispatch(clearPageLoading()))
};

// Add Team member
export const addFinanceTeamMember = (formData, ownProps) => dispatch => {
    dispatch(setPageLoading());

    axios
    .post(
        backendServerURL+`/hikmah/registerFinanceTeamMember`, 
        formData, {
            headers: { 
                'token': localStorage.authToken
            }
        }
    )
    .then(res => {
        console.log("Checking Result in addTeamMember: ", res);
        ownProps.history.push('/verify-team-member-code');
        dispatch({type: SET_VERIFY_TEAM_MEMBER_EMAIL, payload: formData.userName })

        dispatch(clearErrors());
    })
    .catch(err => {
        console.log("error: ", err && err.response && err.response.data && err.response.data);
        dispatch({type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! "} })
    })      
    .finally(() => dispatch(clearPageLoading()))
};


export const verifyMemberEmail = (formData, ownProps) => dispatch => {
    dispatch(setPageLoading());

    axios
    .post(
        backendServerURL+`/hikmah/auth/verify`, 
        formData, {
            headers: { 
                'token': localStorage.authToken
            }
        }
    )
    .then(res => {
        console.log("Checking Result in addTeamMember: ", res);
        ownProps.history.push('/team-members');
        dispatch(clearErrors());
    })
    .catch(err => {
        console.log("error: ", err && err.response && err.response.data && err.response.data);
        dispatch({type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! "} })
    })      
    .finally(() => dispatch(clearPageLoading()))
};

export const sendResetCode = (formData, ownProps) => dispatch => {
    dispatch(setPageLoading());

    axios
    .post(
        backendServerURL+`/hikmah/auth/sendPasswordResetCode`, 
        formData, {
            headers: { 
                'token': localStorage.authToken
            }
        }
    )
    .then(res => {
        console.log("Checking Result in EditTeamMember: ", res);
        ownProps.history.push('/team-members/reset-password-cod');
        dispatch(clearErrors());
    })
    .catch(err => {
        console.log("error: ", err && err.response && err.response.data && err.response.data);
        dispatch({type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! "} })
    })      
    .finally(() => dispatch(clearPageLoading()))
};

export const resetMemberPassword = (formData, id, ownProps) => dispatch => {
    dispatch(setPageLoading());

    axios
    .post(
        backendServerURL+`/hikmah/auth/resetPassword`, 
        formData, {
            headers: { 
                'token': localStorage.authToken
            }
        }
    )
    .then(res => {
        
        dispatch(showMessage("Password changed successfully!"));
        ownProps.history.push(`/edit-finance-team-member/${id}`);
        dispatch(clearErrors());
    })
    .catch(err => {
        console.log("error: ", err && err.response && err.response.data && err.response.data);
        dispatch({type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! "} })
    })      
    .finally(() => dispatch(clearPageLoading()))
};


// Delete team member
export const deleteTeamMember = (id, orgId) => dispatch => {

    axios
    .delete(
        backendServerURL+`/hikmah/team-members/${id}`, {
            headers: { 
                'token': localStorage.authToken
            }
        }
    )
    .then(res => {
        console.log("Checking Result in deleteTeamMember: ", res);
        dispatch(clearErrors());
        dispatch(getAllTeamMembers(orgId));
    })
    .catch(err => {
        console.log("error: ", err && err.response && err.response.data && err.response.data);
        dispatch({type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! "} })

    })
};

export const getFinanceTeamMember = (id, history) => dispatch => {
    axios
    .get(
        backendServerURL+`/hikmah/getFinanceTeamMemberById/${id}`, {
            headers: { 
                'token': localStorage.authToken
            }
        }
    )
    .then(res => {
        console.log("Checking Result getTeamMember: ", res);
        const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
        dispatch({type: SET_FINANCE_TEAM_MEMBER, payload:data })
        dispatch(clearErrors());
    })
    .catch(err => {
        console.log("error: ", err && err.response && err.response.data && err.response.data);
        dispatch({type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! "} })
    })
};

export const editFinanceTeamMember = (id, formData, ownProps) => dispatch => {
    dispatch(setPageLoading());
    axios
    .put(
        backendServerURL+`/hikmah/updateFinanceTeamMember/${id}`, 
        formData, {
            headers: { 
                'token': localStorage.authToken
            }
        }
    )
    .then(res => {
        console.log("Checking Result in addTeamMember: ", res);
        ownProps.history.push('/team-members');
        dispatch(clearErrors());
    })
    .catch(err => {
        console.log("error: ", err && err.response && err.response.data && err.response.data);
        dispatch({type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! "} })
    })      
    .finally(() => dispatch(clearPageLoading()))
};
