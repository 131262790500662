import React from 'react'
import { ComponentDonationCard } from "@dclab/dc-react-ui-kit";
import moment from "moment";


export default function TopCardsData(props) {
    const { card1 = {}, card2 = {}, card3 = {}, card4 = {}, card5 = {} } = props.productCardData;

    let data = [
        {
            header: `Total Apps`,
            amount: card1?.data ? card1.data : 0,
            percentage: card1?.percentage ? (card1?.percentage).toFixed(2) : 0,
            showSign: true,
            showPercentage: true,
            text:'from last month',
            sign: card1?.percentage > 0 ? true : false
        },
        {
            header: "Yesterday Revenue",
            amount: card2?.data ? "$" + card2.data.toFixed(2) : 0,
            percentage: card2?.percentage ? (card2?.percentage).toFixed(2) : 0,
            showSign: true,
            showPercentage: true,
            text:'from last month',
            sign: card2?.percentage > 0 ? true : false
        },
        {
            header: "Last 7 days revenue",
            amount: card3?.data ? "$" + card3.data.toFixed(2) : 0,
            percentage: card3?.percentage ? (card3?.percentage).toFixed(2) : 0,
            showSign: false,
            showPercentage: false,
            text: `Year of ${moment().format("YYYY")}`,
            sign: false
        },
        {
            header: "This month Revenue",
            amount: card4?.data ? "$" + card4.data.toFixed(2) : 0,
            percentage: card4?.percentage ? (card4?.percentage).toFixed(2) : 0,
            showSign: true,
            showPercentage: true,
            text:'from last month',
            sign: card4?.percentage > 0 ? true : false
        },
        {
            header: `Last month revue`,
            amount: card5?.data ? "$" + card5.data.toFixed(2) : 0,
            percentage: card5?.percentage ? (card5?.percentage).toFixed(2) : 0,
            showSign: true,
            showPercentage: true,
            text:'from 60 invites',
            sign: card5?.percentage > 0 ? true : false
        },
    ]

    return (
         <ComponentDonationCard  
         object={data}
        />
    )
}
