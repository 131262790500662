import { CardData, AlertAction, MasterLayoutMiddle, FooterInner, } from '@dclab/dc-react-ui-kit'
import React, { useState, useEffect } from 'react'
import HardwareDevicesTable from './HardwareDevicesTable';
import { connect } from 'react-redux';
import * as actions from '../../store/Actions/index';
import Spinner from '../../components/common/Spinner';
import Grid from '@material-ui/core/Grid';

function HardwareDevices(props) {
    const [data, setdata] = useState([
        {
            title: "Total Apps",
            donation: "24",
            fromLastMonth: 34,
            increased: true,
        },
        {
            title: "Yesterday Revenue",
            donation: "$12400",
            fromLastMonth: 34,
            increased: true,
        },
        {
            title: "7 days Revenue",
            donation: "$640",
            fromLastMonth: 34,
            increased: false,
        },
        {
            title: "This Month Revenue",
            donation: "$640",
            fromLastMonth: 34,
            increased: false,
        },
        {
            title: "Last Month Revenue",
            donation: "$640",
            fromLastMonth: 34,
            increased: false,
        },
    ])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        props.onGetAllDevices();
    }, [])

    const deleteDevice = (id) => {
        props.onDeleteDevice(id)
    }

    const style = {
        width:'100%',
    };


    return (
        <div>
          <MasterLayoutMiddle
            header={
                            
                <div>
                     {/* {
                        props.errors && props.errors.error && <AlertAction
                        alert={props.errors.message}
                        alertType={"error"}
                        //   onClick={this.closeAlert}
                        />
                    }    */}
                </div>
            }

            content={

              <div>

                  <Grid container spacing={4}>	      

                    <Grid item xs={12}> 
                        {props.page && props.page.loading && <Spinner />}
                        <CardData data={data}  />
                    </Grid>

                    <Grid item xs={12}> 
                        <HardwareDevicesTable devices={props.devices} history={props.history} deleteDevice={deleteDevice} />
                    </Grid>

                  </Grid>

              </div>
            }


          isFooter={true}
          footer={
          <FooterInner style={style}/>
          }

          isWhiteBackground={false}


          />

      </div>
 
    )
}


const mapStateToProps = state => {
    return {
        devices: state.devices.devices,
          page: state.page,
          errors: state.errors
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetAllDevices: () => dispatch(actions.getAllDevices()),
        onDeleteDevice: (id) => dispatch(actions.deleteDevice(id)),
        //   onClearError: () => dispatch(actions.clearErrors())
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(HardwareDevices);