import React from 'react'
import { ComponentDonationCard } from "@dclab/dc-react-ui-kit";
import moment from "moment";


export default function TopCardsData(props) {
    const { newOrgsCard, onBoardingCard, activeCard, inviteCard } = props.orgsAndInvitedOrgs;

    // let activeOrganization = [], onBoardingOrganization = [], newOrganization = [];

    // if (allOrganizationsData && allOrganizationsData.length) {
    //     activeOrganization = allOrganizationsData.filter(org => (org.org_status).toLowerCase() === "active");
    //     onBoardingOrganization = allOrganizationsData.filter(org => (org.org_status).toLowerCase() === "onboarding");
    //     newOrganization = allOrganizationsData.filter(org => {
    //         // console.log(org.createdAt > moment().subtract(7, "d").toDate());
    //         return org.createdAt > moment().subtract(7, "d").toDate()
    //     })
    // }


    let data = [
        {
            header: `New Org - ${moment().format("MMM")} ${moment().format('YYYY')}`,
            amount: newOrgsCard && newOrgsCard.data ? newOrgsCard.data : 0,
            percentage: newOrgsCard && newOrgsCard.percentage ? (newOrgsCard.percentage).toFixed(0) : 0,
            showSign: true,
            showPercentage: true,
            text:'from last month',
            sign: newOrgsCard && newOrgsCard.percentage > 0 ? true : false
        },
        {
            header: "Onboarding",
            amount: onBoardingCard && onBoardingCard.data ? onBoardingCard.data : 0,
            percentage: onBoardingCard && onBoardingCard.percentage ? (onBoardingCard.percentage).toFixed(0) : 0,
            sign: onBoardingCard && onBoardingCard.percentage > 0 ? true : false,
            showSign: true,
            text:'from last month',
            showPercentage: true,
        },
        {
            header: "Active Org",
            amount: activeCard && activeCard.data ? activeCard.data : 0,
            percentage: activeCard && activeCard.percentage ? (activeCard.percentage).toFixed(0) : 0,
            sign: activeCard && activeCard.percentage > 0 ? true : false,
            showSign: true,
            text:'from last year',
            showPercentage: true,
        },
        {
            header: "Placeholder",
            amount: 0,
            percentage: 0,
            sign: false,
            showSign: true,
            text:'from last month',
            showPercentage: true,
        },
        {
            header: `Invited - ${moment().format('YYYY')}`,
            amount: inviteCard && inviteCard.data ? inviteCard.data : 0,
            percentage: inviteCard && inviteCard.percentage ? (inviteCard.percentage).toFixed(0) : 0,
            sign: inviteCard && inviteCard.percentage > 0 ? true : false,
            showSign: true,
            text:'accepted from invites',
            showPercentage: true,
        },
    ]

    return (
         <ComponentDonationCard  
         object={data}
        />
    )
}
