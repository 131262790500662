import React from "react";

import Header from '../components/Header/Header';
import Routes from './routes';
import NavBar from '../components/NavDawer/NavDrawer';
import { MasterLayout} from "@dclab/dc-react-ui-kit";



const HomePage = (props) => {

  let orgDetail = window.location.href.indexOf("organization-detail") > -1 ? true : false || window.location.href.indexOf("select-hardware") > -1 ? true : false || window.location.href.indexOf("assign-hardware") > -1 || window.location.href.indexOf("select-hardware") > -1 ? true : false || window.location.href.indexOf("add-team-member") > -1 ? true : false || window.location.href.indexOf("plans") > -1 || window.location.href.indexOf("orgorders") > -1 ? true : false;
  
  let communicationPage = window.location.href.indexOf("communication") > -1 ? true : false ||  window.location.href.indexOf("request") > -1 ? true : false || window.location.href.indexOf("contact") > -1 ? true : false ; 


  const content = (


  <MasterLayout
  header={
    <Header history={props.history} />
    }

    sideBar={
      <NavBar orgDetail={orgDetail} history={props.history} communicationPage={communicationPage} />
    }

    headerFixed={true}

    middleContent ={
      <Routes />
    }
  />



    
  );
  return content;
};





export default HomePage;
