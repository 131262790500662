import React, {useEffect, useState} from 'react'
import {MediaUploader} from '@dclab/dc-react-ui-kit'; 
import replyIcon from '../../../assets/img/replyIcon.svg';
import FileIcon from '../../../assets/img/upload_file_black_24dp.svg'
import {getMediaItems, uploadMedia} from './Apis/index';

export default function MediaUpload(props) {    
const [mediaList, setMediaList] = useState([]); 
const [currentImageSize, setcurrentImageSize] = useState(0)

useEffect( () => {
  getImages();
}, [] );

const getImages = () => {
  const token = localStorage.getItem("authToken");
  getMediaItems( token, '/images').then( response => {
  console.log("res is : ", response);
  let res =  response?.data?.body?.data ?? [];
  setMediaList(res);

  let sum = 0;
  res.forEach(element => {
    sum = sum + (element?.imageMetaData?.imageSize ?? 0) ;      
  });
  setcurrentImageSize(sum)
  }).catch((err) => {
    console.log("err", err)
  });
}

console.log("currentImageSize", currentImageSize)
const selectMediaItem = (obj) => {
  console.log("Obj is: ", obj);
 
}

const imageData = async (imageMetaData, postData,) => {
  // console.log("Meta data: ", imageMetaData);
  // console.log("Post Data: ", postData);
  const token = localStorage.getItem("authToken");
  const response = await uploadMedia(imageMetaData, postData, token);
  getImages();
  console.log("Respose is: ", response);
}

const videoData = (obj) => {
  console.log("Video data is: ", obj);
}

    return (
        <>
            <MediaUploader 
                mediaList = {mediaList}
                getMediaItems = {getMediaItems}
                typeOfMedia={props.typeOfMedia} 
                label={props.label} 
                onChange={selectMediaItem}
                addButtonHandler = {props.addMediaItem}
                imageData = {imageData}
                maxImageLimit={100}
                videoData = {videoData}
                replyIcon = {replyIcon}
                FileIcon = {FileIcon}
                buttonType={props.buttonType}
                buttonName = {props.buttonName}
                currentImageSize = {currentImageSize}
                selectedMedia = {props.selectedMedia ? { ...props.selectedMedia} : ''}
            />
        </>
    )
}
