import React, { useEffect, useState } from 'react'
import {BaseButton, CardDetailsTable, EventLogs, AlertAction, MasterLayoutMiddle, FooterInner, AlertDialogue } from "@dclab/dc-react-ui-kit";
import Check from "../../assets/images/check_circle-24px.svg";
// import Add from "../../assets/images/add_circle-24px.svg";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Cancel from "../../assets/images/cancel-24px.svg";
import './details.module.css';
import { connect } from 'react-redux';
import * as actions from '../../store/Actions/index';
import Spinner from '../../components/common/Spinner';
import Grid from '@material-ui/core/Grid';
import Moment from 'moment';
import Axios from 'axios';


function HardwareDetails(props) {
    const { device } = props
    
    const [open, setOpen] = useState(false);
    const [details, setDetails] = useState([]);
    const [deleteId, setdeleteId] = useState("")
    // console.log("device: ", props.device)
    

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        props.onGetEditDevice(props.match.params.id);
        if(props.device) {
            const deviceData = props.device;
            let disconnectedObj = {};
            let connectedObj = {};
            
            if(deviceData?.timeline?.disconnected) {
                disconnectedObj = {
                    id: 1, 
                    icon: Cancel, 
                    status: 'Device disconnected', 
                    date: Moment(deviceData?.timeline?.disconnected?.created).format('MMM DD, YYYY, hh:mm A'), 
                    details: [
                        { title: 'IP Address', message: deviceData?.timeline?.disconnected?.ip ??  "No data"},
                        {
                            title: 'Country', message: deviceData?.timeline?.disconnected?.country ??  "No data"
                        },
                        {
                            title: 'Browser', message: deviceData?.timeline?.disconnected?.browser ??  "No data"
                        },
                        {
                            title: 'Operating System', message: deviceData?.timeline?.disconnected?.os ??  "No data"
                        },
                        {
                            title: 'Latitude', message: deviceData?.timeline?.disconnected?.lat ??  "No data"
                        },
                        {
                            title: 'Longitude', message: deviceData?.timeline?.disconnected?.long ??  "No data"
                        },
                        {
                            title: 'City, State', message: deviceData?.timeline?.disconnected?.city ??  "No data"
                        },
                        {
                            title: 'Last Active', message:deviceData?.timeline?.disconnected?.lastActive ??  "No data"
                        },
                
                    ]
                }
            }

            if(deviceData?.timeline?.connected) {
                connectedObj = {
                    id: deviceData?.timeline?.disconnected ? 2 : 1, 
                    icon: Check, 
                    status: 'Device connected', 
                    date: Moment(deviceData?.timeline?.connected?.created).format('MMM DD, YYYY, hh:mm A'), 
                    details: [
                        { title: 'IP Address', message: deviceData?.timeline?.connected?.ip ??  "No data"},
                        {
                            title: 'Country', message: deviceData?.timeline?.connected?.country ??  "No data"
                        },
                        {
                            title: 'Browser', message: deviceData?.timeline?.connected?.browser ??  "No data"
                        },
                        {
                            title: 'Operating System', message: deviceData?.timeline?.connected?.os ??  "No data"
                        },
                        {
                            title: 'Latitude', message: deviceData?.timeline?.connected?.lat ??  "No data"
                        },
                        {
                            title: 'Longitude', message: deviceData?.timeline?.connected?.long ??  "No data"
                        },
                        {
                            title: 'City, State', message: deviceData?.timeline?.connected?.city ??  "No data"
                        },
                        {
                            title: 'Last Active', message:deviceData?.timeline?.connected?.lastActive ??  "No data"
                        },
                
                    ]
                }
            }

            const temp = [];
            if(disconnectedObj.id) {
                temp.push(disconnectedObj)
            }

            if(connectedObj.id) {
                temp.push(connectedObj)
            }
            setDetails(temp);

        }
    }, [])

    // const timelineData = [
    //     {
    //         id: 1, icon: Check, status: 'Device connected', date: 'June 03, 2020, 12:00 PM', details: [
    //             { title: 'IP Address', message: "23.123.232.233", link: "www.google.com" },
    //             {
    //                 title: 'Country', message: 'Pakistan'
    //             },
    //             {
    //                 title: 'Browser', message: 'Safari'
    //             },
    //             {
    //                 title: 'Operating System', message: 'Windows'
    //             },
    //             {
    //                 title: 'Latitude', message: '32.123'
    //             },
    //             {
    //                 title: 'Longitude', message: '74.1234'
    //             },
    //             {
    //                 title: 'City, State', message: 'LA'
    //             },
    //             {
    //                 title: 'Last Active', message: '23rd, June 2020'
    //             },
    //         ]
    //     },
    //     {
    //         id: 2, icon: Cancel, status: 'Device disconnected', date: 'June 03, 2020, 12:00 PM', details: [
    //             { title: 'IP Address', message: "23.123.232.233", link: "www.google.com" },
    //             {
    //                 title: 'Country', message: 'Pakistan'
    //             },
    //             {
    //                 title: 'Browser', message: 'Safari'
    //             },
    //             {
    //                 title: 'Operating System', message: 'Windows'
    //             },
    //             {
    //                 title: 'Latitude', message: '32.123'
    //             },
    //             {
    //                 title: 'Longitude', message: '74.1234'
    //             },
    //             {
    //                 title: 'City, State', message: 'LA'
    //             },
    //             {
    //                 title: 'Last Active', message: '23rd, June 2020'
    //             },
    //         ]
    //     },
    // ];

    const checkStatus = (status) => {
        status = status?.toLowerCase();
        status = status === "live" ? "active" : status;
        if (status === 'available') {
          return "#3CC480"
        } else if (status === 'shipped') {
          return "#EEB000"
        } else if (status === 'unavailable' || status === 'lost' || status === 'defective'){
          return "#818E94"
        } else if (status === 'locked'){
          return "#C44058"
        } else if (status === 'active'){
            return "#2490F9"
        } else{
          return "#818E94"
        }
    }

    let styles = {
        
        backBtn: {
            backgroundColor: 'transparent',
            borderRadius: '18px',
            color: '#4D4D4D',
            textAlign: 'left',
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '15px',
            width: '86px',
            height: '36px',
            marginBottom: "0px",
            boxShadow: "none",
            border:"#E8E9E9 1px solid",
          },
    }

    let header = {
        avatar: "",
        tableName: `Hardware/Device Details`,
        icon: "",
        status: []
    }

    let data = [
        {
            field: "Device ID",
            value: device.deviceId
        },
        {
            field: "Assigned Org",
            value: device.orgId && device.orgId.orgName
        },
        {
            field: "Last Active",
            value: device.lastActive
        },
        {
            field: "Mac Address",
            value: device.macAddress
        },
        // {
        //     field: "Status",
        //     value: device.status
        // },
        {
            field: "Connected",
            value: device.connectedOn ? Moment(device.connectedOn).format("MMM DD, YYYY, hh:mm A") : ''
        },
        {
            field: "IP Address",
            value: device.ipAddress
        },
        {
            field: "App Stream",
            value: device.appStream
        },
        {
            field: "Device Name",
            value: device.name
        },
        {
            field: "Country",
            value: device.country
        },
        {
            field: "Unit Price",
            value: `$${device.price}`
        },
        
        // {
        //     field: "Total Revenue",
        //     value: `$${device.revenue}`
        // }
    ];

    const handleConfirm = () => {
        const deviceId = props.match.params.id;
        if(deviceId) {
            props.onDeleteDevice(deviceId)
            setOpen(false);
            props.history.push("/hardware")
        }
    };

    const deleteAction = (deleteData) => {
        setdeleteId(deleteData[0].id)
        setOpen(true)
    };

    let footerLinkName = "Edit Device";
    let footerDeleteButton = "DELETE DEVICE";

    const onClick = () => {
        props.history.push(`/edit-hardware/${props.match.params.id}`)
    }

    const onDeleteClick = () => {
        
        setOpen(true)
    }
      

    const style = {
        width:'100%',
      };

    return (


            <MasterLayoutMiddle

            content={

            <div>

                <AlertDialogue
                    title="Delete Hardware?"
                    description="Are you sure you want to delete this hardware?"
                    description2 = "Deleting will permanently remove from everywhere."
                    cancelText="Cancel"
                    confirmText="Confirm"  
                    open={open}
                    handleCancel={() => setOpen(false)}
                    handleClose={() => setOpen(false)}
                    handleConfirm={() => handleConfirm()}
                    styles={{
                        width:470,
                        marginLeft:800
                    }}
                />

                <Grid container spacing={4}>	      

                <Grid item xs={12}> 
                    <BaseButton
                        onClick={() => props.history.push("/hardware")}
                        text="back"
                        textStyle={styles.txt}
                        disableElevation={false}
                        disableFocusRipple={false}
                        disableRipple={false}
                        style={styles.backBtn}
                        startIcon={<ChevronLeftIcon />}
                    />
                    

                    { props.errors && props.errors.error && <AlertAction alert={props.errors.message} alertType={"error"}
                        //   onClick={this.closeAlert}
                     /> }

                     {props.page && props.page.loading && <Spinner />}
                </Grid>


                <Grid item xs={12}> 
                    <CardDetailsTable 
                        data={data} 
                        header={header} 
                        footerLinkName={footerLinkName} 
                        footerDeleteButton={footerDeleteButton}
                        onDeleteClick={onDeleteClick}
                        onClick={() => onClick()}
                        Status={true}
                        StatusLabel="Status"
                        StatusText={device.status}
                        statuscolor={checkStatus(device.status)}
                    />
                </Grid>

                 <Grid item xs={12}> 
                    <EventLogs data={details} headTitle="Timeline" />
                </Grid>

                </Grid> 
            </div>
            }

            isFooter={true}
            footer={
            <FooterInner style={style}/>
            }

            isWhiteBackground={false}


            />

        
    )
}

const mapStateToProps = state => {
    return {
        device: state.devices.editDevice,
        page: state.page,
        errors: state.errors
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetEditDevice: (id) => dispatch(actions.getEditDevice(id)),
        onDeleteDevice: (id) => dispatch(actions.deleteDevice(id)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HardwareDetails);