import React, {useState, useEffect} from "react";
import Grid from '@material-ui/core/Grid';
import { MasterLayoutMiddle, FooterInner, } from "@dclab/dc-react-ui-kit";
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import './PaymentReceipt.css'
import Logo from '../../../assets/img/DC_Logo.png'

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import moment from "moment";

function PaymentReceipt(props) {
  const [invoice, setinvoice] = useState({})
  const [returnUrl, setreturnUrl] = useState('/');

  useEffect(() => {
    if(props?.location?.state?.invoice) {
      props?.location?.state?.invoice && setinvoice(props.location.state.invoice);
      props?.location?.state?.returnUrl && setreturnUrl(props.location.state.returnUrl)
        console.log("props.location.state.invoice", props.location.state.invoice);
    }
}, [props?.location?.state?.invoice])

  // ============================== Table Start ======================================//
  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });

  function createData(name, calories, fat, carbs) {
    return { name, calories, fat, carbs };
  }

  const rows = invoice?.lines?.data?.length ? invoice.lines.data.map((el) => {
    let amount = el.amount ? (el.amount / 100).toFixed(2) : "0.00";
    let d = el.description?.split("×")[1] ?? ""
    return createData(d?.split("(")[0] ?? "", 1, amount, amount)
  }) : []
  // ============================== Table End ======================================//

  const style = {
    width: '100%',
  };

  const capitalizeFLetter = (val) => {  
    return val ? `${val[0].toUpperCase() + val.slice(1)}` : ""; 
  } 

  const classes = useStyles();
  return (
    <MasterLayoutMiddle
      content={
        <div>
          <div className="ReceiptsContainer">

            <div className="FormButtonContainer">
            <div
              onClick={() => props.history.push(returnUrl)}
              className='backBtn'>
              <ChevronLeft style={{ alignSelf: 'center', marginLeft: 7 }} />
              <p style={{ alignSelf: 'center' }}>Back</p>{' '}
            </div>

            <Button onClick={() => window.open(invoice?.invoice_pdf ?? "")} variant="outlined" className="downloadBtn">Download {invoice?.status === "paid" ? "Receipt" : "Invoice"}</Button>
            </div>
            <div className="ReceiptsForm">

              <div className="RcsptHeader">
                <img className="DcLogoRcspt" src={Logo} alt="dc-logo" />
                <div className="RcsptHeaderInfo">
                  <h2>Invoice # {invoice?.number ?? ""}</h2>
                  <p>Invoice Date: <span>{invoice?.created ? moment(invoice.created * 1000).format("LL") : ""}</span></p>
                  <p>Status: <label>{invoice?.status ? capitalizeFLetter(invoice.status) : ""}</label></p>
                  <p>Payment Date: <span>{invoice?.created ? moment(invoice.created * 1000).format("LL") : ""}</span></p>
                </div>
              </div>

              <div className="BillInfromation">


                <Grid container spacing={3}>

                  <Grid item xs={5}>
                    <div className="RcsptHeaderInfoDetails">
                      <h2>Billed from:</h2>
                      <h3>DEENCONNECT Inc</h3>
                      <p>New York, NY 11201</p>
                      <p>billing@deenconnect.com</p>
                    </div>
                  </Grid>

                  <Grid item xs={6}>
                    <div className="RcsptHeaderInfoDetails">
                      <h2>Billed to:</h2>
                      <h3>{invoice?.customer_name ? invoice.customer_name : localStorage.getItem("organame") }</h3>
                      <p>New York, NY 11201</p>
                      <p>{invoice?.customer_email ?? "" } </p>
                    </div>
                  </Grid>

                </Grid>

              </div>

              <div className="RcptBiilingTable">
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell width='43%'>ITEMS</TableCell>
                        <TableCell width='23%'>QTY</TableCell>
                        <TableCell width='20%'>Unit Price</TableCell>
                        <TableCell align="right">Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.name}>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.calories}</TableCell>
                          <TableCell>${row.fat}</TableCell>
                          <TableCell align="right">${row.carbs}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>

              <div className="TotalDetailsContainer">
                <Grid container spacing={3}>

                  <Grid item xs={6}>

                  </Grid>

                  <Grid item xs={6}>

                    
                    <div className="TotalDetails">
                    <Grid container spacing={1}>

                      <Grid item xs={6}>
                        <p className="invoiceAmountText">Subtotal</p>
                      </Grid>
                      <Grid item xs={6}>
                        <p>{invoice?.subtotal ? "$" + (invoice?.subtotal / 100).toFixed(2) : "$0.00"}</p>
                      </Grid>

                      <Grid item xs={6}>
                        <p className="invoiceAmountText">Tax</p>
                      </Grid>
                      <Grid item xs={6}>
                        <p>$0.00</p>
                      </Grid>

                      <Grid item xs={6}>
                        <p className="invoiceAmountText">Shipping</p>
                      </Grid>
                      <Grid item xs={6}>
                        <p>$0.00</p>
                      </Grid>

                      <Grid item xs={6}>
                        <p className="invoiceAmountText">Discount 10%</p>
                      </Grid>
                      <Grid item xs={6}>
                        <p>$0.00</p>
                      </Grid>

                      <Grid item xs={6}>
                        <p className="boldText invoiceAmountText">Grand Total</p>
                      </Grid>
                      <Grid item xs={6}>
                        <p className="boldText">{invoice?.total ? "$" + (invoice?.total / 100).toFixed(2) : "$0.00"}</p>
                      </Grid>

                      </Grid>

                    </div>
                  </Grid>

                </Grid>
              </div>


              <div className="RscptFormFooter">
                    <p>Thank you for your business!</p>
                    <p>www.deenconnect.io</p>
              </div>
            </div>

          </div>

        </div>
      }
      isFooter={true}
      footer={
        <FooterInner style={style} />
      }
      isWhiteBackground={false}
    />
  );
}
export default PaymentReceipt;