import React, { Component } from 'react';
import Index from './container/index';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import SignIn from './container/SignIn/SignIn';
import OTP from './container/OTP';
import * as actions from './store/Actions/index';
import { connect } from 'react-redux';
import setAuthToken from './utils/setAuthToken';
import PrivateRoute from './components/common/PrivateRoute';
import jwt_decode from 'jwt-decode';
import './App.css'
import ChangePassword from './container/AdminProfile/ChangePassword';



class App extends Component {
  render() {


    if (localStorage.authToken) {
      let decoded = jwt_decode(localStorage.authToken);
      // console.log("Checking Decoded: ", decoded);

      // console.log("Checking Current time: ", Date.now()/ 60000);
      // console.log("Checking exp time: ",decoded.exp/60 );
      // console.log("Checking iat time: ",decoded.iat/60);
      // console.log("Checking difference: ",decoded.exp/60-decoded.iat/60  );
      // console.log("Checking remaimaining time: ",Date.now()/ 60000 - decoded.exp/60 );

      if (Math.floor(Date.now() / 1000) < decoded.exp) {
        // setAuthToken(localStorage.authToken);
        setAuthToken('deenconnect-elobyte');
        this.props.setCurrentUser();
      } else {
        this.props.onLogoutUser(this.props.history, false);
      }

    }

    const routes = (
      <Switch>

        <PrivateRoute path="/dashboard" component={Index} />
        <PrivateRoute path="/admin-organization" component={Index} />
        <PrivateRoute path="/invite-organization" component={Index} />
        <PrivateRoute path="/invite-organization-:id" component={Index} />
        <PrivateRoute path="/organization-detail-:id" component={Index} />
        <PrivateRoute path="/edit-organization-detail-:id" component={Index} />
        <PrivateRoute path="/add-team-member-:id" component={Index} />
        <PrivateRoute path="/varify-ein/:id" component={Index} />
        <PrivateRoute path="/varify-tax-exemption" component={Index} />
        <PrivateRoute path="/hardware" component={Index} />
        <PrivateRoute path="/add-hardware" component={Index} />
        <PrivateRoute path="/edit-hardware/:id" component={Index} />
        <PrivateRoute path="/hardware-details/:id" component={Index} />
        <PrivateRoute path="/assign-hardware/:id" component={Index} />
        <PrivateRoute path="/select-hardware/:id" component={Index} />
        <PrivateRoute path="/team-members" component={Index} />
        <PrivateRoute path="/add-finance-team-member" component={Index} />
        <PrivateRoute path="/edit-finance-team-member/:id" component={Index} />
        <PrivateRoute path="/verify-team-member-code" component={Index} />
        <PrivateRoute path="/admin-profile" component={Index} />
        <PrivateRoute path="/edit-profile" component={Index} />

        <PrivateRoute path="/communication" component={Index} />
        <PrivateRoute path="/request-details-:id" component={Index} />
        <PrivateRoute path="/request-respond-:id" component={Index} />

        <PrivateRoute path="/communication/contact-us" component={Index} />
        <PrivateRoute path="/communication/contact-us-details" component={Index} />
        <PrivateRoute path="/communication/contact-us-respond" component={Index} />

        <Route path="/change-password" component={ChangePassword} />        
        <Route path="/otp" component={OTP} />

        <PrivateRoute path="/admin-products" component={Index} />
        <PrivateRoute path="/add-product" component={Index} />
        <PrivateRoute path="/edit-product/:id" component={Index} />
        <PrivateRoute path="/add-plan" component={Index} />
        <PrivateRoute path="/edit-plan" component={Index} />
        <PrivateRoute path="/plans/details/:id" component={Index} />
        <PrivateRoute path="/plans/:id" component={Index} />

        <PrivateRoute path="/add-integration" component={Index} />
        <PrivateRoute path="/edit-integration/:id" component={Index} />
        <PrivateRoute path="/campaign" component={Index} />
        <PrivateRoute path="/add-new-campaign" component={Index} />
        <PrivateRoute path="/edit-campaign" component={Index} />
        <PrivateRoute path="/campaign-details" component={Index} />

        <PrivateRoute path="/order" component={Index} />
        <PrivateRoute path="/order/order-details" component={Index} />
        <PrivateRoute path="/order/order-form" component={Index} />
        <PrivateRoute path="/order/manage-order" component={Index} />
        
        <PrivateRoute path="/dashboard-stats" component={Index} />

        <PrivateRoute path="/orgorders/:id" component={Index} />
        <PrivateRoute path="/organization/orders/payment-receipt" component={Index} />

        <PrivateRoute path="/team-members/reset-password-cod" component={Index} />
        <PrivateRoute path="/team-members/reset-password" component={Index} />

        

        

        <Route path='/login' component={SignIn} />

        <Redirect to="dashboard" />

        


      </Switch>
    );

    return routes;
  }
}





const mapDispatchToProps = dispatch => {
  return {
    setCurrentUser: () => dispatch(actions.setCurrentUser()),
    onLogoutUser: (history, unsetCookie) => dispatch(actions.logoutUser(history, unsetCookie))
  };
};


export default withRouter(connect(null, mapDispatchToProps)(App));

