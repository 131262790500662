import React from 'react';
import { SimpleTable, CardDetailsTable } from "@dclab/dc-react-ui-kit";
import check_circle from '../../assets/img/check_circle.svg';
import moment from 'moment';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import './tab.css'

  
export default function tabContent3(props) {
  let organization = props.organization;
  let {taxIdStatus} = props.organization;
  let stripeOrgDetails = props.stripeOrgDetails;
  
  console.log("stripeOrgDetails", stripeOrgDetails)

    let columns = [

        { type: "string", name: "category", label: "NAME", width: '20%' },
      
        { type: "string", name: "date/time", label: "SUBMITTED", width: '20%' },
      
        { type: "string", name: "textImg", label: "REVIEWED", width: '40%' },
      
        { type: "status", name: "status", label: "STATUS", width: '30%' },
        { type: "action", name: "action", label: "ACTION", width: '20%' },
      ];
      
      let classProps = () => {
        const tempArray = [];
        columns.forEach((column, index) => {
          tempArray.push(`class${index}`);
        });
        return tempArray;
      };
      
      const changeClassProps = () => {
        let tempArray = classProps();
        tempArray[0] = "class15";
        return tempArray;
      };
      
      const columns1 = (columns) =>
        columns.map((obj) => {
          // delete obj.id;
          // delete obj.name;
          return obj;
        });

        const getVerificationTableStatus = (status) => {
         
          status = status?.toLowerCase();
          switch (status) {
            case 'rejected':
              return 'failed'
            
            case 'approved':
              return 'success'
            
            case 'pending ':
              return 'pending'
    
            default:
              return 'failed'
          }
        };
      
      let rows = [
        [
          { type: "string", name: "category", value: "Tax ID/EIN" },
          { type: "string", name: "category", 
            value: taxIdStatus?.taxIdEinDetail?.submittedAt
            ? moment(taxIdStatus.taxIdEinDetail.submittedAt).format('MMM DD, YYYY, hh:mm A')
            : ' ' },
          { type: "string", name: "category", 
            value: taxIdStatus?.taxIdEinDetail?.reviewedAt
            ? moment(taxIdStatus.taxIdEinDetail.reviewedAt).format('MMM DD, YYYY, hh:mm A')
            : ' '  },
          { type: "status", 
            status: taxIdStatus?.taxIdEin?.length && taxIdStatus?.taxIdEinDetail?.status 
            ? getVerificationTableStatus(taxIdStatus.taxIdEinDetail.status)
            : 'failed', 
            value: taxIdStatus?.taxIdEin?.length && taxIdStatus?.taxIdEinDetail?.status 
              ? taxIdStatus.taxIdEinDetail.status.toLowerCase() 
              : 'Submission Required',
          },
          { type: "action", status: "details", value: taxIdStatus?.taxIdEin?.length ? "See details" : 'submit now'},
        ],
        [
          { type: "string", name: "category", value: "Tax Exemption" },
          { type: "string", name: "category", 
            value: taxIdStatus?.taxExemptDetail?.submittedAt
            ? moment(taxIdStatus.taxExemptDetail.submittedAt).format('MMM DD, YYYY, hh:mm A')
            :''
          },
          { type: "string", name: "category", 
            value: taxIdStatus?.taxExemptDetail?.reviewedAt
            ? moment(taxIdStatus.taxExemptDetail.reviewedAt).format('MMM DD, YYYY, hh:mm A')
            :'' },
          { type: "status", 
            status: taxIdStatus?.taxExempt && taxIdStatus?.taxExemptDetail?.status
            ? getVerificationTableStatus(taxIdStatus.taxExemptDetail.status)
            : 'failed', 
            value: taxIdStatus?.taxExempt && taxIdStatus?.taxExemptDetail?.status
            ? taxIdStatus.taxExemptDetail.status.toLowerCase()
            : 'Not yet submitted'
          },
          { type: "action", status: "details", value: taxIdStatus?.taxExempt ? "See details" : 'submit now'  },
        ],
       
      ];
      
      const click = (msg) => {
        console.log(msg);
      };
      
      const btnClick=() => {
        console.log('invite')
      }
      // console.log(rowFinder(rows));
      // console.log(value);
      
      // <SimpleTable rows={rowFinder(rows)} columns={columns1(columns)} colSpan={6} />;
    
      const actionLinkHandler = (name) => {
        console.log("checking name: ", name);
        props.history.push({pathname:`/varify-ein/${organization._id}`, state: taxIdStatus});
      }

      let header = {
        avatar: "",
        tableName: `Verification - Stripe`,
        icon: "",
        status: []
    };

    let data = [
        {
            field: "Stripe Id",
            value: stripeOrgDetails && stripeOrgDetails.id ? stripeOrgDetails.id : ""
        },
        {
            field: "Bank Account",
            value: stripeOrgDetails && stripeOrgDetails.external_accounts && stripeOrgDetails.external_accounts.data[0] && stripeOrgDetails.external_accounts.data[0].account ? `<span>Connected <img src="${check_circle}" class="verified-icon" /> </span>` : ""
        },
       
        {
            field: "Stripe Status",
            value: stripeOrgDetails && stripeOrgDetails.legal_entity && stripeOrgDetails.legal_entity.verification && stripeOrgDetails.legal_entity.verification.status === "verified" ? 
            `<span>Verified <img src="${check_circle}" class="verified-icon" /> </span>` : "Not Verified"
        },
        {
            field: "Type",
            value: stripeOrgDetails && stripeOrgDetails.type ? stripeOrgDetails.type : ""
        },
        {
            field: "Email",
            value: stripeOrgDetails && stripeOrgDetails.email ? stripeOrgDetails.email : stripeOrgDetails && stripeOrgDetails.legal_entity && stripeOrgDetails.legal_entity.personal_email ? stripeOrgDetails.legal_entity.personal_email : ""
        },
        {
            field: "Country",
            value: stripeOrgDetails && stripeOrgDetails.country ? stripeOrgDetails.country : ""
        },
        {
            field: "Connected",
            value: stripeOrgDetails && stripeOrgDetails.created ? moment(new Date(stripeOrgDetails.created).toString()).format('ll')  : "",
            // value: ""

        },
        {
            field: "Agreement",
            value: stripeOrgDetails && stripeOrgDetails.tos_acceptance && stripeOrgDetails.tos_acceptance.date ? "Agreed on " + moment(new Date(stripeOrgDetails.tos_acceptance.date).toString()).format('lll') : ""
        },
        {
            field: "Currency",
            value: stripeOrgDetails && stripeOrgDetails.default_currency ? stripeOrgDetails.default_currency : ""
        }
    ];


    return(
        <React.Fragment>
          <div style={{marginBottom: '30px'}}>
             <CardDetailsTable data={data} header={header}/>
          </div>
   
          <SimpleTable
          title="Org Tax ID/EIN"
          rows={rows}
          columns={columns1(columns)}
          colSpan={6}
          actionSendButton={true}
          classProps={changeClassProps()}
          onClick={(message, index) => click(message)}
          showFilter={false}
          showAdd={false}
          donationsCount={10}
          noTitle="No recent donations"
          noPara="Add donation categories so that people can donate for a specific cause."
          noBtnText="Invite Donors"
          onInviteClick={() => btnClick()}
          showIconCol={false}
          showFooter={false}
          actionLink={(name) => actionLinkHandler(name)}
        />
        </React.Fragment>
    )
}





