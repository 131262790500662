import React from 'react';
import { SimpleTable } from "@dclab/dc-react-ui-kit";
import moment from 'moment';

export default function tabContent(props) {
  let invitedOrganizations = props.invitedOrganizations;

  let columns = [
    { type: "number", name: "serial_number", label: "NO.", width:"auto" },
    { type: "string", name: "name", label: "NAME", width:"20%" },
    { type: "string", name: "admin_name", label: "ADMIN NAME", width:"20%" },
    { type: "string", name: "date", label: "DATE", width:"40%" },
    { type: "status", name: "status", label: "STATUS", width:"20%" },
    // { type: "string", name: "slkfjdsl", label: "", minWidth: 70 },
    // { type: "string", name: "slkfjdslkjljl", label: "", minWidth: 70 },
    { type: "action", name: "action", label: "ACTION", width:"20%" },
  ];

  let classProps = () => {
    const tempArray = [];
    columns.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };

  const changeClassProps = () => {
    let tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };

  const columns1 = (columns) =>
    columns.map((obj) => {
      // delete obj.id;
      // delete obj.name;
      return obj;
    });

    const checkStatus = (status) => {
       status = status ? status.toLowerCase() : "";
       if (status === 'invite sent') {
        return "invite"
      } else if (status === 'onboarding') {
         return "discontinued"
       } else if (status === 'accepted'){
        return "success"
      } else{
         return "discontinued"
       }
    }

  let rows = [];
  if (invitedOrganizations && invitedOrganizations.length) {
    rows = invitedOrganizations.map((org, index) => {
      return (
        [
          { type: "id", id: org._id, name: 'serial_number', value: index + 1 },
          { type: "string", name: "category", value: org.orgName },
          { type: "string", name: "category", value: org.orgAdmin },
          { type: "string", name: "date", value: moment(org.createdAt).format('LLL') },
          { type: "status", status: checkStatus(org.orgStatus), value: org.orgStatus },
          // { type: "string", name: "lkdsjlksjf", value: "" },
          // { type: "string", name: "lkdsjlksjf", value: "" },
          { type: "action", status: "details", value: "see details" }
        ]
      )
    });
  }




  const click = (msg) => {
    console.log(msg);
  };

  const btnClick = () => {
    console.log('invite')
  }

  const actionLinkHandler = (data) => {
    // console.log("checking Link data: ", data);
    let id = data[0].id;
    console.log("id is: ", id);
    props.history.push(`/invite-organization-${id}`)
  }


  return (<SimpleTable
    title="Invited Organizations"
    showHeader={true}
    rows={rows}
    columns={columns1(columns)}
    colSpan={7}
    actionSendButton={true}
    classProps={changeClassProps()}
    onClick={(message, index) => click(message)}
    showFilter={false}
    showAdd={true}
    showFooter={true}
    addText="INVITE ORG"
    donationsCount={10}
    actionLink={(data) => actionLinkHandler(data)}
    onAddClick={() => props.history.push('/invite-organization')}
    noTitle="No recent donations"
    noPara="Add donation categories so that people can donate for a specific cause."
    noBtnText="Invite Donors"
    onInviteClick={() => btnClick()}
    iconTextSize={14}
    sortColumnIndex={[4]}
  />
  )
}


