import axios from 'axios';

import {
    SET_ERRORS,
    SET_ORGANIZATIONS,
    SET_SINGLE_ORGANIZATION,
    SET_INVITED_ORGANIZATIONS,
    SET_SINGLE_INVITE_ORGANIZATION,
    ORGS_AND_INVITED_ORGS,
    SET_STRIPE_ORGANIZATION,
    SET_ORGANIZATIONS_ORDERS,
    SET_ORGANIZATIONS_ORDERS_RECEIPTS,
    SHOW_MESSAGE
} from './actionTypes';

import {
    setPageLoading,
    clearPageLoading,
    clearErrors,
    showMessage
} from './pageActions';


const backendServerURL = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;



// Invite organization action
export const inviteOrganization = (formData, history, paramId) => dispatch => {
    dispatch(setPageLoading());

    return new Promise((resolve, reject) => {
        axios
            .post(
                backendServerURL + '/organizations',
                formData,
                {
                    headers: {
                        'token': localStorage.authToken
                    }
                }
            )
            .then(res => {
                if (paramId) {
                    history.push(`/invite-organization-${paramId}`);
                    dispatch(showMessage("Invite sent successfully!"));
                } else {
                    history.push("/dashboard?tab=1");
                    dispatch(showMessage("Invite sent successfully!"));
                }
                // console.log("Checking Result: ", res);
                dispatch(clearErrors());
                resolve(true);
            })
            .catch(err => {
                console.log("error: ", err && err.response && err.response.data && err.response.data);
                dispatch({ type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! " } })
                reject(false)
            })
            .finally(() => dispatch(clearPageLoading()))
    })

};

//Getting all organizations from 
export const getAllOrganization = () => dispatch => {
    dispatch(setPageLoading());

    axios
        .get(backendServerURL + '/hikmah/getAllOrganizations',            
        {
            headers: {
                'token': localStorage.authToken
            }
        })
        .then(res => {
            // console.log("Checking Result: ", res && res.data && res.data.body && res.data.body.data && res.data.body.data);
            const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
            dispatch({ type: SET_ORGANIZATIONS, payload: data });
            dispatch(clearErrors());

        })
        .catch(err => {
            console.log("error: ", err && err.response && err.response.data && err.response.data);
            dispatch({ type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! " } })

        })
        .finally(() => dispatch(clearPageLoading()))
};

//Getting all INVITED organizations from 
export const getAllInvitedOrganizations = () => dispatch => {
    dispatch(setPageLoading());

    axios
        .get(backendServerURL + '/hikmah/organization-invited',
        {
            headers: {
                'token': localStorage.authToken
            }
        })
        .then(res => {
            // console.log("Checking Result in getAllInvitedOrganizations: ", res && res.data && res.data.body && res.data.body.data && res.data.body.data);
            const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
            dispatch(clearErrors());
            dispatch({ type: SET_INVITED_ORGANIZATIONS, payload: data })

        })
        .catch(err => {
            console.log("error: ", err && err.response && err.response.data && err.response.data);
            dispatch({ type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! " } })

        })
        .finally(() => dispatch(clearPageLoading()))
};


//get detail of single organization 
export const getOrganizationDetail = (id) => dispatch => {
    dispatch(setPageLoading());

    axios
        .get(backendServerURL + `/hikmah/organizationById/${id}`,            
        {
            headers: {
                'token': localStorage.authToken
            }
        })
        .then(res => {
            console.log("Checking Result org: ", res.data);
            const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
            dispatch(clearErrors());
            if (data.length) {
                dispatch({ type: SET_SINGLE_ORGANIZATION, payload: data[0] });
            }
            else {
                dispatch({ type: SET_SINGLE_ORGANIZATION, payload: data });
            }
        })
        .catch(err => {
            console.log("error: ", err && err.response && err.response.data && err.response.data);
            dispatch({ type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! " } })

        })
        .finally(() => dispatch(clearPageLoading()))
};

export const getOrganizationOrderDetail = (id) => dispatch => {
    dispatch(setPageLoading());

    axios
        .get(backendServerURL + `/hikmah/orders/${id}`,            
        {
            headers: {
                'token': localStorage.authToken
            }
        })
        .then(res => {
            dispatch(clearErrors());
            dispatch({ type: SET_ORGANIZATIONS_ORDERS, payload: res?.data ?? [] });
            
        })
        .catch(err => {
            console.log("Error in getOrganizationOrderDetail");
            console.log("error: ", err && err.response && err.response.data && err.response.data);
            dispatch({ type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! " } })

        })
        .finally(() => dispatch(clearPageLoading()))
};

export const getOrganizationOrderReceipts= (id) => dispatch => {
    dispatch(setPageLoading());

    axios
        .get(backendServerURL + `/hikmah/orgreceipts/${id}`,            
        {
            headers: {
                'token': localStorage.authToken
            }
        })
        .then(res => {
            dispatch(clearErrors());
            dispatch({ type: SET_ORGANIZATIONS_ORDERS_RECEIPTS, payload: res?.data ?? [] });
            
        })
        .catch(err => {
            console.log("Error in getOrganizationOrderDetail");
            console.log("error: ", err && err.response && err.response.data && err.response.data);
            dispatch({ type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! " } })

        })
        .finally(() => dispatch(clearPageLoading()))
};

export const getStripeAccountDetail = (id) => dispatch => {
    dispatch(setPageLoading());

    axios
        .get(backendServerURL + `/organizations/getOrgDetailFromStripe/${id}`,            
        {
            headers: {
                'token': localStorage.authToken
            }
        })
        .then(res => {
            console.log("stripe Result: ", res.data);
            const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
            dispatch(clearErrors());
            dispatch({ type: SET_STRIPE_ORGANIZATION, payload: data });
        })
        .catch(err => {
            console.log("error: ", err);
        })
        .finally(() => dispatch(clearPageLoading()))
};


// edit organization data
export const editOrganization = (id, formData, history) => dispatch => {
    dispatch(setPageLoading());

    axios
        .put(
            backendServerURL + `/hikmah/organizations/${id}`,
            formData,
            {
                headers: {
                    'token': localStorage.authToken
                }
            }
        )
        .then(res => {
            console.log("Checking Result: ", res);
            dispatch(clearErrors());
            dispatch(showMessage("Saved Successfully!"));
            history.push(`/organization-detail-${id}`);
        })
        .catch(err => {
            console.log("error: ", err && err.response && err.response.data && err.response.data);
            dispatch({ type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! " } })

        })
        .finally(() => dispatch(clearPageLoading()))
};


// set Default organization then dispatch editorganization action to update organization data
export const setDefaultOrganization = (id, formData, history) => dispatch => {
    dispatch(setPageLoading());

    axios
        .post(
            backendServerURL + `/organizations/setDefault`,
            {
                orgId: id
            },
            {
                headers: {
                    'token': localStorage.authToken
                }
            }
        )
        .then(res => {
            console.log("Checking Result: ", res);
            dispatch(editOrganization(id, formData, history));
            dispatch(clearErrors());
        })
        .catch(err => {
            console.log("error: ", err && err.response && err.response.data && err.response.data);
            dispatch({ type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! " } })

        })
        .finally(() => dispatch(clearPageLoading()))
};


//get detail of single invited organization 
export const getInvitedOrganizationDetail = (id) => dispatch => {
    dispatch(setPageLoading());

    axios
        .get(backendServerURL + `/hikmah/organization-invited/${id}`,
        {
            headers: {
                'token': localStorage.authToken
            }
        })
        .then(res => {
            console.log("Checking Result in getInvitedOrganizationDetail: ", res && res.data && res.data.body && res.data.body.data && res.data.body.data);
            const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
            dispatch(clearErrors());
            dispatch({ type: SET_SINGLE_INVITE_ORGANIZATION, payload: data })

        })
        .catch(err => {
            console.log("error: ", err && err.response && err.response.data && err.response.data);
            dispatch({ type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! " } })

        })
        .finally(() => dispatch(clearPageLoading()))
};


//Getting all organizations from 
export const getOrganizationsCardData = () => dispatch => {
    dispatch(setPageLoading());

    axios
        .get(backendServerURL + '/hikmah/organizationsCardData', {
            headers: {
                'token': localStorage.authToken
            }
        })
        .then(res => {
            // console.log("Cards Orgs: ", res && res.data && res.data.body && res.data.body.data && res.data.body.data);
            const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
            console.log("cards data",  data)
            dispatch({ type: ORGS_AND_INVITED_ORGS, payload: data });
            dispatch(clearErrors());

        })
        .catch(err => {
            console.log("error: ", err && err.response && err.response.data && err.response.data);
            dispatch({ type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! " } })

        })
        .finally(() => dispatch(clearPageLoading()))
};