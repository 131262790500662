import React, { Component } from 'react';
import { BaseTabs, MasterLayoutMiddle, FooterInner, ComponentDonationCard  } from "@dclab/dc-react-ui-kit";

import TabContent1 from './tabContent1';
import TabContent2 from './tabContent2';
import TabContent3 from './tabContent3';

//for send props to chage the menu
import Navbar from '../../../components/NavDawer/NavDrawer';


import Alert from '../../../components/common/AlertAction';
import { connect } from 'react-redux';
import * as actions from '../../../store/Actions/index';
import Spinner from '../../../components/common/Spinner';
import Grid from '@material-ui/core/Grid';


class OrganizationOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      errors: {},
      loading: false,
      organization: {},
      teamMembers: [],
      id: '',
      currentTab: 0,
      orgOrders: [],
      orderReceipts: []

    };
  }


  static getDerivedStateFromProps(props, state) {
    let errors = props.errors;
    let page = props.page;
    let organization = props.organization ? props.organization : {};
    let teamMembers = props.teamMembers ? props.teamMembers : {};
    let orgOrders = props.orgOrders ?  props.orgOrders : [];
    let orderReceipts = props.orderReceipts ? props.orderReceipts : [];



    let stateChanged = false;
    let changedState = {};

    if (orderReceipts && JSON.stringify(state.orderReceipts) !== JSON.stringify(orderReceipts)) {
      changedState.orderReceipts = orderReceipts;
      stateChanged = true;
    }

    if (orgOrders && JSON.stringify(state.orgOrders) !== JSON.stringify(orgOrders)) {
      changedState.orgOrders = orgOrders;
      stateChanged = true;
    }

    if (organization && JSON.stringify(state.organization) !== JSON.stringify(organization)) {
      changedState.organization = organization;
      stateChanged = true;
    }

    if (teamMembers && JSON.stringify(state.teamMembers) !== JSON.stringify(teamMembers)) {
      changedState.teamMembers = teamMembers;
      stateChanged = true;
    }

    if (errors && JSON.stringify(state.errors) !== JSON.stringify(errors)) {
      changedState.errors = errors;
      stateChanged = true;
    }

    if (page && JSON.stringify(state.loading) !== JSON.stringify(page.loading)) {
      changedState.loading = page.loading;
      stateChanged = true;
    }

    if (stateChanged) {
      return changedState;
    }


    return null;
  }




  onClick = (x, y) => {
    console.log('checking onClick function: ', x)
    console.log('checking onClick function Y:', y)
    if (y === 1) {
      const id = this.props.match.params.id;
      this.props.onGetTeamMembers(id);
    }
  }

  styles = {
    routes: {
      marginLeft: '70px',
      width: '90%',
      padding: '30px 0',
    }

  };

  showAlertHandler = () => {
    this.props.onClearError();
  }

  componentDidMount() {
    console.log("componentDidMount");
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    const id = this.props.match.params.id;
    this.setState({
      id: id
    });
    this.props.onGetSingleOrganiztion(id);
    this.props.onGetStripeAccountDetail(id);
    this.props.onGetOrders(id);
    this.props.onGetOrdersReceipts(id);
  }

  onDeleteMember = (data) => {
    console.log("delete Id: ", data);
    let orgId = this.props.match.params.id;
    this.props.onDeleteMember(data[0].id, orgId);
  }

  render() {
  
    let { errors, loading, organization, show, teamMembers, orgOrders, orderReceipts, id} = this.state;
    let pageContent = '';
    let message = 'Invite sent successfully! ';
    let type = 'success';
    let styles = this.styles;

  

    if (loading) {
      pageContent = <Spinner />

    }
    if (errors && errors.message) {
      message = errors.message;
      type = 'error';
      show = true;
    }

    const object = [
      {
        label: "ORDERS",
        item: <TabContent1 organization={organization} history={this.props.history} orgOrders={orgOrders} orgId = {id}/>
      },
      {
        label: "PAYMENTS HISTORY",
        item: <TabContent2 teamMembers={teamMembers} deleteAction={this.onDeleteMember} id={this.state.id} history={this.props.history} orgOrders={orderReceipts} orgId = {id}/>,
      },
      // {
      //   label: "PAYMENTS HISTORY",
      //   item: <TabContent3 organization={organization} stripeOrgDetails={this.props.stripeOrgDetails} history={this.props.history} orgOrders={orgOrders}/>,
      // },

    ];

    const data = [
      {
          header: "Active Kiosks",
          amount: "3",
          percentage: "",
          sign: true,
          text:'Febrarury 2010',
          showPercentage:false,
          showSign:false
      },
      {
          header: "Donation This Month",
          amount: "$560",
          percentage: "35",
          sign: false,
            text:'from last month',
          showPercentage:true,
          showSign:true
      },
      {
          header: "Total Donors",
          amount: "$640",
          percentage: "10",
          sign: true,
            text:'from last month',
          showPercentage:true,
          showSign:true
      },
      {
          header: "Total Donations - 2020",
          amount: "$64000.78",
          percentage: "10",
          sign: false,
            text:'from last month',
          showPercentage:true,
          showSign:true
      }
      
  ];
  


    const handleChange = (event, newValue) => {
      this.setState({ currentTab: newValue });
    };

    const style = {
      width:'100%',
    };

    return (
      <MasterLayoutMiddle
          header={          
            <div>
              {/* <Alert message={message} type={type} click={this.showAlertHandler} show={show} /> */}
              <div style={{ display: 'none' }}>
              {console.log("into navBAR")}
              {/* <Navbar orgDetail={true} /> */}
              </div>  
            </div>
            }
            content={
            <div>

            <Grid container spacing={3}>

            {/* <Grid item xs={12}> 
                <ComponentDonationCard object={data} />
            </Grid>	       */}

              <Grid item xs={12}> 
                <BaseTabs
                title=""
                value={this.state.currentTab}
                object={object}
                indicatorColor="#7266fb"
                style={{ color: "#2490F9", border: "1px solid #E8E9E9", width: '100%', background: 'white', padding: '0px 0' }}
                tabsStyle={{ width: '25%' }}
                onClick={(x, y) => this.onClick(x, y)}
                onChange={(event, newValue) => handleChange(event, newValue)}
              />
              {pageContent}
              </Grid>

            </Grid>

            
            </div>
          }
  
          isFooter={true}
          footer={
            <FooterInner style={style}/>
          }

          isWhiteBackground={false}
      />
      
    )
  }
}


const mapStateToProps = state => {
  return {
    organization: state.organizations.organization,
    orgOrders: state.organizations.organizationOrders,
    orderReceipts: state.organizations.orgOrderReceipts,
    teamMembers: state.teamMembers.teamMembers,
    stripeOrgDetails: state.organizations.stripeOrgDetails,
    page: state.page,
    errors: state.errors
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onGetSingleOrganiztion: (id) => dispatch(actions.getOrganizationDetail(id)),
    onGetStripeAccountDetail: (id) => dispatch(actions.getStripeAccountDetail(id)),
    onGetTeamMembers: (id) => dispatch(actions.getAllTeamMembers(id)),
    onDeleteMember: (id, orgId) => dispatch(actions.deleteTeamMember(id, orgId)),
    onClearError: () => dispatch(actions.clearErrors()),
    onGetOrders: (id) => dispatch(actions.getOrganizationOrderDetail(id)),
    onGetOrdersReceipts: (id) => dispatch(actions.getOrganizationOrderReceipts(id)),
    
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(OrganizationOrder);













