import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { SimpleTable, MasterLayoutMiddle, CardDetailsTable, FooterInner, BaseButton, } from "@dclab/dc-react-ui-kit";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CreateIcon from "../../assets/images/create-24px.svg";
import DeleteIcon from "../../assets/images/delete_forever-24px.svg";
import { stateAbbreviations } from "../../utils/statesAbbreviations";



import './style.css'
import Moment from 'moment';



const useStyles = makeStyles((theme) => ({}));

function OrderDetails(props) {
  const { userDetails } = props;
  const [orderData, setOrderData] = useState({});
  const [shippingCost, setShippingCost] = useState(0);
  const [rows, setRows] = useState([]);
  const [returnUrl, setReturnUrl] = useState('/order');


  useEffect(() => {
    props?.location?.state?.returnUrl && setReturnUrl(props.location.state.returnUrl);
    if(props?.location?.state?.orderData) {
      setOrderData(props.location.state.orderData);
      const tempArray = props.location.state.orderData.orderItems.map((text, index) => {
        return [
          { type: "string", name: "name", value: `${text.name.toUpperCase()} - ${capitalizeFLetter(text.planType)}`, },
          {
            type: 'link',
            name: 'invoice_number',
            value: text?.receipt_number?.replace('#', '') ,
            url: text.receipt_url,
            linkType: "external"
          },          
          { type: "string", name: "frequency", value: text.qty, },
          { type: "string", name: "frequency", value: `$${parseInt(text.amount/ 100)}${text.frequency === "monthly" ? '/mo' : text.frequency === 'annually' ? '/yr' :'' }` },
          { type: 'url', name: 'single_pic', initials: '' },
          { type: 'url', name: 'frequency', value: text?.dueDate ? Moment(text?.dueDate).format("MMM DD, YYYY h:mm A") : '' },
          { type: 'status', status: 'success', value: text.status },
          { type: "action", status: "success", value: "ASSIGN" },
          ]
      });
      setRows(tempArray);

    };

  }, [])

  // ============================== Table Start ======================================//


  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };

  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };

  const capitalizeFLetter = (data) => {
    return data[0].toUpperCase() + data.slice(1);
  };

  const onClickManageOrder = () => {
    props.history.push({pathname: '/order/manage-order', state: {orderData: orderData, returnUrl: returnUrl}})
  }

  let col = [
    { type: "string", name: "name", label: "Name", width: "20%" },
    { type: "string", name: "category", label: "STRIPE ORDER #", width: '15%' },
    { type: "string", name: "name", label: "QTY", width: "10%" },
    { type: "string", name: "name", label: "Price", width: "10%" },
    { type: "string", name: "name", label: "Assignee", width: "10%" },
    { type: "string", name: "name", label: "DUE DATE", width: "15%" },
    { type: "status", name: "status", label: "STATUS", width: "10%" },
    { type: "action", name: "action", label: "ACTION", width: "10%" },
  ];

  // let rows = [
  //   [

  //     { type: "string", name: "name", value: "May 06, 2020", },
  //     { type: "string", name: "frequency", value: "2", },
  //     { type: "string", name: "frequency", value: "$14/mo", },
  //     { type: 'url', name: 'single_pic', initials: 'HK' },
  //     { type: 'url', name: 'frequency', value: 'Jun 20, 2021, 12:00 PM' },
  //     { type: 'string', status: 'success', value: 'Active' },
  //     { type: "action", status: "success", value: "ASSIGN" },
  //   ],

  //   [

  //     { type: "string", name: "name", value: "May 06, 2020", },
  //     { type: "string", name: "frequency", value: "2", },
  //     { type: "string", name: "frequency", value: "$14/mo", },
  //     { type: 'url', name: 'single_pic', initials: 'HK' },
  //     { type: 'url', name: 'frequency', value: 'Jun 20, 2021, 12:00 PM' },
  //     { type: 'string', status: 'success', value: 'Active' },
  //     { type: "action", status: "success", value: "ASSIGN" },
  //   ],

  //   [

  //     { type: "string", name: "name", value: "May 06, 2020", },
  //     { type: "string", name: "frequency", value: "2", },
  //     { type: "string", name: "frequency", value: "$14/mo", },
  //     { type: 'url', name: 'single_pic', initials: 'HK' },
  //     { type: 'url', name: 'frequency', value: 'Jun 20, 2021, 12:00 PM' },
  //     { type: 'string', status: 'success', value: 'Active' },
  //     { type: "action", status: "success", value: "ASSIGN" },
  //   ],

  // ];

  // ============================== Table End ======================================//


  
  // ============================== Card Details Start ===============================//
  let header = { tableName: `Order Details`, };

  let data = [

    {
      field: "Order #",
      value: `<a >${orderData.orderNo}</a>`
    },

    {
      field: "Payment",
      value: 'Paid' 
      // <CheckCircleIcon style={{color: "#05CE78", fontSize: "14px"}}/>
    },

    {
      field: "Email",
      value: orderData?.email ??  "<span style = 'color: growsray'>No Data</span>"
    },

    {
      field: "Date",
      value: orderData?.createdAt ? Moment(orderData?.createdAt).format("MMM DD, YYYY") : "<span style = 'color: gray'>No Data</span>"
    },

    {
      field: "Subtotal",
      value: `$${(orderData.totalAmount / 100).toFixed(2)}`
    },

    {
      field: "Phone",
      value: orderData?.orgId?.phoneFax?.primaryPhone ? orderData.orgId.phoneFax.primaryPhone : "<span style = 'color: gray'>No Data</span>"
    },

    {
      field: "Organization",
      value: orderData?.orgId?.orgName ? `<a>${orderData.orgId.orgName}</a>` : "<span style = 'color: gray'>No Data</span>"
    },

    {
      field: "Shipping Cost",
      value: `$${shippingCost.toFixed(2)}`
    },

    {
      field: "Shipping",
      value: orderData.shippingAddress?.line1 + " " + orderData.shippingAddress?.city + ", " + (stateAbbreviations[orderData.orgId?.orgAddress?.state] ? stateAbbreviations[orderData.orgId?.orgAddress?.state] : orderData.orgId?.orgAddress?.state) + " " + orderData.shippingAddress?.postalCode
    },

    {
      field: "Address",
      value: orderData.orgId?.orgAddress?.city + ', ' + (stateAbbreviations[orderData.orgId?.orgAddress?.state] ? stateAbbreviations[orderData.orgId?.orgAddress?.state] : orderData.orgId?.orgAddress?.state)
    },

    {
      field: "Total",
      value: `$${(orderData.totalAmount / 100).toFixed(2)}`
    },
    {
      field: "",
      value: " "
    },
    {
      field: "",
      value: " "
    },

    {
      field: "Promo Applied",
      // value: "<a href='#'>EID2021</a>"
      value: '<span className="ondisableText">None</span>'
    },

  ];

  let footerLinkName = "Manage order";
  let footerDeleteButton = "invoice";


  // ============================== Card Details END ===============================//

  const classes = useStyles();

  const style = {
    width: '100%',
  };

  return (
    <MasterLayoutMiddle

      header={
        <div className="backBtnFrom">
          <BaseButton
            onClick={() => props.history.push(returnUrl)}
            text="back"
            textStyle={styles.txt}
            disableElevation={false}
            disableFocusRipple={false}
            disableRipple={false}
            style={styles.backBtn}
            startIcon={<ChevronLeftIcon />}
          />
        </div>
      }
      content={
        <div style={styles.Container}>

          <Grid container spacing={3}>


            <Grid item xs={12} >
              <CardDetailsTable
                data={data}
                header={header}
                footerLinkName={footerLinkName}
                footerDeleteButton={footerDeleteButton}
                onClick={() => onClickManageOrder()}
                //onDeleteClick={() => onDeleteClick()}

                Status={true}
                StatusLabel="Status"
                StatusText={orderData.status}
                statuscolor={orderData.status?.toLowerCase() === 'completed' ?"#3CC47C" : "#7266FB"}
              />

            </Grid>

            <Grid item xs={12} >
              <div className="table_box">
                <SimpleTable
                  showHeader={true}
                  title="Items"
                  width="100%"
                  rows={rows}
                  columns={col}
                  colSpan={16}
                  actionSendButton={true}
                  classProps={changeClassProps()}
                  donationsCount={1}
                  noTitle=""
                  noPara=""
                  noBtnText=""
                  onInviteClick={() =>
                    this.props.history.push(
                      "/add-new-campaign"
                    )
                  }
                  actionLink={(name) => console.log("actionLink: ", name)}
                  showIconCol={false}
                  actionSendButton={true}
                  //editIcon={Edit}
                  //deleteIcon={Delete}
                  editIcon={CreateIcon}
                  deleteIcon={DeleteIcon}
                  //editAction = {(e) => this.editService(e)}
                  deleteAction={(e) => this.deleteServiceAction(e)}
                  showFooter={true}
                  showAdd={false}
                  addText="ADD NEW"
                  onAddClick={() =>
                    this.props.history.push(
                      "/add-new-campaign"
                    )
                  }
                  iconTextSize={16}
                />
              </div>
            </Grid>

          </Grid>

        </div>
      }

      isFooter={true}
      footer={
        <FooterInner style={style} />
      }
      isWhiteBackground={false}
    />
  );
}

let styles = {
  txt: {
    color: '#4D4D4D ',
    letterSpacing: '1.26px',
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '15px'
  },

  backBtn: {
    backgroundColor: 'transparent',
    borderRadius: '18px',
    color: '#4D4D4D',
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '15px',
    width: '86px',
    height: '36px',
  },

  Container: {
    marginTop: "60px",
  }

}

export default OrderDetails