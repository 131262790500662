import axios from 'axios';

import {
    SET_ERRORS,
    SET_ALL_ORDERS,
    SET_ORDER,
    
} from './actionTypes';

import {
    setPageLoading,
    clearPageLoading,
    clearErrors
} from './pageActions';

const backendServerURL = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;

export const getAllOrders = () => dispatch => {
    dispatch(setPageLoading());

    axios
    .get( backendServerURL+`/hikmah/orders`, {
        headers: { 
            'token': localStorage.authToken
        }
    } )
    .then(res => {
        const data = res && res.data ;
        dispatch(clearErrors());
        dispatch({type: SET_ALL_ORDERS, payload:data})
        
    })
    .catch(err => {
        console.log("error: ", err && err.response && err.response.data && err.response.data);
        dispatch({type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! "} })
    })      
    .finally(() => dispatch(clearPageLoading()))
};