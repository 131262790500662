import {
	SET_ORGANIZATIONS,
	SET_SINGLE_ORGANIZATION,
	SET_INVITED_ORGANIZATIONS,
	SET_SINGLE_INVITE_ORGANIZATION,
	ORGS_AND_INVITED_ORGS,
	SET_STRIPE_ORGANIZATION,
	SET_ORGANIZATIONS_ORDERS,
	SET_ORGANIZATIONS_ORDERS_RECEIPTS
} from '../Actions/actionTypes';

const initialState = {
	organizations: [],
	organization: {},
	organizationOrders: [],
	invitedOrganizations: [],
	invtiteOrganization: {},
	orgsAndInvitedOrgs: {},
	stripeOrgDetails: {},
	orgOrderReceipts: []
};

export default function (state = initialState, action) {
	switch (action.type) {
		case SET_ORGANIZATIONS:
			return { ...state, organizations: action.payload }
		case SET_SINGLE_ORGANIZATION:
			return { ...state, organization: action.payload }
		case SET_INVITED_ORGANIZATIONS:
			return { ...state, invitedOrganizations: action.payload }
		case SET_SINGLE_INVITE_ORGANIZATION:
			return { ...state, invtiteOrganization: action.payload }
		case ORGS_AND_INVITED_ORGS:
			return { ...state, orgsAndInvitedOrgs: action.payload }
		case SET_STRIPE_ORGANIZATION:
			return { ...state, stripeOrgDetails: action.payload }
		case SET_ORGANIZATIONS_ORDERS:
			return { ...state, organizationOrders: action.payload }
		case SET_ORGANIZATIONS_ORDERS_RECEIPTS:
				return { ...state, orgOrderReceipts: action.payload }

		default:
			return state;
	}
}