import React from 'react'
import { EventLogs } from "@dclab/dc-react-ui-kit";
import Add from "../../assets/images/check_circle-24px.svg";
import moment from 'moment';

export default function EventsLogs(props) {
    const { invtiteOrganization } = props;
    let data = invtiteOrganization?.invitationsDetails?.inviteLogs?.length ? invtiteOrganization.invitationsDetails.inviteLogs.map((log, i) => {
        return {
            id: i + 1, icon: Add, status: 'Invite Sent', date: log.createdAt ? moment(log.createdAt).format('MMMM Do YYYY, h:mm:ss a') : "", details: [
                { title: 'Org Admin', message: log && log.orgAdminFullName ? log.orgAdminFullName : '' },
                { title: 'Email', message: log && log.orgEmail ? log.orgEmail : '' },
                { title: 'Phone Number', message: log && log.orgPhoneNo ? log.orgPhoneNo : '' },
                { title: 'Invitation Code', message: log && log.inviteCode ? log.inviteCode : '' },
                { title: 'Sent by', message: log && log.sentBy ? log.sentBy : '' },
            ]
        }
    }) : null;

    data = data && data.length ? data.reverse() : [];

    return (
        data && data.length ? <EventLogs data={data}
            headTitle="Timeline"
            schedule={true}
        /> : null
    )
}
