import {
	GET_ALL_APPLICATIONS, GET_ALL_INTEGRATIONS, ADD_APP_INFO, ADD_APPLICATION, DELETE_APPLICATION, GET_APPLICATION,
	ADD_INTEGRATION_INFO, ADD_INTEGRATION, DELETE_INTEGRATION, GET_INTEGRATION, SET_CARD_DATA
} from '../Actions/actionTypes';

const initialState = {
	applications: [],
	integrations: [],
	applicationInfo: {},
	integrationInfo: {},
	productCardData: {}
};

export default function (state = initialState, action) {
	switch (action.type) {
		case GET_ALL_APPLICATIONS:
			return { ...state, applications: action.payload }

		case GET_ALL_INTEGRATIONS:
			return { ...state, integrations: action.payload }

		case ADD_APP_INFO:
			return { ...state, applicationInfo: { ...state.applicationInfo, ...action.payload} }

		// case SAVE_APP_INFO:
		// 	return { ...state, applicationInfo: { ...state.applicationInfo, ...action.payload} }
	

		case GET_APPLICATION:
			return { ...state, applicationInfo: action.payload }

		case ADD_APPLICATION:
			return { ...state, applicationInfo: {} }
		
		case DELETE_APPLICATION:
			let apps = state.applications.filter((el) => el._id !== action.payload)
			return { ...state, applications: apps }

		//  INTEGRATIONS
		case ADD_INTEGRATION_INFO:
			return { ...state, integrationInfo: { ...state.integrationInfo, ...action.payload} }

		case GET_INTEGRATION:
			return { ...state, integrationInfo: action.payload }
					
		case DELETE_INTEGRATION:
			let ints = state.integrations.filter((el) => el._id !== action.payload)
			return { ...state, integrations: ints }

		case ADD_INTEGRATION:
			return { ...state, integrationInfo: {} }

		case SET_CARD_DATA:
			return { ...state, productCardData: action.payload }

		default:
			return state;
	}
}