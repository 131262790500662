import React, { Component } from 'react';
import { SignInComponent, ComponentOtpLockedCard } from "@dclab/dc-react-ui-kit";
import Lock from "../../assets/img/lock-24px.svg";
import Right from '../../assets/img/done-14px.svg'
import Close from '../../assets/img/clear-24px.svg'

import Logo from '../../assets/img/DC_Logo.png';
import classes from './signin.module.css';

import backgroundImage from '../../assets/images/dark_pattern_bg.png';

import { connect } from 'react-redux';
import * as actions from '../../store/Actions/index';
import {redirectDashboardIfAlreadyLogin} from '../../components/common/lib';
import Spinner from '../../components/common/Spinner';
import Alert from '../../components/common/AlertAction';
import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';

class SignIn extends Component {
    constructor(props){
        super(props);
        this.state = {
            useremail: "",
            userpassword: "",
            // newpassword:'',
            // confirmpassword:'',
            errors: {},
            loading : false,
            checked: false
        };
    }


    componentDidMount() {
      redirectDashboardIfAlreadyLogin(this.props.auth, this.props.history);
      localStorage.removeItem('checkedToken');
      const cookies = new Cookies();
      let rememberMeToken = cookies.get('rememberMe')
      if(rememberMeToken) {
        let decodedToken = jwt_decode(rememberMeToken);
        // console.log("decodedToken", decodedToken)
        const { userName, password } = decodedToken;
        this.props.authenticateWithRememberMe(userName, password, false,  this.props.history);
      }
    }

    static getDerivedStateFromProps(props, state) {
      let errors = props.errors;
      let page = props.page;
   
      let stateChanged = false;
      let changedState = {};
   
      if(errors && JSON.stringify(state.errors) !== JSON.stringify(errors)){
         changedState.errors= errors;
         stateChanged = true;
     }
   
       if(page && JSON.stringify(state.loading) !== JSON.stringify(page.loading)){
           changedState.loading = page.loading;
           stateChanged = true;            
       }
       
       if(stateChanged){
           return changedState;
       }
   
   
      return null;
   }

    handleChange = (prop) => (event) => {
        this.setState({
            [prop]: event.target.value
        });
      }

        rememberMe = (e) => {
          this.setState({
            checked: !this.state.checked
          })
        }

      handleSubmit = (e) => {
        e.preventDefault();
        //console.log("Checking Login Data: ", this.state);
        const { useremail, userpassword , checked} = this.state;
        this.props.onClearError();
        this.props.onSubmit(useremail, userpassword,checked,  this.props.history);
      };

      showAlertHandler = () => {
        this.props.onClearError();
      }
      
      
    render() {
        const {errors , loading, useremail, userpassword  } = this.state;

        let message = errors && errors.message && "Incorrect email address or password";

        
        // let show = true;
        let show = errors && errors.message && !errors.message.includes("Login Attempts Exceeded") ? true : false;
        let type = "error";

        let pageContent = '';
        //console.log("Error checking: ", errors);

        if(loading){
          pageContent = <Spinner />;
        } 
        // else if (errors && errors.message && errors.message.includes("Login Attempts Exceeded")) {
        //   pageContent = <ComponentOtpLockedCard src={Logo} tooAttempts={false} />
        // } 
        else{
          pageContent = <div style={{width: '55%', margin: 'auto', fontFamily: 'Roboto', fontWeight: '300'}}>
          <SignInComponent
            title="Sign In"
            name="signin"
            src={Logo}
            right={Right}
            close={Close}
            icon={Lock}
            textfieldvalue={useremail}
            onChange={this.handleChange("useremail")}
            required={true}
            id={"useremail"}
            label={"Email Address"}
            placeholder={"email"}
            type={"email"}
            error={false}
            helperText={""}
            passwordvalue={userpassword}
            passwordvalue_onChange={this.handleChange("userpassword")}
            passwordvalue_required={true}
            passwordvalue_id={"userpassword"}
            passwordvalue_label={"Password"}
            passwordvalue_error={false}
            passwordvalue_placeholder={"password"}
            passwordvalue_helperText={""}
          
            // newpasswordvalue={newpassword}
            // newpasswordvalue_onChange={this.handleChange("newpassword")}
            // newpasswordvalue_required={true}
            // newpasswordvalue_id={"newpassword"}
            // newpasswordvalue_label={"New Password"}
            // newpasswordvalue_error={false}
            // newpasswordvalue_placeholder={""}
            // newpasswordvalue_helperText={""}
          
            // confirmpasswordvalue={confirmpassword}
            // confirmpasswordvalue_onChange={this.handleChange("confirmpassword")}
            // confirmpasswordvalue_required={true}
            // confirmpasswordvalue_id={"confirmpassword"}
            // confirmpasswordvalue_label={"Confirm Password"}
            // confirmpasswordvalue_error={false}
            // confirmpasswordvalue_placeholder={""}
            // confirmpasswordvalue_helperText={""}
          
            text={"Sign in"}
            onClick={(e) => this.handleSubmit(e)}
            onSubmit={(e) => this.handleSubmit(e)}
            checked={this.state.checked}
            setChecked={(e) => this.rememberMe(e)}
            hidePasswordStrength={true}
            hideSignupButton={true}
            hideForgotPasswordButton={true}
            />
            </div>;
          }

        return (
          <div className={classes.mainClass}>
            <div className={classes.signInClass} style={{backgroundImage: `url(${backgroundImage}) !important` }}>
              <Alert message={message} type={type} click={this.showAlertHandler} show={show} /> 
                {pageContent}
              </div>
          </div>
          );
    }
}

const mapStateToProps = state => {
  return {
     auth: state.auth,
     page: state.page,
     errors: state.errors
  }
};

const mapDispatchToProps = dispatch => {
  return {
      onSubmit: (userName, password, checked, history) => dispatch(actions.authenticateUser(userName, password, checked, history)),
      authenticateWithRememberMe: (userName, password, checked, history) => dispatch(actions.authenticateWithRememberMe(userName, password, checked, history)),
      onClearError: () => dispatch(actions.clearErrors())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);

