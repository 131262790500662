import { 
	SET_ALL_CONTACTS,
	SET_CONTACT,
	SET_ALL_REQUESTS,
	SET_REQUEST
} from '../Actions/actionTypes';

const initialState = {
	allContacts: [],
	allRequests: [],
	contactDetails: {},
	requestDetails: {}
};

export default function(state = initialState, action) {
	switch (action.type) {
		case SET_ALL_CONTACTS:
			return { ...state, allContacts: action.payload }

		case SET_CONTACT:
			return { ...state, contactDetails: action.payload }

		case SET_ALL_REQUESTS:
		return { ...state, allRequests: action.payload }

		case SET_REQUEST:
			return { ...state, requestDetails: action.payload }
			
		default:
			return state;
	}
}