import React, { useState, useEffect } from 'react'
import { ChangePasswordComponent } from "@dclab/dc-react-ui-kit";
import Lock from "../../assets/img/lock-24px.svg";
import Right from '../../assets/img/done-14px.svg'
import Close from '../../assets/img/clear-24px.svg'

import Logo from '../../assets/img/DC_Logo.png';
import classes from './signin.module.css';
import backgroundImage from '../../assets/images/dark_pattern_bg.png';

import { connect } from 'react-redux';
import * as actions from '../../store/Actions/index';
import Spinner from '../../components/common/Spinner';
import Alert from '../../components/common/AlertAction';


function ChangePassword(props) {
  const [values, setValues] = React.useState({
    currentpassword: "",
    newpassword: "",
    confirmpassword: "",
    userName: ""
  });

  const [showAlert, setshowAlert] = useState(true)

  useEffect(() => {
    if(props.userDetails && props.userDetails.userName) {
      setValues({ ...values, userName: props.userDetails.userName });
    }
  }, [props.userDetails])
  
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("submit",  values)
    const { userName, currentpassword, newpassword, } = values;
    props.onClearError();
    props.onSubmit(userName, currentpassword, newpassword, props.history);
  };
  
  const onCancelLink = (e) => {
    props.history.push("/edit-profile")
  };
  
    const checkPassword = (password) => {
        return password.length < 8 ||
          !/[a-z]/.test(password) ||
          !/[A-Z]/.test(password) ||
          !/[0-9]/.test(password)
          ? false
          : true;
    }
  
    const {  currentpassword, newpassword, confirmpassword } = values
    let disableBtn = checkPassword(currentpassword) && checkPassword(newpassword) && checkPassword(confirmpassword) && newpassword === confirmpassword ? false : true;

    return (
    <div className={classes.mainClass}>
        <div className={classes.signInClass} style={{backgroundImage: `url(${backgroundImage}) !important` }}>
            { props.errors && props.errors.message && <Alert message={props.errors.message} type={"error"} show={showAlert} click={() => setshowAlert(false)} /> }
            <div style={{width: '55%', margin: 'auto', fontFamily: 'Roboto', fontWeight: '300'}}>
            <ChangePasswordComponent
                title="Change Password"
                name="Change Password"
                src={Logo}
                right={Right}
                close={Close}
                icon={Lock}

                currentpasswordvalue={values.currentpassword}
                currentpasswordvalue_onChange={handleChange("currentpassword")}

                newpasswordvalue={values.newpassword}
                newpasswordvalue_onChange={handleChange("newpassword")}

                confirmpasswordvalue={values.confirmpassword}
                confirmpasswordvalue_onChange={handleChange("confirmpassword")}

                text={"Change Password"}
                onClick={(e) => handleSubmit(e)}
                onSubmit={(e) => handleSubmit(e)}
                isLoading={false}
                onCancelLink={onCancelLink}
                disableBtn={disableBtn}
            />
            </div>
          </div>
      </div>
    )
}

const mapStateToProps = state => {
    return {
       userDetails: state.auth.getCurrentUserDetails,
       auth: state.auth,
       page: state.page,
       errors: state.errors
    }
  };
  
const mapDispatchToProps = dispatch => {
return {
    onSubmit: (userName, currentpassword, newpassword, history) => dispatch(actions.changePassword(userName, currentpassword, newpassword, history)),
    onClearError: () => dispatch(actions.clearErrors())
}
};
  
  export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
  
  