import axios from 'axios';

import {
    GET_ALL_APPLICATIONS, GET_ALL_INTEGRATIONS, ADD_APP_INFO, ADD_APPLICATION, DELETE_APPLICATION, GET_APPLICATION,
    ADD_INTEGRATION_INFO, ADD_INTEGRATION, DELETE_INTEGRATION, GET_INTEGRATION, SET_CARD_DATA
} from './actionTypes';

import {
    setPageLoading,
    clearPageLoading,
    clearErrors,
    showMessage
} from './pageActions';


const backendServerURL = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;

export const getAllApplications = () => dispatch => {
    dispatch(setPageLoading());

    axios
        .get(backendServerURL + '/hikmah/getAllDcApplications', {
            headers: {
                'token': localStorage.authToken
            }
        })
        .then(res => {
            console.log("Checking Result: ", res && res.data && res.data.body && res.data.body.data && res.data.body.data);
            const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
            dispatch({ type: GET_ALL_APPLICATIONS, payload: data });
            dispatch(clearErrors());

        })
        .catch(err => {
            console.log("error: ", err)
        })
        .finally(() => dispatch(clearPageLoading()))
};

export const getAllIntegrations = () => dispatch => {
    dispatch(setPageLoading());

    axios
        .get(backendServerURL + '/hikmah/getAllDcIntegrations', {
            headers: {
                'token': localStorage.authToken
            }
        })
        .then(res => {
            console.log("Checking Result: ", res && res.data && res.data.body && res.data.body.data && res.data.body.data);
            const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
            dispatch({ type: GET_ALL_INTEGRATIONS, payload: data });
            dispatch(clearErrors());

        })
        .catch(err => {
            console.log("error: ", err)
        })
        .finally(() => dispatch(clearPageLoading()))
};

export const addAppInfo = (data) => dispatch => {
    dispatch({ type: ADD_APP_INFO, payload: data });
}

// export const saveAppInfo = (data) => dispatch => {
//     dispatch({ type: SAVE_APP_INFO, payload: data });
// }

export const clearAppInfo = () => dispatch => {
    dispatch({ type: ADD_APPLICATION });
}

export const addApplication = (ownProps) => (dispatch, getState) => {
    dispatch(setPageLoading());
      const { applicationInfo } = getState().products;

      console.log("data", {...applicationInfo});
      axios.post(`${backendServerURL}/hikmah/createDcApplication`, {...applicationInfo}, {
        headers: {
            'token': localStorage.authToken
        }
    })
        .then((response) => {
          console.log("response: ", response)
          dispatch({ type: ADD_APPLICATION });
          dispatch(showMessage("Application Added Successfully!"));
          ownProps.history.push("/admin-products");       
        })
        .catch((error) => {
          console.log(error.message);
        })
        .finally(() => dispatch(clearPageLoading()))
  };

export const deleteApplication = (id) => dispatch => {
    dispatch(setPageLoading());

    axios
        .delete(backendServerURL + '/hikmah/deleteDcApplicationsById/' + id, {
            headers: {
                'token': localStorage.authToken
            }
        })
        .then(res => {
            dispatch({ type: DELETE_APPLICATION, payload: id });
            dispatch(showMessage("Application Deleted Successfully!"));
            dispatch(clearErrors());
        })
        .catch(err => {
            console.log("error: ", err)
        })
        .finally(() => dispatch(clearPageLoading()))
};

export const editApplication = (ownProps) => (dispatch, getState) => {
    dispatch(setPageLoading());
      const { applicationInfo } = getState().products;

      console.log("data", {...applicationInfo});
        axios.put(`${backendServerURL}/hikmah/updateDcApplication/${ownProps.match.params.id}`, {...applicationInfo}, {
            headers: {
                'token': localStorage.authToken
            }
        })
        .then((response) => {
        //   dispatch({ type: ADD_APPLICATION });
          console.log(response, "response")
          dispatch(showMessage("Application Updated Successfully!"));
        //   ownProps.history.push("/admin-products")
        })
        .catch((error) => {
          console.log(error.message);
        })
        .finally(() => dispatch(clearPageLoading()))
  };

const getProductPlans = async () => {
    axios
    .get(backendServerURL + '/stripe/getProductPlans').then((result) => {
        console.log("result", result)
   
    });
}

export const sortApplications = (data) => (dispatch, getState) => {
    dispatch(setPageLoading());

     
      axios.put(`${backendServerURL}/hikmah/sortApplicationList`, data, {
        headers: {
            'token': localStorage.authToken
        }
        })
        .then((response) => {
          console.log("response: ", response)
        })
        .catch((error) => {
          console.log(error.message);
        })
        .finally(() => dispatch(clearPageLoading()))
};

export const getApplication = (ownProps) => dispatch => {
    dispatch(setPageLoading());

    if(ownProps.match.params.id){
        axios
        .get(backendServerURL + '/hikmah/getDcApplicationsById/' + ownProps.match.params.id, {
            headers: {
                'token': localStorage.authToken
            }
        })
        .then(res => {
            const data = res?.data?.body?.data;
            dispatch({ type: GET_APPLICATION, payload: data });
            dispatch(clearErrors());
        })
        .catch(err => {
            console.log("error: ", err)
        })
        .finally(() => dispatch(clearPageLoading()))
    }
};


// INTEGRATIONS
export const addIntegrationInfo = (data) => dispatch => {
    dispatch({ type: ADD_INTEGRATION_INFO, payload: data });
}

export const clearIntegrationInfo = () => dispatch => {
    dispatch({ type: ADD_INTEGRATION });
}

export const addIntegration = (ownProps) => (dispatch, getState) => {
    dispatch(setPageLoading());
      const { integrationInfo } = getState().products;

      console.log("data", {...integrationInfo});
      axios.post(`${backendServerURL}/hikmah/createDcIntegration`, {...integrationInfo}, {
        headers: {
            'token': localStorage.authToken
        }
    })
        .then((response) => {
          console.log("response: ", response)
          dispatch({ type: ADD_INTEGRATION });
          dispatch(showMessage("Integration Added Successfully!"));
          ownProps.history.push({pathname: "/admin-products", state: {step: "integration"}});       
        })
        .catch((error) => {
          console.log(error.message);
        })
        .finally(() => dispatch(clearPageLoading()))
  };

export const deleteIntegration = (id) => dispatch => {
dispatch(setPageLoading());

axios
    .delete(backendServerURL + '/hikmah/deleteDcIntegrationsById/' + id, {
        headers: {
            'token': localStorage.authToken
        }
    })
    .then(res => {
        dispatch({ type: DELETE_INTEGRATION, payload: id });
        dispatch(showMessage("Integration Deleted Successfully!"));
        dispatch(clearErrors());
    })
    .catch(err => {
        console.log("error: ", err)
    })
    .finally(() => dispatch(clearPageLoading()))
};

export const getIntegration = (ownProps) => dispatch => {
    dispatch(setPageLoading());

    if(ownProps.match.params.id){
        axios
        .get(backendServerURL + '/hikmah/getDcIntegrationsById/' + ownProps.match.params.id, {
            headers: {
                'token': localStorage.authToken
            }
        })
        .then(res => {
            const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
            dispatch({ type: GET_INTEGRATION, payload: data });
            dispatch(clearErrors());
        })
        .catch(err => {
            console.log("error: ", err)
        })
        .finally(() => dispatch(clearPageLoading()))
    }
};

export const editIntegration = (ownProps) => (dispatch, getState) => {
    dispatch(setPageLoading());
      const { integrationInfo } = getState().products;

      console.log("data", {...integrationInfo});
        axios.put(`${backendServerURL}/hikmah/updateDcIntegration/${ownProps.match.params.id}`, {...integrationInfo}, {
            headers: {
                'token': localStorage.authToken
            }
        })
        .then((response) => {
          dispatch(showMessage("Integration Updated Successfully!"));
        //   ownProps.history.push({pathname: "/admin-products", state: {step: "integration"}})
        })
        .catch((error) => {
          console.log(error.message);
        })
        .finally(() => dispatch(clearPageLoading()))
  };


  export const getProductCardData = () => dispatch => {
    dispatch(setPageLoading());

    axios
        .get(backendServerURL + '/hikmah/productsCardData', {
            headers: {
                'token': localStorage.authToken
            }
        })
        .then(res => {
            const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
            dispatch({ type: SET_CARD_DATA, payload: data });
            dispatch(clearErrors());

        })
        .catch(err => {
            console.log("error: ", err)
        })
        .finally(() => dispatch(clearPageLoading()))
};

export const sortIntegrations = (data) => (dispatch, getState) => {
    dispatch(setPageLoading());

      console.log("data", data);
      axios.put(`${backendServerURL}/hikmah/sortIntegrationList`, data, {
        headers: {
            'token': localStorage.authToken
        }
    })
        .then((response) => {
          console.log("response: ", response)
        //   dispatch(showMessage("Integration Added Successfully!"));
        })
        .catch((error) => {
          console.log(error.message);
        })
        .finally(() => dispatch(clearPageLoading()))
  };
