import React from 'react';
import { SimpleTable, CardDetailsTable } from "@dclab/dc-react-ui-kit";
import check_circle from '../../../assets/img/check_circle.svg';
import moment from 'moment';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import './../tab.css'

  
export default function tabContent3(props) {
  let organization = props.organization;
  let stripeOrgDetails = props.stripeOrgDetails;
  let orgOrders = props.orgOrders;



    const capitalizeFLetter = (data) => {
      return data[0].toUpperCase() + data.slice(1);
    };

    let columns = [

      { id: 'NO', label: 'NO.', width: 'auto' },
    { id: "RECEIPT", label: "INVOICE NO.", width: "20%" },
    { type: "string", name: "name", label: "INVOICE Date", width: "20%" },
    { type: "string", name: "name", label: "Billing Cycle", width: "20%" },
    { type: "string", name: "name", label: "Amount paid", width: "20%" },
    { type: "string", name: "status", label: "Status", width: '15%' },
    { type: "action", name: "action", label: "ACTION", width: "10%" },
      ];
      
      let classProps = () => {
        const tempArray = [];
        columns.forEach((column, index) => {
          tempArray.push(`class${index}`);
        });
        return tempArray;
      };
      
      const changeClassProps = () => {
        let tempArray = classProps();
        tempArray[0] = "class15";
        return tempArray;
      };
      const checkStatusName = (status) => {
        status = status ? status.toLowerCase() : "";
        if (status === 'open') {
          return "Due"
        } else if (status === 'paid') {
          return "Paid"
        } else if (status === 'void') {
          return "Failed"
        } else {
          return capitalizeFLetter(status)
        }
      };
    
      const checkStatus = (status) => {
        status = status ? status.toLowerCase() : "";
        if (status === 'paid') {
          return "success"
        } else if (status === 'open') {
          return "discontinued"
        } else if (status === 'void') {
          return "failed"
        } else {
          return "discontinued"
        }
      };

      const checkFrequency = (status) => {
        status = status ? status.toLowerCase() : "";
        if (status === 'month') {
          return "Monthly"
        } else if (status === 'year') {
          return "Annually"
        } else {
          return capitalizeFLetter(status)
        }
      };
      
      const columns1 = (columns) =>
        columns.map((obj) => {
          // delete obj.id;
          // delete obj.name;
          return obj;
        });
      const rows = orgOrders?.map((payment, i) => {
        return [
          { type: 'id', value: i + 1, payment: payment },
          {
            type: "link",
            name: "invoice_number",
            value: payment?.receipt_number ? payment?.receipt_number.replace("#", "") : "" || "N/A",
            url: payment?.hosted_invoice_url ? payment?.hosted_invoice_url : ""
          },
          { type: 'string', name: 'date', value: payment?.created ? moment(payment.created * 1000).format("LL") : "" },
          { type: "string", name: "frequency", value: payment?.lines?.data?.[0]?.plan?.interval ? checkFrequency(payment?.lines?.data?.[0]?.plan?.interval) : "" },
          { type: "string", name: "name", value: payment?.total ? "$" + (payment.total / 100).toFixed(2) : "N/A" },
          { type: 'status', status: checkStatus(payment?.status), value: payment?.status ? checkStatusName(payment.status) : "" },
          { type: "action", status: "active", value: payment?.status === "paid" ? "VIEW RECEIPT" : "VIEW INVOICE" },
        ]
      });
     
      
      const click = (msg) => {
        console.log(msg);
      };
      
      const btnClick=() => {
        console.log('invite')
      }
      // console.log(rowFinder(rows));
      // console.log(value);
      
      // <SimpleTable rows={rowFinder(rows)} columns={columns1(columns)} colSpan={6} />;
    
      const actionLinkHandler = (name) => {
        if(name?.[0]?.data?.receipt_url) window.open(name[0].data.receipt_url, "_blank");
        // props.history.push('/varify-ein');
      }

    

    


    return(
        <React.Fragment>

          <SimpleTable
          showHeader={true}
          title="Payment History"
          rows={rows}
          columns={columns1(columns)}
          colSpan={6}
          actionSendButton={true}
          classProps={changeClassProps()}
          onClick={(message, index) => click(message)}
          showFilter={false}
          showAdd={false}
          donationsCount={10}
          noPara="Add donation categories so that people can donate for a specific cause."
          noBtnText="Invite Donors"
          onInviteClick={() => btnClick()}
          showIconCol={false}
          showFooter={false}
          actionLink={(name) => props.history.push({ pathname: "/organization/orders/payment-receipt", state: { invoice: name[0].payment , returnUrl : `/orgorders/${props.orgId}` }})}

          onInviteClick={() => btnClick()}
          iconTextSize={14}
          sortColumnIndex={[5]}
          rowsPerPage={10}
          showFooter={rows?.length > 10 ? true : false}


        />
        </React.Fragment>
    )
}





