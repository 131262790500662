import { 
	SET_CURRENT_USER,
	CLEAR_CURRENT_USER,
	SET_TEMP_USER,
	SET_CURRENT_USER_DETAILS,
	GET_CURRENT_USER_DETAIL
} from '../Actions/actionTypes';

const initialState = {
	isAuthenticated: false,
	currentUserDetails: {},
	tempUser : {},
	responseData: '',
	getCurrentUserDetails: {}
};

const saveUserToLocalStorage = (user) => {
	const {name, role, imageDetails} = user;
	const firstName = name && name.firstName ? name.firstName : "";
	const lastName = name && name.lastName ? name.lastName : "";
	const userImage = imageDetails && imageDetails.image ? imageDetails.image : "";
	localStorage.setItem("userName", firstName + " " + lastName);
	localStorage.setItem("role", role && role.length ? role[0].roles : "");
	localStorage.setItem("image", userImage);
}

export default function(state = initialState, action) {
	switch (action.type) {
		case SET_CURRENT_USER:
			return { ...state, isAuthenticated: true }

		case SET_CURRENT_USER_DETAILS:
			saveUserToLocalStorage(action.payload)
			return { ...state }

		case CLEAR_CURRENT_USER:
			return { ...state, isAuthenticated: false }

		case SET_TEMP_USER:
			// console.log("Checking payload: ", action.responseData, action.payload );
			return {
				...state,
				tempUser: action.payload,
				responseData: action.responseData
			}

		case GET_CURRENT_USER_DETAIL:
			saveUserToLocalStorage(action.payload)
			return {
				...state, getCurrentUserDetails: action.payload
			}
		default:
			return state;
	}
}